import React, { Component } from "react";
import AdvancedLoudspeakerGraph from "./AdvancedLoudspeaker.graph";
import SliderEdit from "../UI/SliderEdit";
import { COLORS } from "../../helpers/helpers";
import "./AdvancedLoudspeaker.css";

const INTRO_MESSAGE =
  "This example uses real data (voltage, current, and velocity) measured on an actual loudspeaker with wide-band signal as explained in the theory part. Try to estimate first the force factor Bl, and then the mass Mms.";

const PAGE_TITLE = ["part 1 of 2: Electrical part", "part 2 of 2: Mechanical part"];

const MAIN_TEXT_MESSAGE = [
  "Set the value of Bl that gets rid of any resonance in apparent impedance curves and click on the button below. Once you find a correct value, the button becomes enabled.",
  "Here, you can estimate the mass Mms to get the stiffness Kms as flat as possible.",
];

const BUTTON_MESSAGE = [
  "Go to estimation of Mechanical part",
  "Go back to estimation of Electromagnetic parameters.",
];

const FINAL_MESSAGE = [
  "You have finished the estimation of electrical parameters Bl, Re(f) and Le(f).",
  "You have finished the estimation of mechanical parameters Mms, Rms(f) and Kms(f).",
];

const INFO_MESSAGE_PART_1 = [
  "Due to the eddy currents the parameters Le(f) and Re(f) are frequency dependent. The estimated value of Bl = ",
  "Due to the surround's and spider's viscoelastic effects, such as creep, the stiffness is not independent of the frequency. The estimated value of Mms = ",
];
const INFO_MESSAGE_PART_2 = [
  " Tm is near to the real force factor value as there is almost no remaining resonance effect around the resonant frequency.",
  " g leads to a stiffness that increases slowly with frequency, according to the viscoelastic theory. The losses Rms(f) decrease with frequency.",
];

const style_display = ["block", "none"];

class AdvancedLoudspeaker extends Component {
  // class state
  state = {
    page: 0, // step
    Bl: 0, // resistance [Ohm]
    Mms: 0, // mass [g]
  };
  // slider parameters
  sliders = [
    {
      name: "Bl",
      unit: "Tm",
      min: 0,
      max: 10.0,
      step: 0.01,
    },
    {
      name: "Mms",
      unit: "g",
      min: 0,
      max: 20.0,
      step: 0.01,
    },
  ];

  // handle for sliders
  handleChange = (name, value) => {
    if (!isNaN(value)) {
      this.setState({ [name]: value });
    }
  };

  // handle for button cliks
  buttonClick = (event) => {
    this.setState({ page: Number(!this.state.page) });
  };

  render() {
    let { Bl, Mms, page } = this.state;
    let estimated_parameter = page === 0 ? Bl : Mms;
    let show_messages = false;
    let disable_button = true;
    if (page === 0 && Bl > 5.36 && Bl < 5.42) show_messages = true;
    if (page === 1 && Mms < 10.8 && Mms > 10.4) show_messages = true;
    if (page === 1 || show_messages) disable_button = false;

    return (
      <div>
        <p className="text-info-advanced-loudspeaker">{INTRO_MESSAGE}</p>
        <div style={{ border: "solid 1px" }}>
          <p className="title-info-advanced-loudspeaker" style={{ background: COLORS[2] }}>
            {PAGE_TITLE[page]}
          </p>
          <div>
            {/* --------------------------------------------------*/}
            {/* sliders */}
            {/* --------------------------------------------------*/}
            <div className="slider-block" style={{ display: style_display[page] }}>
              <SliderEdit
                onChange={this.handleChange}
                params={this.sliders[0]}
                val={this.state[this.sliders[0].name]}
              />
            </div>
            <div className="slider-block" style={{ display: style_display[1 - page] }}>
              <SliderEdit
                onChange={this.handleChange}
                params={this.sliders[1]}
                val={this.state[this.sliders[1].name]}
              />
            </div>
          </div>
          <p className="text-info-advanced-loudspeaker">{MAIN_TEXT_MESSAGE[page]}</p>
          <p className="text-info-advanced-loudspeaker" style={{ color: COLORS[1] }}>
            {show_messages && FINAL_MESSAGE[page]}
          </p>
          <p className="text-info-advanced-loudspeaker" style={{ color: COLORS[4] }}>
            {show_messages && INFO_MESSAGE_PART_1[page]}
            {show_messages && estimated_parameter.toString()}
            {show_messages && INFO_MESSAGE_PART_2[page]}
          </p>

          <div style={{ margin: "15px" }}>
            <button disabled={disable_button} onClick={this.buttonClick}>
              {BUTTON_MESSAGE[page]}
            </button>
          </div>
          <div>
            <AdvancedLoudspeakerGraph Bl={Bl} Mms={Mms / 1000} page={page} />
          </div>
        </div>
      </div>
    );
  }
}
export default AdvancedLoudspeaker;
