import React, { Component } from "react";

function convertPolynomPoints(arrayPts) {
  return arrayPts
    .map(function (point) {
      return point[0] + "," + point[1];
    })
    .join(" ");
}

class PassiveRadiatorSVG extends Component {
  render() {
    // List of inputs ("props") :
    //        Rmult ... Rloss = Rmult * Rms
    //        fmult ... fb = fmult * fs
    let BOX = { x: 150, y: 10, w: 100, h: 120 };
    let HP1 = { x: 150, y: 20, w: 30, h: 30, l: 30 };
    let HP2 = { x: 250, y: 50, w: 30, h: 30, l: 30 };

    const LINE_STYLE = { fill: "white", stroke: "black", strokeWidth: 2 };

    return (
      <svg viewBox="0 0 400 140">
        {/*-- BOX --*/}
        <rect
          x={BOX.x}
          y={BOX.y}
          width={BOX.w}
          height={BOX.h}
          style={{ fill: "white", strokeWidth: 2, stroke: "black" }}
        />
        {/*-- HP1 --*/}
        <polygon points={createSpeaker(HP1, 1, true)} style={LINE_STYLE} />
        {/*-- HP2 --*/}
        <polygon points={createSpeaker(HP2, -1, false)} style={LINE_STYLE} />
        <text y={HP2.y + HP2.h / 2} fontSize="0.8em">
          <tspan x={HP2.x + 10} dy="1em">
            passive
          </tspan>
          <tspan x={HP2.x + 10} dy="1em">
            radiator
          </tspan>
        </text>
      </svg>
    );
  }
}
export default PassiveRadiatorSVG;

function createSpeaker(HP, dir, motor) {
  let PTS = [
    [HP.x + (dir * HP.w) / 2, HP.y + HP.l / 2],
    [HP.x + (dir * HP.w) / 2, HP.y + HP.l / 2 + HP.h],
    [HP.x, HP.y + HP.l + HP.h],
    [HP.x, HP.y],
    [HP.x + (dir * HP.w) / 2, HP.y + HP.l / 2],
  ];

  if (motor) {
    PTS.push(
      [HP.x + dir * HP.w, HP.y + HP.l / 2],
      [HP.x + dir * HP.w, HP.y + HP.l / 2 + HP.h],
      [HP.x + (dir * HP.w) / 2, HP.y + HP.l / 2 + HP.h]
    );
  }

  return convertPolynomPoints(PTS);
}
