import React from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  return (
    <nav className="navbar">
      <NavLink exact to="/Sinus" activeClassName="navbar__link--active" className="navbar__link">
        Sinus
      </NavLink>
      <NavLink exact to="/Fourier" activeClassName="navbar__link--active" className="navbar__link">
        Fourier
      </NavLink>
      <NavLink
        to="/MechanicalSystem"
        activeClassName="navbar__link--active"
        className="navbar__link"
      >
        Mechanical System
      </NavLink>
      <NavLink to="/Distortion" activeClassName="navbar__link--active" className="navbar__link">
        Distortion
      </NavLink>
      <NavLink to="/TSLoudspeaker" activeClassName="navbar__link--active" className="navbar__link">
        TS Loudspeaker
      </NavLink>
      <NavLink
        to="/ClosedBoxSystem"
        activeClassName="navbar__link--active"
        className="navbar__link"
      >
        Closed-box System
      </NavLink>
      <NavLink
        to="/VentedBoxSystem"
        activeClassName="navbar__link--active"
        className="navbar__link"
      >
        Vented-box System
      </NavLink>
      <NavLink
        to="/PassiveRadiator"
        activeClassName="navbar__link--active"
        className="navbar__link"
      >
        Passive Radiator
      </NavLink>
      <NavLink
        to="/PistonDirectivity"
        activeClassName="navbar__link--active"
        className="navbar__link"
      >
        Piston Directivity
      </NavLink>
      <NavLink to="/AnalogFilters" activeClassName="navbar__link--active" className="navbar__link">
        Analog Filters
      </NavLink>

      <NavLink
        to="/MicrophoneArray"
        activeClassName="navbar__link--active"
        className="navbar__link"
      >
        Microphone Array
      </NavLink>
      <NavLink to="/WaveGuide02" activeClassName="navbar__link--active" className="navbar__link">
        WaveGuide (lesson 1.2)
      </NavLink>
      <NavLink to="/WaveGuide03" activeClassName="navbar__link--active" className="navbar__link">
        WaveGuide (lesson 1.3)
      </NavLink>
      <NavLink to="/WaveGuide04" activeClassName="navbar__link--active" className="navbar__link">
        WaveGuide (lesson 1.4)
      </NavLink>
      <NavLink to="/WaveGuide05" activeClassName="navbar__link--active" className="navbar__link">
        WaveGuide (lesson 1.5)
      </NavLink>
      <NavLink to="/WaveGuide06" activeClassName="navbar__link--active" className="navbar__link">
        WaveGuide (lesson 1.6)
      </NavLink>
      <NavLink to="/WaveGuide07" activeClassName="navbar__link--active" className="navbar__link">
        WaveGuide (lesson 1.7)
      </NavLink>
      <NavLink to="/WaveGuide08" activeClassName="navbar__link--active" className="navbar__link">
        WaveGuide (lesson 1.8)
      </NavLink>
      <NavLink to="/WaveGuide09" activeClassName="navbar__link--active" className="navbar__link">
        WaveGuide (lesson 1.9)
      </NavLink>
      <NavLink
        to="/WaveGuideHelmholtz"
        activeClassName="navbar__link--active"
        className="navbar__link"
      >
        Wave Guide Helmholtz
      </NavLink>
      <NavLink to="/BandPass" activeClassName="navbar__link--active" className="navbar__link">
        Band-Pass
      </NavLink>
      <NavLink to="/Geophone" activeClassName="navbar__link--active" className="navbar__link">
        Geophone
      </NavLink>
      <NavLink
        to="/MicrophoneEldyn"
        activeClassName="navbar__link--active"
        className="navbar__link"
      >
        El-dyn microphone
      </NavLink>
      <NavLink
        to="/MicrophoneCardio"
        activeClassName="navbar__link--active"
        className="navbar__link"
      >
        Cardioid microphone
      </NavLink>
      <NavLink
        to="/ElectrostaticTransduction"
        activeClassName="navbar__link--active"
        className="navbar__link"
      >
        Elstat. transduction
      </NavLink>
      <NavLink
        to="/MicrophoneElstat"
        activeClassName="navbar__link--active"
        className="navbar__link"
      >
        Elstat. microphone
      </NavLink>
      <NavLink to="/EddyCurrents" activeClassName="navbar__link--active" className="navbar__link">
        Eddy Currents
      </NavLink>
      <NavLink
        to="/NonlinearLoudspeakerBl"
        activeClassName="navbar__link--active"
        className="navbar__link"
      >
        Loudspeaker Bl(x)
      </NavLink>
      <NavLink exact to="/FIR" activeClassName="navbar__link--active" className="navbar__link">
        FIR
      </NavLink>
      <NavLink
        exact
        to="/MicrophoneDirectivity"
        activeClassName="navbar__link--active"
        className="navbar__link"
      >
        Microphone
      </NavLink>
      {/*}
      <NavLink
        exact
        to="/ColorPlotExample"
        activeClassName="navbar__link--active"
        className="navbar__link"
      >
        ColorPlotExample
      </NavLink>
            */}

      <NavLink
        to="/AdvancedLoudspeaker"
        activeClassName="navbar__link--active"
        className="navbar__link"
      >
        Advanced Loudspeaker
      </NavLink>
      <NavLink to="/SoundZones" activeClassName="navbar__link--active" className="navbar__link">
        Sound Zones
      </NavLink>
      <NavLink to="/AddedMass" activeClassName="navbar__link--active" className="navbar__link">
        Added Mass
      </NavLink>
      <NavLink
        to="/BlockedImpedance"
        activeClassName="navbar__link--active"
        className="navbar__link"
      >
        Blocked Impedance
      </NavLink>
      <NavLink to="/PressureBasics" activeClassName="navbar__link--active" className="navbar__link">
        Pressure Basics
      </NavLink>
    </nav>
  );
};

export default Navbar;
