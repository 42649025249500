export function graphData(z_values) {

    let N = z_values.length;
    let Re = new Array(N);
    let Le = new Array(N);
    let labels = new Array(N);

    let dataMatlab = require("./data/BlockedImpedance.json");

    let R, L, f;

    z_values.forEach(function (z, idx) {
        if (isNaN(z)) {
            Re[idx] = NaN
            Le[idx] = NaN
            labels[idx] = undefined
        } else {
            // index of the array
            let pos = z + 5;

            let Re_local = [];
            let Le_local = [];
            for (let i = 0; i < dataMatlab[pos].frequencies.length; i++) {
                R = dataMatlab[pos].R[i];
                L = dataMatlab[pos].L[i];
                f = dataMatlab[pos].frequencies[i];

                // apparent resistance (Re)
                Re_local[i] = {
                    x: f,
                    y: R,
                };

                // apparent inductance (Le)
                Le_local[i] = {
                    x: f,
                    y: L * 1000,
                };
            }

            Re[idx] = Re_local;
            Le[idx] = Le_local;
            labels[idx] = z.toString() + " mm";
        }
    });

    return { Re, Le, labels };
}
