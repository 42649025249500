import React, { Component } from "react";

import {
  pressureFnc,
  velocityFnc,
  displacementFnc,
} from "../../helpers/WaveGuideHelmholtz.helper.js";
import { COLORS, scale, adjust_font_size } from "../../../../helpers/helpers";

const Ly = 0.125;

// ------ CANVAS MAIN PROPERTIES ------------------------------------------------
class Drawing {
  constructor(obj) {
    this.context = obj.context;
    this.PADDING_TOP = 15; // in pixels
    this.PADDING_BOTTOM = 0; // in pixels
    this.PIXEL_TOLERANCE_LINE_DRAG = 5; // in pixels

    this.size = {
      width: obj.context.canvas.width,
      height: obj.context.canvas.height,
    };

    this.size_graph_pixels = {
      x_min: 0,
      x_max: obj.context.canvas.width,
      y_min: this.PADDING_TOP,
      y_max: obj.context.canvas.height - this.PADDING_TOP - this.PADDING_BOTTOM,
    };

    this.size_graph_meters = { x_min: -0.05, x_max: 0.33, y_min: -0.15, y_max: 0.15 };
  }

  setContext = (context) => {
    this.context = context;
  };

  // map functions between [meters] and [pixels]
  meters_to_pixels_x = (value) => {
    return scale(
      value,
      this.size_graph_meters.x_min,
      this.size_graph_meters.x_max,
      this.size_graph_pixels.x_min,
      this.size_graph_pixels.x_max
    );
  };
  meters_to_pixels_y = (value) => {
    return scale(
      value,
      this.size_graph_meters.y_min,
      this.size_graph_meters.y_max,
      this.size_graph_pixels.y_max,
      this.size_graph_pixels.y_min
    );
  };

  // map functions between [pixels] and [meters]
  pixels_to_meters_x = (value) => {
    return scale(
      value,
      this.size_graph_pixels.x_min,
      this.size_graph_pixels.x_max,
      this.size_graph_meters.x_min,
      this.size_graph_meters.x_max
    );
  };

  // map functions between [meters] and [pixels]
  pixels_to_meters_y = (value) => {
    return scale(
      value,
      this.size_graph_pixels.y_max,
      this.size_graph_pixels.y_min,
      this.size_graph_meters.y_min,
      this.size_graph_meters.y_max
    );
  };

  // update canvas size
  update_drawing_size = () => {
    this.size.width = this.context.canvas.width;
    this.size.height = this.context.canvas.height;

    this.size_graph_pixels.x_max = this.context.canvas.width;
    this.size_graph_pixels.y_max =
      this.context.canvas.height - this.PADDING_TOP - this.PADDING_BOTTOM;
  };

  // check if canvas size has changed (window has resized)
  drawing_size_has_changed = () => {
    if (
      this.size.width !== this.context.canvas.width ||
      this.size.height !== this.context.canvas.height
    ) {
      // size has changed
      return true;
    }
    return false;
  };
}

// ------ COLORMAP ------------------------------------------------
export class Colormap {
  constructor(obj) {
    let { name, nshades } = obj.parent.properties.animation.colormap;
    this.parent = obj.parent; // parent object (WaveGuideHelmholtz)
    this.nshades = nshades;

    let colormaps = require("colormap");
    this.colormap = colormaps({
      colormap: name, // "RdBu", "picnic"
      nshades: nshades,
      format: "rgbaString",
      alpha: 1,
    });

    this.color_gradient = NaN; // will be assigned using update_color_gradient method
  }

  // color gradient for ColorBar
  updateColorGradient = () => {
    let drawing = this.parent.drawing;
    this.color_gradient = drawing.context.createLinearGradient(
      0,
      0,
      0,
      drawing.size_graph_pixels.y_max
    );
    for (let i = 0; i < this.nshades; i++) {
      this.color_gradient.addColorStop(
        ((i / this.nshades) * this.colormap.length) / (this.nshades - 1),
        this.colormap[this.nshades - i - 1]
      );
    }
  };

  getColor = (val, max_val) => {
    return this.colormap[Math.floor(scale(val, -max_val, max_val, 0, this.nshades))];
  };
}

// ------ GROUP OF POINTS ------------------------------------------------
class AirParticles {
  // each group is made of 5 particles with the same x coordinate and random y coordinate
  constructor(obj) {
    this.x = obj.x;
    this.y = [...Array(obj.particles_per_group).keys()].map(() => Ly * (2 * Math.random() - 1));
    this.dx = 0; // displacement (will be updated for animation)
    this.v = 0; // velocity (will be updated for animation)
    this.color = "rgba(0, 0, 0, 1)"; // (will be updated for animation)
    this.parent = obj.parent; // parent object (WaveGuideHelmholtz)
  }

  calculateDisplacement = (t) => {
    let { physics, signal } = this.parent.properties;
    let dx = displacementFnc(signal, this.x, t, physics);

    // change each point dx according to displacement_zoom
    this.dx = dx * signal.displacement_zoom;
  };

  calculateVelocity = (t) => {
    let { physics, signal } = this.parent.properties;
    this.v = velocityFnc(signal, this.x, t, physics);
  };

  calculatePressure = (t) => {
    let { physics, signal, animation } = this.parent.properties;

    let colormap = this.parent.colormap;

    if (animation.pressure_colors.show) {
      let value = pressureFnc(signal, this.x + this.dx, t, physics);
      value = value > signal.graph_limits.pmax ? signal.graph_limits.pmax : value;
      value = value < -signal.graph_limits.pmax ? -signal.graph_limits.pmax : value;
      this.color = colormap.getColor(value, 1.01 * signal.graph_limits.pmax);
    } else {
      this.color = "rgba(190, 190, 190, 1)";
      //this.color = colormap.getColor(0, 1);
    }
  };

  drawPoint = (y) => {
    let canvas = this.parent.drawing;
    canvas.context.beginPath();
    canvas.context.arc(
      canvas.meters_to_pixels_x(this.x + this.dx),
      canvas.meters_to_pixels_y(y),
      2, // radius of points [pixels]
      0, // starting angle [rad]
      Math.PI * 2 // ending angle, [rad]
    );
    let clr = this.color;
    canvas.context.fillStyle = clr === undefined ? "rgba(0,0,0,1)" : clr;
    canvas.context.fill();
  };

  drawArrow = (y) => {
    let { size_ratio, color = COLORS[0] } = this.parent.properties.animation.velocity_arrows;
    let canvas = this.parent.drawing;
    canvas.context.beginPath();
    this.drawArrowFnc(
      canvas.context,
      canvas.meters_to_pixels_x(this.x + this.dx),
      canvas.meters_to_pixels_y(y),
      this.v * size_ratio,
      0
    );
    //let clr = this.color;
    //ctx.strokeStyle = clr === undefined ? "rgba(0,0,0,1)" : clr;
    canvas.context.strokeStyle = color;
    canvas.context.stroke();
  };

  drawArrowFnc = (context, x, y, dx, dy) => {
    var headlen = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2)) / 3; // length of head in pixels
    var tox = x + dx;
    var toy = y + dy;
    var angle = Math.atan2(dy, dx);
    context.moveTo(x, y);
    context.lineTo(x + dx, y + dy);
    context.lineTo(
      tox - headlen * Math.cos(angle - Math.PI / 6),
      toy - headlen * Math.sin(angle - Math.PI / 6)
    );
    context.moveTo(tox, toy);
    context.lineTo(
      tox - headlen * Math.cos(angle + Math.PI / 6),
      toy - headlen * Math.sin(angle + Math.PI / 6)
    );
  };

  draw = () => {
    let { animation, physics } = this.parent.properties;
    let { L } = physics.dimensions;

    let isParticleInsideWaveguide = this.parent.box.isParticleInsideWaveguide;
    let densities = animation.points_density;

    let density = this.x < L ? densities[0] : densities[1];
    this.y.slice(0, density).map((y) => {
      if (isParticleInsideWaveguide({ x: this.x, y: y })) this.drawPoint(y);
      return null;
    });
    if (animation.velocity_arrows.show) {
      if (isParticleInsideWaveguide({ x: this.x, y: this.y[0] })) this.drawArrow(this.y[0]);
      if (isParticleInsideWaveguide({ x: this.x, y: this.y[1] })) this.drawArrow(this.y[1]);
    }
  };
}

// ------ COLORBAR ------------------------------------------------
class Colorbar {
  constructor(obj) {
    this.width = obj.width;
    this.colormap = obj.colormap;
    this.ticks_padding = obj.ticks_padding; // pixels
    this.parent = obj.parent; // parent object (WaveGuideHelmholtz)
  }

  draw = (p_max) => {
    let canvas = this.parent.drawing;
    let context = canvas.context;

    if (this.parent.properties.animation.pressure_colors.show) {
      context.fillStyle = this.colormap.color_gradient;
      context.beginPath();
      context.rect(
        canvas.meters_to_pixels_x(0.28),
        canvas.size_graph_pixels.y_min,
        this.width,
        canvas.size_graph_pixels.y_max - canvas.size_graph_pixels.y_min
      );
      context.strokeStyle = "black";
      context.stroke();
      context.fill();

      // tick labels in vertical colormap axis
      context.font = adjust_font_size(this.parent.drawing.size.width, 12) + "px Helvetica";
      context.fillStyle = "rgb(0,0,0)";
      context.textAlign = "left";
      context.textBaseline = "middle";
      context.fillText(
        p_max + " Pa",
        canvas.meters_to_pixels_x(0.28) + this.width + this.ticks_padding,
        canvas.size_graph_pixels.y_min
      );
      context.fillText(
        "0",
        canvas.meters_to_pixels_x(0.28) + this.width + this.ticks_padding,
        canvas.meters_to_pixels_y(0.0)
      );
      context.fillText(
        -p_max + " Pa",
        canvas.meters_to_pixels_x(0.28) + this.width + this.ticks_padding,
        canvas.size_graph_pixels.y_max
      );
    }
  };
}

class Box {
  constructor(obj) {
    this.parent = obj.parent; // parent object (WaveGuideHelmholtz)
  }

  isParticleInsideWaveguide = (particle) => {
    let { L, H, h } = this.parent.properties.physics.dimensions;

    return (
      (particle.x < L && Math.abs(particle.y) < H) || (particle.x >= L && Math.abs(particle.y) < h)
    );
  };

  drawWhiteBox = (canvas, x1, y1, x2, y2) => {
    canvas.context.fillStyle = "white";
    canvas.context.fillRect(
      canvas.meters_to_pixels_x(x1),
      canvas.meters_to_pixels_y(y1),
      canvas.meters_to_pixels_x(x2) - canvas.meters_to_pixels_x(x1),
      canvas.meters_to_pixels_y(y2) - canvas.meters_to_pixels_y(y1)
    );
  };

  // -----------------------------------------------------
  // draw outside box representing the wave guide
  draw = () => {
    let { L, l, H, h } = this.parent.properties.physics.dimensions;

    let x_min = 0.003;
    let canvas = this.parent.drawing;
    let context = canvas.context;

    context.lineWidth = 2;
    context.strokeStyle = "rgba(0,0,0,1)";

    this.drawWhiteBox(canvas, -x_min, H, L, 1.0);
    this.drawWhiteBox(canvas, -x_min, -H, L, -1.0);
    this.drawWhiteBox(canvas, L, h, L + l + x_min, 1.0);
    this.drawWhiteBox(canvas, L, -h, L + l + x_min, -1.0);

    context.beginPath();
    // upper lines
    context.moveTo(canvas.meters_to_pixels_x(-x_min), canvas.meters_to_pixels_y(H) - 1);
    context.lineTo(canvas.meters_to_pixels_x(L), canvas.meters_to_pixels_y(H) - 1);
    context.lineTo(canvas.meters_to_pixels_x(L), canvas.meters_to_pixels_y(h) - 1);
    context.lineTo(canvas.meters_to_pixels_x(0.25 + x_min), canvas.meters_to_pixels_y(h) - 1);

    // bottom lines
    context.moveTo(canvas.meters_to_pixels_x(-x_min), canvas.meters_to_pixels_y(-H) + 1);
    context.lineTo(canvas.meters_to_pixels_x(L), canvas.meters_to_pixels_y(-H) + 1);
    context.lineTo(canvas.meters_to_pixels_x(L), canvas.meters_to_pixels_y(-h) + 1);
    context.lineTo(canvas.meters_to_pixels_x(0.25 + x_min), canvas.meters_to_pixels_y(-h) + 1);

    context.moveTo(canvas.meters_to_pixels_x(-0.003), canvas.meters_to_pixels_y(H) - 1);
    context.lineTo(canvas.meters_to_pixels_x(-0.003), canvas.meters_to_pixels_y(-H) + 1);

    context.stroke();

    // drag the boundaries
    context.font = adjust_font_size(this.parent.drawing.size.width, 12) + "px Helvetica";
    context.fillStyle = "rgb(0,0,0)";
    context.textAlign = "center";

    // if (this.parent.properties.physics.radius.drag) {
    //   context.fillText(
    //     "drag the boundary (r = " + Math.round(H * 1000) / 1000 + " m)",
    //     canvas.meters_to_pixels_x(0.25),
    //     canvas.meters_to_pixels_y(H) - 5
    //   );
    //   context.fillText(
    //     "drag the boundary (r = " + Math.round(h * 1000) / 1000 + " m)",
    //     canvas.meters_to_pixels_x(0.75),
    //     canvas.meters_to_pixels_y(h) - 5
    //   );
    // }

    // tick labels on x-axis
    context.fillText("x = 0 m", canvas.meters_to_pixels_x(0), canvas.meters_to_pixels_y(-Ly) + 15);
    context.fillText(
      String(L + l) + " m",
      canvas.meters_to_pixels_x(L + l),
      canvas.meters_to_pixels_y(-Ly) + 15
    );
    context.fillText(
      String(L) + " m",
      canvas.meters_to_pixels_x(L),
      canvas.meters_to_pixels_y(-Ly) + 15
    );
  };
}

// Waveguide animation class that includes
// - box around
// - points that moves acording to source and phys. equations
export class MainCanvas {
  constructor(properties) {
    this.properties = properties;

    // objects
    this.drawing = null; // canvas context is added in this.setContext() called from componentDidMount
    this.points = this.createNewPoints(); // canvas points representing gas particles inside the waveguide
    this.colormap = new Colormap({ parent: this });
    this.colorbar = new Colorbar({
      width: 10,
      colormap: this.colormap,
      ticks_padding: 5,
      parent: this,
    });
    this.box = new Box({ parent: this });

    // time
    this.t = 0;
  }

  // -----------------------------------------------------
  // create an array of new points representing gas inside the waveguide
  createNewPoints = () => {
    let { animation } = this.properties;
    let { L, l } = this.properties.physics.dimensions;

    return [...Array(animation.num_of_particles.groups).keys()].map((i) => {
      return new AirParticles({
        x: (i / animation.num_of_particles.groups) * (L + l), // x-coordinate of particles
        particles_per_group: animation.num_of_particles.particles_per_group,
        colormap: animation.colormap,
        parent: this,
      });
    });
  };

  setContext = (context) => {
    this.drawing = new Drawing({ context: context });
  };

  // -----------------------------------------------------
  // calculate new state
  recalculate = (t) => {
    // slow down the time by animation rate
    t /= this.properties.animation.animation_rate;

    // update for each point
    this.points.forEach((point) => {
      point.calculateDisplacement(t);
      point.calculateVelocity(t);
      point.calculatePressure(t);
    });

    this.t = t;
  };

  draw = () => {
    // update size of canvas and all canvas dimensions
    if (this.drawing.drawing_size_has_changed()) {
      this.drawing.update_drawing_size();
      this.colormap.updateColorGradient();
    }

    // draw all the content
    //this.drawColorBackground();
    // draw each point
    for (let i = 0; i < this.points.length; i++) {
      this.points[i].draw();
    }

    // draw box
    this.box.draw();

    // draw colorbar
    this.colorbar.draw(this.properties.signal.graph_limits.pmax);
  };
}

class WaveGuideHelmholtzCanvas extends Component {
  constructor(props) {
    super();
    this.wave_guide_canvas = new MainCanvas(props.properties);
  }

  componentDidMount() {
    // prepare canvas
    this.wave_guide_canvas.setContext(this.refs.canvas.getContext("2d"));
  }

  componentDidUpdate() {
    this.wave_guide_canvas.recalculate(this.props.t);
    this.updateCanvas();
  }

  updateCanvas() {
    // update canvas width (reactive)
    this.refs.canvas.style.width = "100%";
    this.refs.canvas.width = this.refs.canvas.offsetWidth;
    this.refs.canvas.height =
      this.props.properties.animation.canvas_ratio * this.refs.canvas.offsetWidth;
    this.wave_guide_canvas.draw();
  }

  render() {
    return (
      <div>
        <canvas ref="canvas" className="canvas" />
      </div>
    );
  }
}

export default WaveGuideHelmholtzCanvas;
