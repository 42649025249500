// sliders
export const sliderA = {
  title: "A",
  name: "A",
  unit: "",
  min: 0,
  max: 1.0,
  step: 0.01,
  edit: false,
};

function graph_fnc(x, y, state) {
  return x * Math.exp(-Math.pow((1 / state.A) * x, 2) - Math.pow(y, 2));
}

export function calculate_colorplot(graph, state) {
  let x = 0;
  let y = 0;
  for (let idx = 0; idx < graph.x_len; idx++) {
    x = graph.x_min_step_max[0] + idx * graph.x_min_step_max[1];
    for (let idy = 0; idy < graph.y_len; idy++) {
      y = graph.y_min_step_max[0] + idy * graph.y_min_step_max[1];
      graph.arr[idx][idy] = graph_fnc(x, y, state);
    }
  }
}

export function length_from_min_step_max(min_step_max) {
  return Math.floor((min_step_max[2] - min_step_max[0]) / min_step_max[1]);
}
