// prepare data:
// - inputSignal ... sine wave (one period) [in chartjs format]
export function prepareData(A, f, phi) {
  const N = 1000;
  let x = sinusOnePeriod(A, f, phi, N);
  let inputSignal = Array(N);
  for (let i = 0; i < N; i++) {
    inputSignal[i] = { x: i / N, y: x[i] };
  }
  return inputSignal;
}

// sine wave signal (one period)
export function sinusOnePeriod(A, f, phi, N) {
  return [...Array(N).keys()].map(
    (i) => A * Math.sin((((2 * Math.PI * f) / 1000) * i) / N + phi)
  );
}
