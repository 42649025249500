import React, { Component } from "react";
import LineGraph from "../UI/ChartJS/LineGraph";
import { prepareData } from "./Fourier.helper";

class FourierGraph extends Component {
  state = {};
  render() {
    // List of inputs ("props") :
    //   A1   ... amplitude of 1st harmonic
    //   A2   ... amplitude of 2nd harmonic
    //   A3   ... amplitude of 3rd harmonic
    let { osc, freq } = this.props;

    let inputSignal = prepareData(osc, freq);

    let signalData = {
      title: "Waveform",
      xAxes: {
        xLabel: "Time [ms]",
        type: "linear",
      },
      yAxes: [
        {
          data: [inputSignal],
          min: -3,
          max: 3,
          type: "linear",
        },
      ],
      animation: 0,
    };

    return <LineGraph data={signalData} graph_size_ratio={0.6} />;
  }
}

export default FourierGraph;
