import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import PressureBasicsGraph from "./PressureBasics.graph";
import PressureBasicsAudio from "./PressureBasics.audio";
// -------------------------
// Presure Basics:
// Microphone sound is visualized on total pressure scale.
//   - amplitude A (slider variable),
// -------------------------

const dt = 0.1;
const bufferLength = 1024;


const slider = {
  name: "A",
  unit: "",
  min: 0,
  max: 100,
  step: 1,
};

// list of Nonlinear Functions user can choose from
let plot_variables = [
  {
    name: "Atmospheric pressure",
    ylabel: "[Pa]",
    channel: 0,
    DC: 101325,
    amplitude: 0.1,
    zoom_index: 0,
    zoom_limits: [
      { min: 0, max: 200000 },
      { min: 50000, max: 150000 },
      { min: 101000, max: 101600 },
      { min: 101300, max: 101350 },
      { min: 101325 - 10, max: 101325 + 10 },
      { min: 101325 - 5, max: 101325 + 5 },
      { min: 101325 - 2, max: 101325 + 2 },
      { min: 101325 - 1, max: 101325 + 1 },
      { min: 101325 - 0.5, max: 101325 + 0.5 },
      { min: 101325 - 0.2, max: 101325 + 0.2 },
      { min: 101325 - 0.1, max: 101325 + 0.1 }
    ]
  },
  {
    name: "Particle Displacement",
    ylabel: "[&micro;m]",
    channel: 1,
    DC: 0,
    amplitude: 1.1029,
    zoom_index: 0,
    zoom_limits: [
      { min: -1, max: 1 },
      { min: -.5, max: .5 },
      { min: -.2, max: .2 },
      { min: -.1, max: .1 }
    ]
  },
  {
    name: "Particle Velocity",
    ylabel: "[mm/s]",
    channel: 0,
    DC: 0,
    amplitude: 2.5e-1,
    zoom_index: 0,
    zoom_limits: [
      { min: -0.5, max: 0.5 }
    ]
  },
  {
    name: "Density",
    ylabel: "[kg/m^3]",
    channel: 0,
    DC: 1.2,
    amplitude: 8.6e-7,
    zoom_index: 0,
    zoom_limits: [
      { min: 0, max: 2 },
      { min: 1, max: 1.4 },
      { min: 1.2 - 1e-2, max: 1.2 + 1e-2 },
      { min: 1.2 - 1e-3, max: 1.2 + 1e-3 },
      { min: 1.2 - 1e-4, max: 1.2 + 1e-4 },
      { min: 1.2 - 1e-5, max: 1.2 + 1e-5 },
      { min: 1.2 - 1e-6, max: 1.2 + 1e-6 },
    ]
  },
  {
    name: "Temperature",
    ylabel: "[&deg;C]",
    channel: 0,
    DC: 22,
    amplitude: 8.6e-5,
    zoom_index: 0,
    zoom_limits: [
      { min: 0, max: 40 },
      { min: 20, max: 24 },
      { min: 22 - 1e-2, max: 22 + 1e-2 },
      { min: 22 - 1e-3, max: 22 + 1e-3 },
      { min: 22 - 1e-4, max: 22 + 1e-4 },
    ]
  },
];

class PressureBasics extends Component {
  constructor(props) {
    super();
    this.state = {
      // the only parameter that make component update is time
      // other parameters are state independent
      t: 0, // time
    };

    this.A = 50;
    this.PlotIndex = 0;
    this.dataArray_L = new Float32Array(bufferLength);
    this.dataArray_L.fill(128);
    this.dataArray_R = new Float32Array(bufferLength);
    this.dataArray_R.fill(128);
  }

  // handle for slider (Amplitude)
  amplitudeChange = (None, value) => {
    // check if user has entered a numeric value
    if (!isNaN(value)) {
      this.A = Number(value);
    }
  };

  // handle for select (Nnlinear function)
  handlePlotIndex = (event) => {
    let { value } = event.target;
    this.PlotIndex = value;
  };

  // handle for slider (Amplitude)
  zoomin = () => {
    // check if user has entered a numeric value
    let zoom = plot_variables[this.PlotIndex]
    zoom.zoom_index += 1;
    if (zoom.zoom_index > zoom.zoom_limits.length - 1) {
      zoom.zoom_index = zoom.zoom_limits.length - 1;
    }
  };

  // handle for slider (Amplitude)
  zoomout = () => {
    // check if user has entered a numeric value
    let zoom = plot_variables[this.PlotIndex]
    zoom.zoom_index -= 1;
    if (zoom.zoom_index < 0) {
      zoom.zoom_index = 0;
    }
  };

  componentDidMount() {
    // update animation every dt [ms]
    this.interval = setInterval(() => {
      let { t } = this.state;
      this.setState({ t: t + dt }); // increment time by dt [s]
    }, Math.round(1000 * dt)); // update every dt [ms]
  }

  // clean after finishing
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    let zoom_in_disabled = (plot_variables[this.PlotIndex].zoom_index === (plot_variables[this.PlotIndex].zoom_limits.length - 1));
    let zoom_out_disabled = (plot_variables[this.PlotIndex].zoom_index === 0);

    return (
      <div>
        <div className="slider-block">
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            <SliderEdit onChange={this.amplitudeChange} params={slider} val={this.A} />
            <PressureBasicsAudio
              A={this.A}
              dataArray_L={this.dataArray_L}
              dataArray_R={this.dataArray_R}
            />
          </div>
        </div>

        {/* --------------------------------------------------*/}
        {/* select Variable for plot */}
        {/* --------------------------------------------------*/}
        <select value={this.PlotIndex} name="handlePlotIndex" onChange={this.handlePlotIndex}>
          {plot_variables.map((plot_variable, index) => {
            return (
              <option key={index} value={index}>
                {plot_variable.name}
              </option>
            );
          })}
        </select>
        {/* --------------------------------------------------*/}
        {/* -------------------  BUTTONS ---------------------*/}
        {/* --------------------------------------------------*/}
        <div className="slider-block">
          <button onClick={this.zoomout} disabled={zoom_out_disabled}>zoom out</button>
          &nbsp;
          <button onClick={this.zoomin} disabled={zoom_in_disabled}>zoom in</button>
        </div>
        <div>sound pressure level &#8773; {Math.round(20 * Math.log10(this.A / 100) + 65)} dB SPL</div>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        {/* */}
        {/* --------------------------------------------------*/}
        {/* graph (chartjs)*/}
        {/* --------------------------------------------------*/}
        <div className="one-graph">
          <PressureBasicsGraph
            dataArray_L={this.dataArray_L}
            dataArray_R={this.dataArray_R}
            PlotData={plot_variables[this.PlotIndex]}
          />
        </div>
      </div>
    );
  }
}

export default PressureBasics;
