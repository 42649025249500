import React, { Component } from "react";
import LineGraph from "../UI/ChartJS/LineGraph";
import { graphData } from "./BlockedImpedance.helper";

class BlockedImpedanceGraph extends Component {
  render() {
    // List of inputs ("props") :
    //   Bl [Tm] ... force factor
    let { z_values } = this.props;

    // impedance data
    let { Re, Le, labels } = graphData(z_values);
    let dataRe = {
      title: "Apparent resistance",
      xAxes: {
        xLabel: "Frequency [Hz]",
        type: "logarithmic",
        min: 50,
        max: 20000,
      },
      yAxes: [
        {
          data: Re,
          yLabel: "Resistance [ohm]",
          type: "linear",
          label: labels,
        },
      ],
      animation: 0,
    };

    let dataLe = {
      title: "Apparent inductance",
      xAxes: {
        xLabel: "Frequency [Hz]",
        type: "logarithmic",
        min: 50,
        max: 20000,
      },
      yAxes: [
        {
          data: Le,
          yLabel: "Inductance [mH]",
          type: "linear",
        },
      ],
      animation: 0,
    };

    return (
      <div>
        <div>
          <div className="two-graphs">
            <LineGraph data={dataRe} graph_size_ratio={0.7} />
          </div>
          <div className="two-graphs">
            <LineGraph data={dataLe} graph_size_ratio={0.7} />
          </div>
          <div className="clr" />
        </div>{" "}
      </div>
    );
  }
}
export default BlockedImpedanceGraph;
