import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import EddyCurrentsGraph from "./EddyCurrents.graph";
import { sliders, select_Ls_options, select_Ls_values } from "./EddyCurrents.helper";

// -------------------------
// Eddy currents :
// -------------------------

class EddyCurrents extends Component {
  // class state
  state = {
    Re: 8, // resistance [ohm]
    Le: 0.5, // inductance [mH]
    coupling_factor: 0.9, // coupling factor
    Q: 20, // Rs/Ls [ohm/H]
    mode: select_Ls_values.CONSTANT,
  };

  // handle for sliders
  handleChange = (name, value) => {
    this.setState({ [name]: Number(value) });
  };

  handleChangeSelect = (event) => {
    this.setState({ mode: Number(event.target.value) });
  };

  render() {
    let { Re, Le, coupling_factor, Q, mode } = this.state;

    return (
      <div>
        <div className="slider-block">
          {/* --------------------------------------------------*/}
          {/* predefined values */}
          {/* --------------------------------------------------*/}
          <select onChange={this.handleChangeSelect}>
            {select_Ls_options.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.title}
                </option>
              );
            })}
          </select>
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            {sliders.map((slider, index) => {
              return (
                <SliderEdit
                  key={index}
                  onChange={this.handleChange}
                  params={slider}
                  val={this.state[slider.name]}
                />
              );
            })}
          </div>
        </div>
        <div className="one-graph">
          {/* --------------------------------------------------*/}
          {/* graph (chartjs)*/}
          {/* --------------------------------------------------*/}
          <EddyCurrentsGraph
            Re={Re} // el. resistance
            Le={Le * 1e-3} // el. inductance
            coupling_factor={coupling_factor} // intrinsic sensitivity
            Q={Q * 1e3} // Rs/Ls
            mode={mode}
          />
        </div>
      </div>
    );
  }
}
export default EddyCurrents;
