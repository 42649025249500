import React, { Component } from "react";
import MicrophoneArrayGraph from "./MicrophoneArray.graph";
import MicrophoneArrayAudio from "./MicrophoneArray.audio";
import SliderEdit from "../UI/SliderEdit";

// -------------------------
// MicrophoneArray
// - Directivity of a microphone array
// -------------------------
const n_of_transducers_values = [5, 10, 30];
const distance_values = [1, 2, 3];

const sources_angle = (30 / 180) * Math.PI;

// slider parameters
const sliders = [
  {
    title: "theta",
    name: "theta0",
    unit: "deg",
    min: -90,
    max: 90,
    step: 1,
    edit: false,
  },
  {
    name: "frequency",
    unit: "Hz",
    min: 20,
    max: 10e3,
    step: 10,
    edit: false,
  },
];

class MicrophoneArray extends Component {
  // class state
  state = {
    n_of_transducers: n_of_transducers_values[0],
    distance: distance_values[0],
    frequency: 500.0, // frequency [Hz]
    dB: 40, // dynamics
    theta0: 0,
  };

  // handle change
  handleChange = (name, value) => {
    // check if user has entered a numeric value
    if (!isNaN(value)) {
      this.setState({ [name]: parseFloat(value) });
    }
  };

  render() {
    let { frequency, dB, distance, n_of_transducers, theta0 } = this.state;
    return (
      <div>
        <div className="slider-block">
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            {sliders.map((slider, index) => {
              return (
                <SliderEdit
                  key={index}
                  onChange={this.handleChange}
                  params={slider}
                  val={this.state[slider.name]}
                />
              );
            })}
          </div>
        </div>
        <div>
          number of microphones
          <select
            style={{ marginTop: "3px", marginBottom: "3px" }}
            value={n_of_transducers}
            onChange={(e) => this.handleChange("n_of_transducers", Number(e.currentTarget.value))}
          >
            {n_of_transducers_values.map((val) => (
              <option key={val} value={val}>
                {val}
              </option>
            ))}
          </select>
        </div>
        <div>
          distance between microphones
          <select
            style={{ marginTop: "3px", marginBottom: "3px" }}
            value={distance}
            onChange={(e) => this.handleChange("distance", Number(e.currentTarget.value))}
          >
            {distance_values.map((val) => (
              <option key={val} value={val}>
                {val}
              </option>
            ))}
          </select>{" "}
          cm
        </div>
        <MicrophoneArrayAudio
          sources_angle={sources_angle}
          n_of_transducers={n_of_transducers}
          distance={distance / 100}
          theta0={(theta0 / 180) * Math.PI}
        />
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        {/* */}
        {/* --------------------------------------------------*/}
        {/* radio buttons to choose between linear and logarithmic Yaxis of chart.js*/}
        {/* --------------------------------------------------*/}
        <div style={{ width: "100%", maxWidth: "500px", margin: "auto", marginTop: "50px" }}>
          {/* --------------------------------------------------*/}
          {/* graph (chartjs)*/}
          {/* --------------------------------------------------*/}
          <MicrophoneArrayGraph
            sources_angle={sources_angle}
            frequency={frequency} //   frequency [Hz]
            dB={dB} //   dB ... dynamics of the graph
            distance={distance / 100}
            n_of_transducers={n_of_transducers}
            theta0={(theta0 / 180) * Math.PI}
          />
        </div>
      </div>
    );
  }
}
export default MicrophoneArray;
