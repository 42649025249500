import React, { Component } from "react";

// Component used to play audio
// props:   f1, Q1 ... frequency [Hz] and quality factor of High-Pass filter
//          f2, Q2 ... frequency [Hz] and quality factor of Low-Pass filter

class BandPassAudio extends Component {
  state = {
    is_playing: false, // BOOL (audio is/(is not) playing)
  };

  audioContext = null;
  file_buffer = null;

  loadAudioSource() {
    // source -> script node (gainNode) -> output (audioContext.destination)
    // audio context
    let AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioContext = new AudioContext();

    // source node
    this.source_node = this.audioContext.createBufferSource();

    window
      .fetch("/comis.wav")
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) =>
        this.audioContext.decodeAudioData(
          arrayBuffer,
          (audioBuffer) => {
            this.source_node.buffer = audioBuffer;
            this.source_node.loop = true;
            this.source_node.start();
          },
          (error) => console.error(error)
        )
      );

    this.biquadFilter_HP = this.audioContext.createBiquadFilter();
    this.biquadFilter_HP.type = "highpass";

    this.biquadFilter_LP = this.audioContext.createBiquadFilter();
    this.biquadFilter_LP.type = "lowpass";

    this.source_node.connect(this.biquadFilter_HP);
    this.biquadFilter_HP.connect(this.biquadFilter_LP);
  }

  componentDidUpdate() {
    // extract properties
    let { f1, f2 } = this.props;

    // on change any of the properties check if audio is on (checkbox)
    if (this.audioContext !== null) {
      if (this.state.is_playing === true) {
        // change parameters
        this.biquadFilter_HP.frequency.setValueAtTime(f1, this.audioContext.currentTime);
        this.biquadFilter_LP.frequency.setValueAtTime(f2, this.audioContext.currentTime);

        // connect node to destination
        this.biquadFilter_LP.connect(this.audioContext.destination);
      } else {
        // disconnect the node from the destination (it will stop playing)
        this.biquadFilter_LP.disconnect();
      }
    }
  }

  // when unmounting close the audio context
  componentWillUnmount() {
    if (this.audioContext !== null) {
      this.audioContext.close();
    }
  }

  // handle for checkbox audio playing
  handleChangePlay = () => {
    // check if audio context exists, if no, create the audio chain
    if (this.audioContext === null) {
      this.loadAudioSource();
    }
    // change the flag "is_playing"
    this.setState((prevState) => ({
      is_playing: !prevState.is_playing,
    }));
  };

  render() {
    return (
      <div>
        play sound
        <input
          type="checkbox"
          defaultChecked={this.state.is_playing}
          onChange={this.handleChangePlay}
        />
      </div>
    );
  }
}

export default BandPassAudio;
