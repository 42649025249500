import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import FourierGraph from "./Fourier.graph";
import FourierAudio from "./Fourier.audio";
import "./Fourier.css";

// -------------------------
// Several Sine Waves mixed together (Fourier Analysis):
// Signal = Sum of oscilators tuned to frequency f and their integer mutliples
//          Each oscilator has its own
//   - amplitude A (slider variable),
//   - phase init_phase (slider variable).
//   -
// -------------------------

let myAudio = {
  freq: 1000,
  osc: [
    { A: 1.0, init_phase: 0, last_phase: 0 },
    { A: 1.0, init_phase: 0, last_phase: 0 },
    { A: 1.0, init_phase: 0, last_phase: 0 },
  ],
};

class Fourier extends Component {
  state = {
    osc: myAudio.osc,
    freq: myAudio.freq,
  };

  // sliders template
  slider_amplitude = {
    unit: "",
    min: 0,
    max: 1.0,
    step: 0.01,
  };
  slider_phase = {
    unit: "rad",
    min: 0,
    max: 6.28,
    step: 0.01,
  };
  slider_frequency = {
    name: "freq",
    title: "frequency",
    unit: "Hz",
    min: 100,
    max: 5000,
    step: .001,
    log: true
  };

  // handle for slider_frequency
  frequencyChange = (name, value) => {
    // check if user has entered a numeric value
    if (!isNaN(value)) {
      this.setState({ [name]: Number(Math.round(value)) });
    }
  };

  // handle for sliders (Amplitude and Phase)
  handleChange = (name, value) => {
    // check if user has entered a numeric value
    if (!isNaN(value)) {
      const idx = Number(name[1]);
      const amplitude_or_phase = name[0];

      let new_osc = [...this.state.osc];
      if (amplitude_or_phase === "A") {
        // amplitude change
        new_osc[idx] = { ...new_osc[idx], A: Number(value) };
      } else {
        // phase change
        new_osc[idx] = { ...new_osc[idx], init_phase: Number(value) };
      }
      this.setState({ osc: new_osc });
    }
  };

  render() {
    let { osc, freq } = this.state;

    return (
      <div>
        <div className="parameters">
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            {/* frequeny slider */}
            {
              <div className="slider-block">
                <SliderEdit
                  onChange={this.frequencyChange}
                  params={this.slider_frequency}
                  val={this.state.freq}
                />
              </div>
            }
            {this.state.osc.map((osc, index) => {
              /* sliders (amplitude and phase) for each oscillator */
              return (
                <div
                  key={index}
                  className={["slider-block", "fourier-color-box" + index].join(" ")}
                >
                  harmonic {index + 1} (f = {(index + 1) * this.state.freq} Hz)
                  {/* amplitude slider */}
                  <SliderEdit
                    onChange={this.handleChange}
                    params={{
                      ...this.slider_amplitude,
                      name: "A" + String(index),
                      title: "amplitude",
                    }}
                    val={osc.A}
                  />
                  {/* phase slider */}
                  <SliderEdit
                    onChange={this.handleChange}
                    params={{
                      ...this.slider_phase,
                      name: "P" + String(index),
                      title: "phase",
                    }}
                    val={osc.init_phase}
                  />
                </div>
              );
            })}
            <FourierAudio osc={osc} freq={freq} />
          </div>
        </div>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        {/* */}
        {/* --------------------------------------------------*/}
        {/* graph (chartjs)*/}
        {/* --------------------------------------------------*/}
        <div className="one-graph">
          <FourierGraph osc={osc} freq={freq} />
        </div>
      </div>
    );
  }
}

export default Fourier;
