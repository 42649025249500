import React, { Component } from "react";
import LineGraph from "../UI/ChartJS/LineGraph";
import { loudspeaker } from "./AddedMass.helper";
import { LogSpace } from "../../helpers/helpers";

class AddedMassGraph extends Component {
  render() {
    // List of inputs ("props") :
    //   Bl [Tm] ... force factor
    let { added_Mms, add_mass } = this.props;

    if (add_mass === "no") added_Mms = 0;

    // impedance and phase
    let f_ax = LogSpace(1, Math.log10(500), 300);
    let { Z_in } = loudspeaker(added_Mms, f_ax);

    let dataImpedance = {
      title: "Loudspeaker Impedance",
      xAxes: {
        xLabel: "Frequency [Hz]",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [Z_in],
          yLabel: "Absolute value [ohm]",
          type: "linear",
        },
      ],
      tooltip: true,
      animation: 0,
    };

    return (
      <div>
        <div className="one-graph">
          <LineGraph data={dataImpedance} graph_size_ratio={0.7} />
        </div>
      </div>
    );
  }
}
export default AddedMassGraph;
