import { complex, abs, add, multiply, divide, pi } from "mathjs";

// slider for each parameter
export const sliders = [
  {
    name: "Vmult",
    title: "Volume Vab",
    unit: "* Vas",
    min: 0.1,
    max: 2,
    step: 0.01,
    edit: false,
    log: true,
  },
  {
    name: "Rmult",
    title: "Rab",
    unit: "* Ras",
    min: 0,
    max: 5,
    step: 0.01,
    edit: false,
  },
];

const Re = 3.2;
const Le = 0.23e-3;
const Bl = 4.7;
const Mms = 18.2e-3;
const Rms = 1.4;
const Kms = 1020;
const Sd = 250e-4;
const U0 = 2.83;

const rho = 1.184;
const rhoC2 = rho * Math.pow(346.1, 2);

const Vas = (rhoC2 * Math.pow(Sd, 2)) / Kms;
const Bl2 = Math.pow(Bl, 2);

// calculate microphone sensitivity
export function sensitivity(Vmult, Rmult, f_axis) {
  // prepare arrays for absVal and phase
  let Z = [];
  let P = [];
  let Z_baf = [];
  let P_baf = [];
  for (let i = 0; i < f_axis.length; i++) {
    Z[i] = { x: f_axis[i], y: 0 };
    P[i] = { x: f_axis[i], y: 0 };
    Z_baf[i] = { x: f_axis[i], y: 0 };
    P_baf[i] = { x: f_axis[i], y: 0 };
  }

  for (let i = 0; i < f_axis.length; i++) {
    // frequency axis
    let f = f_axis[i];
    let omega = 2 * pi * f;

    let V = Vmult * Vas;
    let Kms_add = (rhoC2 * Math.pow(Sd, 2)) / V;
    let Rms_add = Rmult * Rms;

    let Ze = complex(Re, omega * Le);
    let Zms = complex(Rms + Rms_add, omega * Mms - (Kms + Kms_add) / omega);
    let Zms_baf = complex(Rms, omega * Mms - Kms / omega);

    Z[i].y = abs(add(Ze, divide(Bl2, Zms)));
    Z_baf[i].y = abs(add(Ze, divide(Bl2, Zms_baf)));

    let p = divide(
      complex(0, (((U0 / Math.sqrt(2)) * rho * omega) / (2 * pi)) * Sd * Bl),
      add(multiply(Ze, Zms), Bl2)
    );

    let p_baf = divide(
      complex(0, (((U0 / Math.sqrt(2)) * rho * omega) / (2 * pi)) * Sd * Bl),
      add(multiply(Ze, Zms_baf), Bl2)
    );

    P[i].y = 20 * Math.log10(abs(divide(p, 2e-5)));
    P_baf[i].y = 20 * Math.log10(abs(divide(p_baf, 2e-5)));
  }

  return { Z, P, Z_baf, P_baf };
}
