import React, { Component } from "react";
import { standingWaveRate } from "../../helpers/WaveGuide.helper.js";
import { round } from "../../../../helpers/math.helper";

class WaveGideAnimationInformation extends Component {
  render() {
    let t = this.props.t;
    let { animation, physics, signal, dt } = this.props.properties;
    let current_time = Math.round(10 * (t / animation.animation_rate) * 1000) / 10;
    const show_standing_wave_rate = signal.standing_waves && signal.A.abs * signal.B.abs > 0;
    const show_reflection_coefficients = physics.reflection?.show_coefficients;

    return (
      <>
        <div className="slider-block">
          <button onClick={this.props.handleRestart}>restart</button>
          <button onClick={this.props.handlePause}>{dt === 0.0 ? "play" : "pause"}</button>
        </div>
        current time: {Number.isInteger(current_time) ? current_time + ".0" : current_time}
        {" ms"}
        <table style={{ fontSize: "80%", margin: "auto" }}>
          <tbody>
            <tr>
              <td>animation slowed down {animation.animation_rate}x</td>
            </tr>

            <tr>
              <td>displacement multplied by {signal.displacement_zoom}</td>
            </tr>

            {physics.medium?.title !== undefined && (
              <tr>
                <td>{physics.medium.title}</td>
              </tr>
            )}

            {show_standing_wave_rate && (
              <tr>
                <td>standing wave ratio : {round(standingWaveRate(signal))}</td>
              </tr>
            )}

            {show_reflection_coefficients && (
              <>
                <tr>
                  <td>
                    reflection coefficient R ={" "}
                    {round(signal.left_right === "left" ? signal.Rf.re : signal.Rg.re, 2)}
                  </td>
                </tr>
                <tr>
                  <td>
                    transmission coefficient T ={" "}
                    {round(signal.left_right === "left" ? signal.Tf.re : signal.Tg.re, 2)}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </>
    );
  }
}

export default WaveGideAnimationInformation;
