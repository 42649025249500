import React, { Component } from "react";
import { COLORS } from "../../../helpers/helpers";

import Chart from "chart.js";
import Controller from "./PolarGraph.controller";
import Scale, { defaults } from "./PolarGraph.scale";

// Register the Controller and Scale
Chart.controllers.polar = Controller;
Chart.defaults.polar = {
  aspectRatio: 1,
  scale: {
    type: "polar",
  },
};
Chart.scaleService.registerScaleType("polar", Scale, defaults);

class PolarGraph extends Component {
  myChart = {};
  chartRef = React.createRef();

  componentDidMount() {
    this.myChart = new Chart(
      this.chartRef.current.getContext("2d"), // context
      this.createChartContext() // what to render
    );
  }

  componentDidUpdate() {
    // update chart data for each line in the graph

    this.myChart.data.datasets = this.props.data.data.map((d, idx) => {
      return {
        data: d,
        borderColor: COLORS[idx],
        pointBorderColor: COLORS[idx],
        pointBackgroundColor: COLORS[idx],
      };
    });

    this.myChart.options.title.text = this.props.data.title;

    this.myChart.options.scale.ticks.min = this.props.data.axis.min;
    this.myChart.options.scale.ticks.max = this.props.data.axis.max;
    this.myChart.options.scale.ticks.step = this.props.data.axis.step;
    this.myChart.update();

    this.myChart.options.dragPoint = this.props.data.dragPoint;
  }

  render() {
    return (
      <div>
        <canvas id="myChart" ref={this.chartRef} />
      </div>
    );
  }

  createChartContext = () => {
    let { axis, data, dragPoint } = this.props.data;

    return {
      type: "polar",
      data: {
        datasets: data.map((d, idx) => {
          return {
            data: d,
            label: this.props.data.legend !== undefined ? this.props.data.legend[idx] : "",
            borderColor: COLORS[idx],
            pointBorderColor: COLORS[idx],
            pointBackgroundColor: COLORS[idx],
          };
        }),
      },
      options: {
        dragPoint: dragPoint,
        tooltips: { enabled: false },
        hover: { mode: null },
        title: {
          display: true,
          text: this.props.data.title,
        },
        legend: {
          display: this.props.data.legend !== undefined,
        },
        scale: {
          ticks: {
            min: axis.min,
            max: axis.max,
          },
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
        elements: { point: { radius: 0 } },
      },
    };
  };
}

export default PolarGraph;
