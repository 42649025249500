import React, { useState } from "react";
import { signalSelect } from "../../helpers/WaveGuide.signals.js";

export default function WaveGuideSignals({ properties }) {
  let { signals, physics } = properties;
  const [signal_selected, setSignal] = useState(0);

  // handle for selection of signals
  const handleChangeSignal = (event) => {
    // if reflection keep R coefficient
    const R = properties.signal.Rf;

    const new_signal_selected = Number(event.target.value);
    properties.signal = signalSelect(signals[new_signal_selected], physics);

    setSignal(new_signal_selected);

    // if reflection, then set R
    if (physics.reflection) {
      properties.signal.setReflection(R, physics);
    }

    properties.flag_recalculate = true;
  };

  return (
    <>
      {
        /* --- SELECT signal --------------------------------*/
        properties.signals.length > 1 && (
          <select className="graph-property" value={signal_selected} onChange={handleChangeSignal}>
            {signals.map((option, index) => {
              return (
                <option key={index} value={index}>
                  {option.title}
                </option>
              );
            })}
          </select>
        )
      }
    </>
  );
}
