import React, { Component } from "react";
import LineGraph from "../UI/ChartJS/LineGraph";
import { FRF } from "./BandPass.helper";

class BandPassGraph extends Component {
  render() {
    // List of inputs ("props") :
    //   f1 [Hz] ... low cut-off frequency
    //   f2 [Hz] ... high cut-off frequency
    let { f1, f2 } = this.props;

    // abs and phase
    let { FRF_abs } = FRF(f1, f2);

    let data = {
      xAxes: {
        xLabel: "Frequency [Hz]",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [FRF_abs],
          yLabel: "FRF [dB]",
          type: "linear",
          min: -50,
          max: 10,
        },
      ],
      animation: 0,
    };

    return <LineGraph data={data} graph_size_ratio={0.65} />;
  }
}
export default BandPassGraph;
