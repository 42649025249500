import { fft, util } from "fft-js";

// prepare data:
// - inputSignal ... sine wave (one period) [in chartjs format]
export function prepareData(N) {
  let inputSignal = Array(N);
  for (let i = 0; i < N; i++) {
    inputSignal[i] = { x: i, y: 0 };
  }
  inputSignal[0].y = 1;

  return inputSignal;
}

export function fft_mag_phase(data, show_phase) {
  const N = data.length;
  const Nfft = 256 * N;

  // extract signal from data
  let y = Array(Nfft);
  for (let i = 0; i < N; i++) {
    y[i] = data[i].y;
  }
  for (let i = N; i < Nfft; i++) {
    y[i] = 0;
  }

  let Y = fft(y);

  let YfftMag = util.fftMag(Y);

  let Ymag = Array(Nfft / 2);
  let Yphase = Array(Nfft / 2);
  for (let i = 0; i < Nfft / 2; i++) {
    Ymag[i] = { x: i / Nfft, y: 20 * Math.log10(YfftMag[i]) };
    if (show_phase) {
      Yphase[i] = {
        x: i / Nfft,
        y: (180 / Math.PI) * Math.atan2(Y[i][1], Y[i][0]),
      };
    } else {
      Yphase[i] = NaN;
    }
  }

  return { Ymag, Yphase };
}
