import React, { Component } from "react";

class MicrophoneDirectivityAudio extends Component {
  state = {
    is_playing: false, // BOOL (audio is/(is not) playing)
  };

  audioContext = null;
  file_buffer = null;

  loadAudioSource() {
    // source -> script node (gainNode) -> output (audioContext.destination)
    // audio context
    let AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioContext = new AudioContext();

    // source node
    this.source_node = this.audioContext.createBufferSource();

    window
      .fetch("/comis.wav")
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) =>
        this.audioContext.decodeAudioData(
          arrayBuffer,
          (audioBuffer) => {
            this.source_node.buffer = audioBuffer;
            this.source_node.loop = true;
            this.source_node.start();
          },
          (error) => console.error(error)
        )
      );

    this.gainNode = this.audioContext.createGain();
    this.source_node.connect(this.gainNode);
  }

  componentDidUpdate() {
    // extract properties
    let { gain } = this.props;

    // on change any of the properties check if audio is on (checkbox)
    if (this.audioContext !== null) {
      if (this.state.is_playing === true) {
        // change parameters
        this.gainNode.gain.setValueAtTime(gain, this.audioContext.currentTime);

        // connect node to destination
        this.gainNode.connect(this.audioContext.destination);
      } else {
        // disconnect the node from the destination (it will stop playing)
        this.gainNode.disconnect();
      }
    }
  }

  // when unmounting close the audio context
  componentWillUnmount() {
    if (this.audioContext !== null) {
      this.audioContext.close();
    }
  }

  // handle for checkbox audio playing
  handleChangePlay = () => {
    // check if audio context exists, if no, create the audio chain
    if (this.audioContext === null) {
      this.loadAudioSource();
    }
    // change the flag "is_playing"
    this.setState((prevState) => ({
      is_playing: !prevState.is_playing,
    }));
  };

  render() {
    return (
      <div>
        play sound
        <input
          type="checkbox"
          defaultChecked={this.state.is_playing}
          onChange={this.handleChangePlay}
        />
      </div>
    );
  }
}

export default MicrophoneDirectivityAudio;
