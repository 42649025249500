import React, { Component } from "react";

class PressureBasicsAudio extends Component {
  state = {
    is_playing: false, // BOOL (audio is/(is not) playing)
  };

  audioContext = null;
  file_buffer = null;

  loadAudioSource() {
    // source -> script node (gainNode) -> output (audioContext.destination)
    // audio context
    let AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioContext = new AudioContext();

    // source node
    this.source_node = this.audioContext.createBufferSource();

    window
      .fetch("/noisy_tones.wav")
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) =>
        this.audioContext.decodeAudioData(
          arrayBuffer,
          (audioBuffer) => {
            this.source_node.buffer = audioBuffer;
            this.source_node.loop = true;
            this.source_node.start();
          },
          (error) => console.error(error)
        )
      );

    // gain node
    this.gainNode = this.audioContext.createGain();
    this.gainNode.gain.value = 0;

    this.splitter = this.audioContext.createChannelSplitter();

    this.analyser_L = this.audioContext.createAnalyser();
    this.analyser_L.fftSize = 2048;
    this.analyser_R = this.audioContext.createAnalyser();
    this.analyser_R.fftSize = 2048;

    this.source_node.connect(this.gainNode);

    this.gainNode.connect(this.splitter);
    this.splitter.connect(this.analyser_L, 0, 0);
    this.splitter.connect(this.analyser_R, 1, 0);
  }

  componentDidUpdate() {
    if (this.audioContext !== null) {
      // on change any of the properties check if audio is on (checkbox)
      if (this.state.is_playing === true) {
        // set the gain (using a linear ramp (fade-in)) to avoid abrupt changes
        this.gainNode.gain.linearRampToValueAtTime(
          this.props.A / 100,
          this.audioContext.currentTime + 0.1
        );

        this.analyser_L.getFloatTimeDomainData(this.props.dataArray_L);
        this.analyser_R.getFloatTimeDomainData(this.props.dataArray_R);

        // connect the scriptNode to the destination (it will start playing)
        this.analyser_L.connect(this.audioContext.destination);
      } else {
        // disconnect the oscilator from the gain (it will stop playing)
        this.analyser_L.disconnect();
      }
    }
  }

  // when unmounting close the audio context
  componentWillUnmount() {
    if (this.audioContext !== null) {
      this.audioContext.close();
    }
  }

  // handle for checkbox audio playing
  handleChangePlay = () => {
    // check if audio context exists, if no, create the audio chain
    if (this.audioContext === null) {
      this.loadAudioSource();
    }
    // change the flag "is_playing"
    this.setState((prevState) => ({
      is_playing: !prevState.is_playing,
    }));
  };


  render() {
    return (
      <div>
        <div className="slider-block">
          <button
            onClick={this.handleChangePlay}
            style={{ fontSize: "1.6rem", padding: "0.6rem" }}
          >{this.state.is_playing ? "PAUSE" : "PLAY"}</button>
        </div>
      </div >
    );
  }
}

export default PressureBasicsAudio;
