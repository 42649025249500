import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import VentedBoxSystemGraph from "./VentedBoxSystem.graph";
import VentedBoxSystemSVG from "./VentedBoxSystem.svg";
import { getLength, getVolume } from "./VentedBoxSystem.helper";
import { sliders } from "./VentedBoxSystem.helper";
import { round } from "../../helpers/math.helper";

// -------------------------
// Vented-box loudspeaker system simulation
// - Loudspeaker with given TS parameters has a closed-box which can change
//        Vmult ... Volume = Vmult * Vas
//        Rmult ... Rms_tot = Rmult * Rms
// -------------------------

class VentedBoxSystem extends Component {
  // class state
  state = {
    Vmult: 1, // multiplication factor of volume
    fmult: 1, // multiplication factor of mechanical resistance
  };

  // handle for sliders
  handleChange = (name, value) => {
    this.setState({ [name]: Number(value) });
  };

  render() {
    let { Vmult, fmult } = this.state;

    return (
      <div>
        <div className="slider-block">
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            {sliders.map((slider, index) => {
              return (
                <SliderEdit
                  key={index}
                  onChange={this.handleChange}
                  params={slider}
                  val={this.state[slider.name]}
                />
              );
            })}
          </div>
        </div>
        {/* --------------------------------------------------*/}
        {/* -----------------  TEXT INFO  --------------------*/}
        {/* --------------------------------------------------*/}
        <p style={{ fontSize: "0.8em" }}>
          vent length = {round(getLength(Vmult, fmult) * 100, 2)} cm
          <br />
          box volume = {round(getVolume(Vmult) * 100, 2)} l
        </p>
        {/* --------------------------------------------------*/}
        {/* ----------------  SVG DRAWING  -------------------*/}
        {/* --------------------------------------------------*/}
        <div style={{ margin: "auto", width: "50%" }}>
          <VentedBoxSystemSVG Vmult={Vmult} fmult={fmult} />
        </div>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        <div>
          <VentedBoxSystemGraph Vmult={Vmult} fmult={fmult} />
        </div>
      </div>
    );
  }
}
export default VentedBoxSystem;
