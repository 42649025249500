import { Complex } from "mathjs";

// main colors
export const COLORS = [
  "#131A4F",
  "#FF6040",
  "#D0DAE9",
  "#FBAB00",
  "#2EB6EA",
  "#3AAA35",
  "#FF4141",
  "#FF9A9A",
  "#FFEBA3",
  "#BEEBFC",
  "#C6F1C3",
];

// LogSpace: Logarithmically spaced vector
//    LogSpace(FROM, TO, NUM) generates a vector of NUM logarithmically
//    equally spaced points between decades 10^FROM and 10^TO.
export function LogSpace(from, to, num) {
  var a = (to - from) / (num - 1);
  let data = [...Array(num).keys()].map((x) => Math.pow(10, a * x + from));
  return new Float32Array(data);
}

// air parameters
export const AirParameters = {
  c0: 343, // sound speed [m/s]
  rho0: 1.204, // air density [kg/m^3]
};

export function MatlabData(string_data) {
  let data = [];
  for (let key in string_data) {
    if (isNaN(string_data[key][0])) {
      // is string => complex number
      data[key] = string_data[key].map((x) => Complex(x));
    } else {
      // is nuber => real number
      data[key] = string_data[key];
    }
  }
  return data;
}

// scale value from <in_min, in_max> coordinates to <out_min, out_max>
export function scale(value, in_min, in_max, out_min, out_max) {
  return ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
}

// scale value from <in_min, in_max> coordinates to <out_min, out_max> and limit output to <out_lim_min, out_lim_max>
export function scaleWithLimits(value, in_min, in_max, out_min, out_max, out_lim_min, out_lim_max) {
  let out = scale(value, in_min, in_max, out_min, out_max);
  return Math.min(Math.max(out, out_lim_min), out_lim_max);
}

// adjust font size to (canvas/window) size
export function adjust_font_size(width, normal_size) {
  let ratio = 1;
  if (width < 500) ratio = 0.9;
  if (width < 400) ratio = 0.8;
  if (width < 300) ratio = 0.7;

  return Math.ceil(ratio * normal_size);
}

// zero padding of a string
export function pad(num, size) {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}
