import React, { Component } from "react";
import { getLength } from "./VentedBoxSystem.helper";

function convertPolynomPoints(arrayPts) {
  //return arrayPts[0][0] + "," + arrayPts[0][1] + " 250, 190, 156, 210";
  return arrayPts
    .map(function (point) {
      return point[0] + "," + point[1];
    })
    .join(" ");
}

class VentedBoxSystemSVG extends Component {
  render() {
    // List of inputs ("props") :
    //        Vmult ... Volume = Vmult * Vas
    //        fmult ... fb = fmult * fs
    let { Vmult, fmult } = this.props;
    let boxWidth = 35 + (Vmult - 0.1) * 90;
    let HP = { x: 200 - boxWidth / 2, y: 20, w: 30, h: 35, l: 30 };
    let BOX = { x1: 200 - boxWidth / 2, y1: 10, x2: 200 + boxWidth / 2, y2: 140 };
    let VENT = { y1: BOX.y2 - 30, y2: BOX.y2 - 10 };
    let L = getLength(Vmult, fmult);

    const SPEAKER_PTS = convertPolynomPoints([
      [HP.x + HP.w / 2, HP.y + HP.l / 2],
      [HP.x + HP.w / 2, HP.y + HP.l / 2 + HP.h],
      [HP.x, HP.y + HP.l + HP.h],
      [HP.x, HP.y],
      [HP.x + HP.w / 2, HP.y + HP.l / 2],
      [HP.x + HP.w, HP.y + HP.l / 2],
      [HP.x + HP.w, HP.y + HP.l / 2 + HP.h],
      [HP.x + HP.w / 2, HP.y + HP.l / 2 + HP.h],
    ]);

    const LINE_STYLE = { fill: "white", stroke: "black", strokeWidth: 2 };

    return (
      <svg viewBox="0 0 400 150">
        {/*-- BOX --*/}
        <line x1={BOX.x2} y1={BOX.y1} x2={BOX.x2} y2={BOX.y2} style={LINE_STYLE} />
        <line x1={BOX.x1} y1={BOX.y1} x2={BOX.x2} y2={BOX.y1} style={LINE_STYLE} />
        <line x1={BOX.x1} y1={BOX.y2} x2={BOX.x2} y2={BOX.y2} style={LINE_STYLE} />
        <line x1={BOX.x1} y1={BOX.y1} x2={BOX.x1} y2={VENT.y1} style={LINE_STYLE} />
        <line x1={BOX.x1} y1={BOX.y2} x2={BOX.x1} y2={VENT.y2} style={LINE_STYLE} />

        {/*-- VENT --*/}
        <line x1={BOX.x1} y1={VENT.y1} x2={BOX.x1 + 700 * L} y2={VENT.y1} style={LINE_STYLE} />
        <line x1={BOX.x1} y1={VENT.y2} x2={BOX.x1 + 700 * L} y2={VENT.y2} style={LINE_STYLE} />

        {/*-- HP --*/}
        <polygon points={SPEAKER_PTS} style={LINE_STYLE} />
      </svg>
    );
  }
}
export default VentedBoxSystemSVG;
