import React, { useState, useEffect } from "react";

import "./WaveGuide.amplitude.css";

export default function WaveGuideAmplitude(props) {
  const { properties, name, title } = props;
  const value = properties.signal[name];

  const [abs, setAbs] = useState(value.abs ?? 1.0);
  const [arg, setPhase] = useState(value.arg ?? 0.0);
  const [invert_arg, setInvertPhase] = useState(false);

  useEffect(() => {
    const arg_add = invert_arg ? 180 : 0;
    value.abs = Number(abs);
    value.arg = (((Number(arg) + arg_add) % 360) * Math.PI) / 180;
    properties.flag_recalculate = true;
  }, [abs, arg, invert_arg]);

  return (
    <div className="waveguide-amplitude-main">
      <div className="waveguide-amplitude-block">
        <div className="waveguide-amplitude-text">
          {title} = {abs}
        </div>
        <input
          type="range"
          onChange={(e) => {
            setAbs(e.target.value);
          }}
          min="0"
          max="1"
          value={abs}
          step="0.01"
        />
      </div>

      {value.arg_edit && (
        <div className="waveguide-amplitude-block">
          <div className="waveguide-amplitude-text">phase = {arg}°</div>
          <input
            type="range"
            onChange={(e) => {
              setPhase(e.target.value);
            }}
            min="0"
            max="360"
            value={arg}
            step="1"
          />
        </div>
      )}

      {value.inv_edit && (
        <div className="waveguide-amplitude-block">
          <div className="waveguide-amplitude-text">invert phase</div>
          <input
            type="checkbox"
            defaultChecked={invert_arg}
            onChange={(e) => setInvertPhase((v) => !v)}
          />
        </div>
      )}
    </div>
  );
}
