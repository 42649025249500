import React, { Component } from "react";
import LineGraph from "../UI/ChartJS/LineGraph";
import { LogSpace } from "../../helpers/helpers";
import { sensitivity } from "./PassiveRadiator.helper";
import { COLORS } from "../../helpers/helpers";

class PassiveRadiatorGraph extends Component {
  render() {
    let { Rmult, fmult } = this.props;

    // frequency axis
    let f_ax = LogSpace(Math.log10(10), Math.log10(150), 100);

    let { Z_pr, Z_et, Pd, Pp, Pb } = sensitivity(Rmult, fmult, f_ax);

    // graph data
    let dataImpedance = {
      title: "Loudspeaker Impedance",
      xAxes: {
        xLabel: "f/fs [-]",
        type: "logarithmic",
        min: 0.2,
        max: 3,
      },
      yAxes: [
        {
          data: [Z_pr, Z_et],
          label: ["Z_ebr", "Z_et"],
          yLabel: "Input impedance (abs)",
          type: "linear",
        },
      ],
      animation: 0,
    };

    let dataSensitivity = {
      title: "SPL @2.83V @1m",
      xAxes: {
        xLabel: "f/fs [-]",
        type: "logarithmic",
        min: 0.2,
        max: 3,
      },
      yAxes: [
        {
          data: [Pd, Pp, Pb],
          borderColor: [COLORS[0], COLORS[1], COLORS[3]],
          backgroundColor: [COLORS[0], COLORS[1], COLORS[3]],
          yLabel: "Sound Pressure Level [dB SPL]",
          label: ["Pd", "Pp", "Pax"],
          type: "linear",
          min: 60,
          max: 110,
        },
      ],
    };

    return (
      <div>
        <div className="two-graphs">
          <LineGraph data={dataImpedance} graph_size_ratio={0.7} />
        </div>
        <div className="two-graphs">
          <LineGraph data={dataSensitivity} graph_size_ratio={0.7} />
        </div>
        <div className="clr" />
      </div>
    );
  }
}
export default PassiveRadiatorGraph;
