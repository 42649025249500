import React, { Component } from "react";
import LineGraphDrag from "../../../UI/ChartJS/LineGraphDrag";
import { prepareDataSpace, prepareDataStanding } from "../../helpers/WaveGuide.helper.js";
import { COLORS } from "../../../../helpers/helpers";

class WaveGuideGraphSpace extends Component {
  // handle for microphone movement in the LineGraph
  handleChangeMicrophoneLineGraph = (e, datasetIndex, index, value) => {
    this.props.properties.microphone.x = value.x;
  };

  render() {
    let { pressureSignal, velocitySignal } = prepareDataSpace(
      this.props.t,
      this.props.physics,
      this.props.signal,
      this.props.callback_microphone_change // callback function
    );

    // main pressure data
    let pressure_data = [pressureSignal];
    let pressure_pointRadius = [0];
    let pressure_borderColor = [COLORS[0]];
    let pressure_backgroundColor = [COLORS[0]];
    let pressure_dragData = [false];
    let pressure_lineWidth = [3];
    let pressure_borderDash = [undefined];

    // main velocity data
    let velocity_data = [velocitySignal];
    let velocity_borderColor = [COLORS[1]];
    let velocity_backgroundColor = [COLORS[1]];
    let velocity_lineWidth = [3];
    let velocity_borderDash = [undefined];

    // if microphone is on
    if (this.props.x !== undefined) {
      let microphonePoint = [{ x: this.props.x, y: 0 }];
      pressure_data.unshift(microphonePoint);
      pressure_pointRadius.unshift(6);
      pressure_borderColor.unshift("#000000");
      pressure_backgroundColor.unshift("#00C800");
      pressure_dragData.unshift(true);
      pressure_lineWidth.unshift(1);
      pressure_borderDash.unshift(undefined);
    }

    // compute standing waves
    let { pressureStandingA, pressureStandingB, velocityStandingA, velocityStandingB } =
      prepareDataStanding(this.props.physics, this.props.signal);

    // add standing waves to pressure graph
    pressure_data.push(pressureStandingA, pressureStandingB);
    pressure_pointRadius.push(0, 0);
    pressure_borderColor.push(COLORS[0], COLORS[0]);
    pressure_backgroundColor.push(COLORS[0], COLORS[0]);
    pressure_dragData.push(false, false);
    pressure_lineWidth.push(1, 1);
    pressure_borderDash.push([10, 5], [10, 5]);

    // add standing waves to velocity graph
    velocity_data.push(velocityStandingA, velocityStandingB);
    velocity_borderColor.push(COLORS[1], COLORS[1]);
    velocity_backgroundColor.push(COLORS[1], COLORS[1]);
    velocity_lineWidth.push(1, 1);
    velocity_borderDash.push([10, 5], [10, 5]);

    let { pmax, vmax } = this.props.signal.graph_limits;

    let signalData = {
      title: "Acoustic pressure and velocity",
      dragX: true,
      dragY: false,
      onDragUpdate: true,
      xAxes: {
        xLabel: "x [m]",
        type: "linear",
      },
      yAxes: [
        {
          data: pressure_data,
          yLabel: "Acoustic pressure [Pa]",
          min: -pmax,
          max: pmax,
          type: "linear",
          pointRadius: pressure_pointRadius,
          borderColor: pressure_borderColor,
          backgroundColor: pressure_backgroundColor,
          dragData: pressure_dragData,
          lineWidth: pressure_lineWidth,
          borderDash: pressure_borderDash,
        },
        {
          data: velocity_data,
          yLabel: "Velocity [mm/s]",
          min: -1000 * vmax,
          max: 1000 * vmax,
          type: "linear",
          borderColor: velocity_borderColor,
          backgroundColor: velocity_backgroundColor,
          lineWidth: velocity_lineWidth,
          borderDash: velocity_borderDash,
        },
      ],
      animation: 0,
    };

    return (
      <LineGraphDrag
        data={signalData}
        func={this.handleChangeMicrophoneLineGraph}
        graph_size_ratio={0.5}
      />
    );
  }
}

export default WaveGuideGraphSpace;
