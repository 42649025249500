import { scaleWithLimits } from "../../../helpers/helpers";

export function surface(radius) {
  return Math.PI * Math.pow(radius, 2);
}

export function distanceFromPoint(a, b) {
  return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
}

export function distanceFromArea(a, b) {
  return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
}

export function setDensityPoints(rho) {
  // density points
  return [
    Math.round(scaleWithLimits(rho, 1, 1000, 5, 20, 5, 20)),
    Math.round(scaleWithLimits(rho, 1, 1000, 5, 20, 5, 20)),
  ];
}

export function setAmplitude(signal, dimensions) {
  let { L, l, S, s } = dimensions;
  let { k } = signal;
  signal.A = 1 / (Math.cos(k * L) * Math.cos(k * l) - (S / s) * Math.sin(k * L) * Math.sin(k * l));
}

export function pressureFnc(signal, x, t, physics) {
  let { A, k, omega } = signal;
  let { L, S, s } = physics.dimensions;
  // pressure
  if (x <= L) {
    // cavity
    return A * Math.cos(k * x) * Math.cos(omega * t);
  } else {
    // neck
    return (
      A *
      (Math.cos(k * L) * Math.cos(k * (x - L)) -
        (S / s) * Math.sin(k * L) * Math.sin(k * (x - L))) *
      Math.cos(omega * t)
    );
  }
}

export function velocityFnc(signal, x, t, physics) {
  let { rho, c0 } = physics;
  let { A, k, omega } = signal;
  let { L, S, s } = physics.dimensions;

  // velocity
  if (x <= L) {
    // cavity
    return (A * Math.sin(k * x) * Math.sin(omega * t)) / (rho * c0);
  } else {
    // neck
    return (
      (A *
        (Math.cos(k * L) * Math.sin(k * (x - L)) +
          (S / s) * Math.sin(k * L) * Math.cos(k * (x - L))) *
        Math.sin(omega * t)) /
      (rho * c0)
    );
  }
}

export function displacementFnc(signal, x, t, physics) {
  let { rho, c0 } = physics;
  let { A, k, omega } = signal;
  let { L, S, s } = physics.dimensions;

  // displacement
  if (x <= L) {
    // cavity
    return (-A * Math.sin(k * x) * Math.cos(omega * t)) / (k * rho * c0 * c0);
  } else {
    // neck

    return (
      (-A *
        (Math.cos(k * L) * Math.sin(k * (x - L)) +
          (S / s) * Math.sin(k * L) * Math.cos(k * (x - L))) *
        Math.cos(omega * t)) /
      (k * rho * c0 * c0)
    );
  }
}

export function prepareDataImpedanceFreq(signal, physics) {
  const N = 5000;
  let f_max = 1000;

  let { L, l, S, s } = physics.dimensions;
  let { rho, c0 } = physics;

  let Z_abs = Array(N);
  for (let i = 0; i < N; i++) {
    let f = (i / N) * f_max; // sampling period
    let k = (2 * Math.PI * f) / c0;

    let A = 1 / (Math.cos(k * L) * Math.cos(k * l) - (S / s) * Math.sin(k * L) * Math.sin(k * l));

    let p_module = Math.abs(
      A * (Math.cos(k * L) * Math.cos(k * l) - (S / s) * Math.sin(k * L) * Math.sin(k * l))
    );

    let v_module =
      Math.abs(
        -A * (Math.cos(k * L) * Math.sin(k * l) + (S / s) * Math.sin(k * L) * Math.cos(k * l))
      ) /
      (rho * c0);

    Z_abs[i] = {
      x: f,
      y: Math.log10(p_module / v_module),
    };
  }

  let freqLine = [
    { x: signal.freq.value, y: -2 },
    { x: signal.freq.value, y: 6 },
  ];
  let freqPoint = [{ x: signal.freq.value, y: 1 }];

  return { Z_abs, freqPoint, freqLine };
}

export function getWaveguideIndex(x, x_interface) {
  return x < x_interface ? 0 : 1;
}
