import React, { Component } from "react";
import Chart from "chart.js";
import { COLORS } from "../../../helpers/helpers";
import { SetYaxisTicks } from "./graph.helpers";

class LineGraph extends Component {
  myChart = {};
  chartRef = React.createRef();

  componentDidMount() {
    this.myChart = new Chart(
      this.chartRef.current.getContext("2d"), // context
      this.createChartContext() // what to render
    );
  }

  componentDidUpdate() {
    let { yAxes, title = "" } = this.props.data;
    // update chart data for each line in the graph
    let k = 0;
    for (let i = 0; i < yAxes.length; i++) {
      // update data and legend
      for (let j = 0; j < yAxes[i].data.length; j++) {
        // update data
        this.myChart.data.datasets[k].data = yAxes[i].data[j];
        // update legend
        this.myChart.data.datasets[k].label = yAxes[i].label?.[j];
        k += 1;
      }

      // update type of the yAxis (linear or logarithmic)
      this.myChart.options.scales.yAxes[i].type = yAxes[i].type;
      // update type of the yAxis (linear or logarithmic)
      this.myChart.options.scales.yAxes[i].ticks.min = yAxes[i].min;
      this.myChart.options.scales.yAxes[i].ticks.max = yAxes[i].max;

      // update ylabel
      this.myChart.options.scales.yAxes[i].scaleLabel.labelString = yAxes[i].yLabel;

    }
    this.myChart.options.title.text = title;
    this.myChart.update();
  }

  render() {
    return (
      <div>
        <canvas
          id="myChart"
          ref={this.chartRef}
          width="100%"
          height={this.props.graph_size_ratio * 100 + "%"}
        />
      </div>
    );
  }

  // -------------------------------------
  // createDataset, createYAxes, createChartContext
  // ... functions to prepare chart data and options
  // -------------------------------------
  createDataset = (idxAxis, idxData, idxColor) => {
    let yAxes = this.props.data.yAxes[idxAxis];
    return {
      data: yAxes.data[idxData],
      label: yAxes.label?.[idxData] ?? "",
      fill: false,
      pointRadius: yAxes.pointRadius?.[idxData] ?? 0,
      borderColor: yAxes.borderColor?.[idxData] ?? COLORS[idxColor],
      backgroundColor: yAxes.backgroundColor?.[idxData] ?? COLORS[idxColor],
      borderDash: yAxes.borderDash?.[idxData] ?? undefined,
      borderWidth: yAxes.lineWidth?.[idxData] ?? 3,
      yAxisID: idxAxis,
    };
  };

  createYAxes = (idxAxis) => {
    let yAxes = this.props.data.yAxes[idxAxis];
    return {
      scaleLabel:
        yAxes.yLabel === undefined
          ? { display: false }
          : {
            display: true,
            fontColor: COLORS[idxAxis],
            labelString: yAxes.yLabel,
          },
      type: yAxes.type,
      ticks: {
        min: yAxes.min,
        max: yAxes.max,
        fontColor: COLORS[idxAxis],
        callback: (value, index, values) => {
          return SetYaxisTicks(value, yAxes.type);
        },
      },
      position: idxAxis === 0 ? "left" : "right",
      id: idxAxis,
      gridLines: { drawOnChartArea: idxAxis === 0 }, // grid lines only for first dataset
    };
  };

  createChartContext = () => {
    let { xAxes, yAxes, title, animation = 100, point_radius = 0 } = this.props.data;

    // prepare datasets
    let datasets = [];
    let k = 0;
    for (let i = 0; i < yAxes.length; i++) {
      for (let j = 0; j < yAxes[i].data.length; j++) {
        datasets[k] = this.createDataset(i, j, k);
        k += 1;
      }
    }

    // prepare yAxes
    let yAxesData = [];
    for (let i = 0; i < yAxes.length; i++) {
      yAxesData[i] = this.createYAxes(i);
    }

    // prepare tooltip
    let tooltips = { enabled: false };
    let hover = { mode: null };

    if (this.props.data.tooltip !== undefined) {
      tooltips = { enabled: true };
      hover = {
        onHover: function (e) {
          var point = this.getElementAtEvent(e);
          if (point.length) e.target.style.cursor = "pointer";
          else e.target.style.cursor = "default";
        },
      };
    }

    return {
      type: "line",
      data: {
        datasets: datasets,
      },
      options: {
        tooltips: tooltips,
        hover: hover,

        title: title === undefined ? { display: false } : { display: true, text: title },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: xAxes.xLabel,
              },
              type: xAxes.type,
              ticks: {
                min: xAxes.min,
                max: xAxes.max,
                callback: (value, index, values) => {
                  return SetYaxisTicks(value, xAxes.type);
                },
              },
            },
          ],
          yAxes: yAxesData,
        },
        legend: {
          display: yAxes[0].label !== undefined,
          labels: { boxWidth: 1, filter: function (legendItem, chartData) { return chartData.datasets[legendItem.datasetIndex].data.length > 0 } },
        },
        animation: {
          duration: animation, // general animation time
        },
        elements: {
          point: {
            radius: point_radius,
          },
        },
      },
    };
  };
}

export default LineGraph;
