import React, { Component } from "react";

class WaveGideHelmholtzAnimationInformation extends Component {
  render() {
    let t = this.props.t;
    let { animation, signal, dt } = this.props.properties;
    let current_time = Math.round(10 * (t / animation.animation_rate) * 1000) / 10;

    return (
      <>
        <div className="slider-block">
          <button onClick={this.props.handleRestart}>restart</button>
          <button onClick={this.props.handlePause}>{dt === 0.0 ? "play" : "pause"}</button>
        </div>
        current time: {Number.isInteger(current_time) ? current_time + ".0" : current_time}
        {" ms"}
        <table style={{ fontSize: "80%", margin: "auto" }}>
          <tbody>
            <tr>
              <td>animation slowed down {animation.animation_rate}x</td>
            </tr>

            <tr>
              <td>displacement multplied by {signal.displacement_zoom}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}

export default WaveGideHelmholtzAnimationInformation;
