import React, { useState, useEffect } from "react";

export default function WaveGuideShowWaves({ physics }) {
  const [showWaves_selected, setShowWaves] = useState(0);

  useEffect(() => {
    if (showWaves_selected === 0) {
      physics.showWaves = { incident: 1, reflected: 1 };
    } else if (showWaves_selected === 1) {
      physics.showWaves = { incident: 1, reflected: 0 };
    } else if (showWaves_selected === 2) {
      physics.showWaves = { incident: 0, reflected: 1 };
    }
  }, [showWaves_selected]);

  return (
    <div style={{ display: "inline-block" }}>
      <select
        style={{ marginTop: "5px", marginBottom: "15px" }}
        value={showWaves_selected}
        onChange={(e) => setShowWaves(Number(e.currentTarget.value))}
      >
        <option value="0">all waves</option>
        <option value="1">without reflected wave</option>
        <option value="2">without incident wave</option>
      </select>
    </div>
  );
}
