import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import MicrophoneDirectivityGraph from "./MicrophoneDirectivity.graph";
import MicrophoneDirectivityAudio from "./MicrophoneDirectivity.audio";
import { polarValue } from "./MicrophoneDirectivity.helper";

class MicrophoneDirectivity extends Component {
  // class state
  state = {
    beta: 0.5,
    order: 1,
    sourceAngle: 0,
  };

  // slider parameters
  sliders = [
    {
      title: "pattern coefficient",
      name: "beta",
      unit: "",
      min: 0,
      max: 1,
      step: 0.01,
      edit: false,
    },
  ];

  range_options = [
    { title: "order 1", value: 1 },
    { title: "order 2", value: 2 },
  ];

  dragFnc = (angle) => {
    this.setState({ sourceAngle: angle });
  };

  // handle for sliders
  handleChange = (name, value) => {
    this.setState({ [name]: Number(value) });
  };

  // handle for selection of ranges
  handleChangeRange = (event) => {
    this.setState({
      order: Number(event.target.value),
    });
  };

  render() {
    let { beta, order, sourceAngle } = this.state;
    let gain = polarValue(sourceAngle, beta, order);
    return (
      <div>
        <div className="slider-block">
          <select onChange={this.handleChangeRange}>
            {this.range_options.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.title}
                </option>
              );
            })}
          </select>
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            {this.sliders.map((slider, index) => {
              return (
                <SliderEdit
                  key={index}
                  onChange={this.handleChange}
                  params={slider}
                  val={this.state[slider.name]}
                />
              );
            })}
            <MicrophoneDirectivityAudio gain={gain} />
          </div>
        </div>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        <p style={{ fontSize: "60%" }}>
          The orange point represents the playing music source.
          <br /> You can move with the source around the microphone directivity function while
          playing sound.
        </p>
        <div className="one-graph">
          <MicrophoneDirectivityGraph
            beta={beta}
            order={order}
            sourceAngle={sourceAngle}
            dragFnc={this.dragFnc}
          />
        </div>
      </div>
    );
  }
}

export default MicrophoneDirectivity;
