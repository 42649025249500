import React, { Component } from "react";
import LineGraphDrag from "../../../UI/ChartJS/LineGraphDrag";
import { prepareDataImpedanceFreq } from "../../helpers/WaveGuideHelmholtz.helper.js";
import { COLORS } from "../../../../helpers/helpers";

class WaveGuideHelmholtzGraphImpedanceFreq extends Component {
  updateDraggedFrequencyFromImpedanceFreq = (e, datasetIndex, index, value) => {
    this.updateFrequency(null, value.x);
  };

  updateFrequency = (name, value) => {
    let { properties, updateCoefficients } = this.props;
    properties.signal.freq.value = Number(value);
    properties.signal.updateParams(properties.physics.c0);
    updateCoefficients(properties);
  };

  render() {
    let { signal, physics } = this.props.properties;

    let { Z_abs, freqPoint, freqLine } = prepareDataImpedanceFreq(signal, physics);

    let signalData = {
      title: "Acoustic Impedance Z(f)",
      dragX: true,
      dragY: false,
      onDragUpdate: true,
      xAxes: {
        xLabel: "f [Hz]",
        type: "linear",
      },
      yAxes: [
        {
          data: [freqPoint, freqLine, Z_abs],
          yLabel: "|Z| [kg/m^2/s]",
          min: -1,
          max: 5,
          scale: "log",
          dragData: [true, false, false],
          defaultDragY: [1.0, 0, 0],
          borderColor: [COLORS[1], COLORS[1], COLORS[0]],
          backgroundColor: [COLORS[1], COLORS[1], COLORS[0]],
          lineWidth: [0, 1, 3],
          pointRadius: [5, 0, 0],
        },
      ],
      animation: 0,
    };

    return (
      <LineGraphDrag
        data={signalData}
        func={this.updateDraggedFrequencyFromImpedanceFreq}
        graph_size_ratio={0.5}
      />
    );
  }
}

export default WaveGuideHelmholtzGraphImpedanceFreq;
