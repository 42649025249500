import React, { Component } from "react";
import WaveGuideGraphSpace from "./components/graphs/WaveGuide.graphSpace.jsx";
import WaveGuideGraphTime from "./components/graphs/WaveGuide.graphTime.jsx";
import WaveGuideGraphImpedance from "./components/graphs/WaveGuide.graphImpedance.jsx";
import WaveGuideGraphImpedanceFreq from "./components/graphs/WaveGuide.graphImpedanceFreq.jsx";
import WaveGuideSignals from "./components/signals/WaveGuide.signals.jsx";
import WaveGuideAmplitude from "./components/settings/WaveGuide.amplitude.jsx";
import WaveGuideFCW from "./components/settings/WaveGuide.fcw.jsx";
import WaveGuideOpenClosed from "./components/settings/WaveGuide.openclosed.jsx";
import WaveGuideReflection from "./components/settings/WaveGuide.reflection.jsx";
import WaveGuideLeftRight from "./components/settings/WaveGuide.leftright.jsx";
import WaveGuideMedium from "./components/settings/WaveGuide.medium.jsx";
import WaveGuideShowWaves from "./components/settings/WaveGuide.showWaves.jsx";
import WaveGuideRadius from "./components/settings/WaveGuide.radius.jsx";
import WaveGuideFrequency from "./components/settings/WaveGuide.frequency.jsx";
import WaveGuideAnimation from "./components/animation/WaveGuide.animation.jsx";
import WaveGuideAnimationInformation from "./components/animation/WaveGuide.animation.information.jsx";

import { setDensityPoints } from "./helpers/WaveGuide.helper.js";
import { signalSelect } from "./helpers/WaveGuide.signals.js";
import HideDiv from "../UI/HideDiv.jsx";
import "./WaveGuide.css";

const dt_default = 0.1;

class WaveGuide extends Component {
  constructor(props) {
    super();
    this.state = {
      // the only parameter that make component update is time
      // other parameters are state independent
      t: 0, // time
    };
    this.properties = {
      dt: dt_default, // time increment
      physics: props.physics,
      signals: props.signals,
      signal: signalSelect(props.signals[0], props.physics),
      animation: {
        animation_rate: props.animation_rate || 3000,
        num_of_particles: { groups: 250, particles_per_group: 20 }, // number of particles
        colormap: { symmetric: true, nshades: 255, ...props.colormap },
        velocity_arrows: props.velocity_arrows ?? {
          can_be_modified: true,
          show: false,
          size_ratio: 5000,
        },
        pressure_colors: props.pressure_colors ?? { can_be_modified: true, show: true },
        canvas_ratio: 0.3, // ratio of canvas height/width
        color_region: props.color_region ?? { xmin: 0, xmax: 1, box: false },
        arrow_region: props.arrow_region ?? { xmin: 0, xmax: 1, box: false },
      },
      microphone: props.microphone ?? { show: false, x: 0.5 },
      graphs: props.graphs,
      flag_recalculate: true,
      flag_update_reflection: false,
      text: props.text ?? [],
    };
    this.modifiers = {
      signal_selected: 0,
      //   space_graph_show: true,
      //   fkc_show: true,
    };

    this.updateCoefficients();
  }

  componentDidMount() {
    // update animation every dt [ms]
    this.interval = setInterval(() => {
      let { t } = this.state;
      this.setState({ t: t + this.properties.dt }); // increment time by dt [s]

      // if properties need to be recalculated
      if (this.properties.flag_recalculate) {
        this.updateProperties();
        this.properties.flag_recalculate = false;
      }

      // animation restart for non-harmonic signals
      let time_repeat = this.properties.signal.time_repeat;
      if (time_repeat > 0) {
        // restart automatically animation
        if (t > (time_repeat * this.properties.animation.animation_rate) / 1000) {
          this.setState({ t: 0.0 });
        }
      }
    }, Math.round(1000 * dt_default)); // update every dt [ms]
  }

  // clean after finishing
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateCoefficients() {
    let { signal, physics, animation } = this.properties;
    animation.points_density = setDensityPoints(physics.rho); // 5 to 20
    signal.setWavenumer(physics.c0);
    signal.setLambda(physics.c0);
    if (!physics.reflection?.edit && !physics.openclosed) {
      // update coefficients of reflection from physics
      signal.setReflectionPhysics(physics);
    }
    this.updateAmplitudes(this.properties);
  }

  /* Amplitudes A and B can be constant or variable.
     If it is a variable it need to be updated when needed
     as a function on other parameters.  */
  updateAmplitudes(properties) {
    let { signal, physics } = properties;
    // update amplitudes of signals if dependent on parameters
    if (signal.A.fnc !== undefined) {
      signal.A.fnc(signal, physics);
    }
    if (signal.B.fnc !== undefined) {
      signal.B.fnc(signal, physics);
    }
  }

  updateProperties() {
    this.updateCoefficients();

    // update p_max and v_max (maximum values of pressure and velocity)
    if (this.properties.signal.graph_limits.auto) {
      this.properties.signal.setPressureMaxima();
      this.properties.signal.setVelocityMaxima(
        this.properties.physics.c0,
        this.properties.physics.rho
      );
    }
  }

  // handles for Restart and Pause
  handleRestart = (event) => {
    this.properties.dt = dt_default;
    this.setState({ t: 0.0 });
  };
  handlePause = (event) => {
    this.properties.dt = this.properties.dt === 0.0 ? dt_default : 0.0;
  };

  render() {
    let { t } = this.state;
    let { animation, physics, signal, signals, graphs, microphone, flag_update_reflection } =
      this.properties;

    const show_amplitudes_sliders = signal.A.edit || signal.B.edit || signals.length > 1;

    const show_frequency_sliders = signal.freq.can_be_modified_with_c0;
    const show_frequency = signal.freq.can_be_modified;
    const show_reflection_edit = physics.reflection?.edit;
    const show_slider_A = signal.A.edit;
    const show_slider_B = signal.B.edit;
    const show_left_right = signal.edit_left_right;
    const show_medium_choice = physics.medium?.edit;
    const show_radius_sliders = physics.radius?.edit;
    const show_waves_choice = physics.showWaves?.edit;

    const show_graph_space = graphs.space;
    const show_graph_time = microphone.show;
    const show_graph_impedance = graphs.impedance;
    const show_graph_impedance_freq = graphs.impedance_freq;
    const show_open_closed = physics.openclosed;

    return (
      <div>
        {
          /* --------------------------------------------------*/
          /* --- OPEN CLOSED WAVEGUIDE ------------------------*/
          /* --------------------------------------------------*/
          show_open_closed && <WaveGuideOpenClosed properties={this.properties} />
        }

        {
          /* --------------------------------------------------*/
          /* --- SIGNAL SLIDERS (amplitude phase) -------------*/
          /* --------------------------------------------------*/
          show_amplitudes_sliders && (
            <HideDiv text={{ hide: "hide", show: "show amplitude/phase" }}>
              <div className="slider-block">
                {/* Signal select */}
                <WaveGuideSignals properties={this.properties} />

                {
                  /* --- SLIDER Amplitude A ------------------------*/
                  show_slider_A && (
                    <WaveGuideAmplitude properties={this.properties} name="A" title="A" />
                  )
                }

                {
                  /* --- SLIDER Amplitude B ------------------------*/
                  show_slider_B && (
                    <WaveGuideAmplitude properties={this.properties} name="B" title="B" />
                  )
                }

                {
                  /* --- source from LEFT or RIGHT------------------*/
                  show_left_right && <WaveGuideLeftRight properties={this.properties} />
                }
              </div>
            </HideDiv>
          )
        }

        {/* -------------------------------------------------*/
        /* --- SLIDERS (frequency, wavelength, speed) -------*/
        /* --------------------------------------------------*/}
        {show_frequency_sliders && (
          <HideDiv text={{ hide: "hide", show: "show frequency/wavelength/speed" }}>
            <WaveGuideFCW
              c0={physics.c0[0]}
              freq={signal.freq.value}
              properties={this.properties}
            />
          </HideDiv>
        )}

        {/* -------------------------------------------------*/
        /* --- REFLECTION COEFFICIENT -----------------------*/
        /* --------------------------------------------------*/}
        {show_reflection_edit && (
          <HideDiv text={{ hide: "hide", show: "show reflection coefficient" }}>
            <WaveGuideReflection
              properties={this.properties}
              signal={signal}
              physics={physics}
              update_flag={flag_update_reflection}
            />
          </HideDiv>
        )}

        {/* --------------------------------------------------*/
        /* --- FREQUENCY -------------------------------------*/
        /* --------------------------------------------------*/}
        {show_frequency && (
          <HideDiv text={{ hide: "hide", show: "show frequency" }}>
            <WaveGuideFrequency
              properties={this.properties}
              value={signal.freq.value}
              minmax={signal.freq.minmax}
              updateAmplitudes={this.updateAmplitudes}
            />
          </HideDiv>
        )}

        {/* --------------------------------------------------*/
        /* --- WAVEGUIDE HEIGHT ------------------------------*/
        /* --------------------------------------------------*/}
        {show_radius_sliders && (
          <HideDiv text={{ hide: "hide", show: "show waveguide height" }}>
            <WaveGuideRadius
              properties={this.properties}
              value={physics.radius.values[0]}
              updateAmplitudes={this.updateAmplitudes}
            />
          </HideDiv>
        )}

        {/* --------------------------------------------------*/
        /* --- MEDIUM PROPERTIES -----------------------------*/
        /* --------------------------------------------------*/}
        {show_medium_choice && (
          <HideDiv text={{ hide: "hide", show: "show medium selection" }}>
            <WaveGuideMedium idx={0} properties={this.properties} />
            <WaveGuideMedium idx={1} properties={this.properties} />
          </HideDiv>
        )}

        {/* --------------------------------------------------*/
        /* --- ANIMATION information -------------------------*/
        /* --------------------------------------------------*/}
        <HideDiv text={{ hide: "hide", show: "show info" }}>
          <WaveGuideAnimationInformation
            t={t}
            properties={this.properties}
            handleRestart={this.handleRestart}
            handlePause={this.handlePause}
          />
          {show_medium_choice && <WaveGuideShowWaves physics={physics} />}
        </HideDiv>

        {/* --------------------------------------------------*/}
        {/* --- ANIMATION ------------------------------------*/}
        {/* --------------------------------------------------*/}
        <HideDiv text={{ hide: "hide", show: "show animation" }}>
          <WaveGuideAnimation t={t} properties={this.properties} />
        </HideDiv>

        {/* --------------------------------------------------*/}
        {/* --- IMPEDANCE CHART ------------------------------*/}
        {/* --------------------------------------------------*/}
        {show_graph_impedance && (
          <HideDiv text={{ hide: "hide", show: "show impedance graph" }}>
            <div className="one-graph graph">
              <WaveGuideGraphImpedance physics={physics} signal={signal} />
            </div>
          </HideDiv>
        )}

        {/* --------------------------------------------------*/}
        {/* --- IMPEDANCE FREQUENCY CHART --------------------*/}
        {/* --------------------------------------------------*/}
        {show_graph_impedance_freq && (
          <HideDiv text={{ hide: "hide", show: "show impedance graph" }}>
            <div className="one-graph graph">
              <WaveGuideGraphImpedanceFreq
                updateAmplitudes={this.updateAmplitudes}
                properties={this.properties}
              />
            </div>
          </HideDiv>
        )}

        {/* --------------------------------------------------*/}
        {/* --- SPACE CHART ----------------------------------*/}
        {/* --------------------------------------------------*/}
        {show_graph_space && (
          <HideDiv text={{ hide: "hide", show: "show space graph" }}>
            <div className="one-graph graph">
              <WaveGuideGraphSpace
                properties={this.properties}
                x={this.properties.microphone.x}
                t={t / animation.animation_rate}
                physics={physics}
                signal={signal}
              />
            </div>
          </HideDiv>
        )}

        {/* --------------------------------------------------*/}
        {/* ---------------    TIME CHART   ------------------*/}
        {/* --------------------------------------------------*/}
        {show_graph_time && (
          <HideDiv text={{ hide: "hide", show: "show time graph" }}>
            <div className="one-graph graph">
              <WaveGuideGraphTime
                x={this.properties.microphone.x}
                t={t / animation.animation_rate}
                physics={physics}
                signal={signal}
                showTitle={true}
              />
            </div>
          </HideDiv>
        )}
      </div>
    );
  }
}

export default WaveGuide;
