import { scale } from "../../helpers/helpers";

// ------ COLORMAP ------------------------------------------------
class Colormap {
  static nshades = 255;
  static colormaps = require("colormap");
  static colormap = this.colormaps({
    colormap: "jet", // "RdBu", "picnic"
    nshades: this.nshades,
    format: "rgbaString",
    alpha: 1,
  });
}

export class ColorPlot {
  constructor() {
    // colormap
    let colormaps = require("colormap");
    this.colormap = colormaps({
      colormap: "jet", // "RdBu", "picnic"
      nshades: 255,
      format: "rgbaString",
      alpha: 1,
    });
  }

  // -----------------------------------------------------
  // draw all the content
  draw = (ctx, graph) => {
    let x_rect_size = Math.round(ctx.canvas.width / graph.x_len);
    let y_rect_size = Math.round(ctx.canvas.height / graph.y_len);
    // draw pixel by pixel
    for (let i = 0; i < graph.x_len; i++) {
      for (let j = 0; j < graph.y_len; j++) {
        ctx.fillStyle =
          Colormap.colormap[
            Math.round(
              scale(
                graph.arr[i][j],
                graph.z_min_max[0],
                graph.z_min_max[1],
                0,
                Colormap.nshades
              )
            )
          ];
        ctx.fillRect(
          i * x_rect_size,
          j * y_rect_size,
          x_rect_size,
          y_rect_size
        );
      }
    }
  };
}
