// prepare data:
// - inputSignal ... sine wave (one period) [in chartjs format]

export function prepareData(dataArray, PlotData) {
  const N = dataArray.length;
  let inputSignal = Array(N);

  let { DC, amplitude } = PlotData

  for (let i = 0; i < N; i++) {
    inputSignal[i] = { x: (1000 * i) / 44100, y: amplitude * dataArray[i] + DC };
  }
  return inputSignal;
}
