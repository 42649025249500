import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import TSLoudspeakergraph from "./TSLoudspeaker.graph";
import {
  mode_selector,
  mode_options,
  sliders_datasheet,
  sliders_fundamental,
  fundamental_to_datasheet,
  datasheet_to_fundamental,
} from "./TSLoudspeaker.helper";

// -------------------------
// Thiele Small (TS) parameters of a Loudspeaker
// - Simulation of a Loudspeaker based on the TS parameters
//   Shows two graphs:
//   - Impedance (amplitue and phase)
//   - Sound Pressure Level at 1 meter
// -------------------------

class TSLoudspeaker extends Component {
  // class state includes TS parameters
  state = {
    selected_mode: mode_options[0],
    Bl: 4.9, // force factor [Tm]
    Re: 3.2, // resistance [ohm]
    Le: 0.23, // inductance [mH]
    Mms: 14.2, // mass [g]
    Rms: 1.4, // mechanical resisatnce [Ns/m]
    Kms: 1920, // stiffness [N/m]
    Fs: 1, // resonance frequency [Hz]
    Qes: 1, // Electrical Q of the driver at Fs [-]
    Qms: 1, // Mechanical Q of the driver at Fs
    Vas: 1, // Equivalent Compliance Volume [m^3]
    Sd: 250, // Projected area of the driver diaphragm [m^2]
  };

  updateTSParameters = (mode) => {
    let { Re, Bl, Sd } = this.state;
    if (mode === mode_selector.FUNDAMENTAL) {
      let { Mms, Rms, Kms } = this.state;
      let { Fs, Qes, Qms, Vas } = fundamental_to_datasheet(Re, Bl, Mms / 1000, Rms, Kms, Sd / 1e4);
      this.setState({ Fs: Fs, Qes: Qes, Qms: Qms, Vas: Vas * 1000 });
    } else {
      // mode_selector.DATASHEET
      let { Fs, Qes, Qms, Vas } = this.state;
      let { Bl, Mms, Rms, Kms } = datasheet_to_fundamental(Re, Fs, Qes, Qms, Vas / 1000, Sd / 1e4);
      this.setState({ Bl: Bl, Mms: 1000 * Mms, Rms: Rms, Kms: Kms });
    }
  };

  componentDidMount() {
    this.updateTSParameters(this.state.selected_mode.value);
  }

  // handle sliders
  handleChange = (name, value) => {
    // check if user has entered a numeric value
    if (!isNaN(value)) {
      this.setState({ [name]: Number(value) });
    }
    this.updateTSParameters(this.state.selected_mode.value);
  };

  // handle for selection of ranges
  handleChangeMode = (event) => {
    // *** call function to calculate either {Kms, Rms, Mms} or {Qes, Qms, fs}
    //     and set new states of these values
    this.updateTSParameters(Number(event.target.value));
    this.setState({
      selected_mode: mode_options.find((obj) => obj.value === Number(event.target.value)),
    });
  };

  render() {
    let { selected_mode, Bl, Re, Le, Mms, Rms, Kms, Sd } = this.state;

    let show_fundamental_sliders = selected_mode.value === mode_selector.FUNDAMENTAL;
    let show_datasheet_sliders = selected_mode.value === mode_selector.DATASHEET;

    return (
      <div>
        <div className="slider-block">
          {/* --------------------------------------------------*/}
          {/* predefined values */}
          {/* --------------------------------------------------*/}
          <select onChange={this.handleChangeMode}>
            {mode_options.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.title}
                </option>
              );
            })}
          </select>
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ display: show_fundamental_sliders ? "block" : "none" }}>
            {sliders_fundamental.map((slider, index) => {
              return (
                <SliderEdit
                  key={index}
                  onChange={this.handleChange}
                  params={slider}
                  val={this.state[slider.name]}
                />
              );
            })}
          </div>
          <div style={{ display: show_datasheet_sliders ? "block" : "none" }}>
            {sliders_datasheet.map((slider, index) => {
              return (
                <SliderEdit
                  key={index}
                  onChange={this.handleChange}
                  params={slider}
                  val={this.state[slider.name]}
                />
              );
            })}
          </div>
        </div>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        {/* */}
        {/* --------------------------------------------------*/}
        {/* graph (chartjs)*/}
        {/* --------------------------------------------------*/}
        <TSLoudspeakergraph
          Bl={Bl} // force factor
          Re={Re} // resistance
          Le={Le * 1e-3} // inducatnce, pass value from [mH] to [H]
          Mms={Mms * 1e-3} // mass, pass value from [g] to [kg]
          Rms={Rms} // mechanical resistance
          Kms={Kms} // stiffness
          Sd={Sd * 1e-4} // diaphragm surface, pass value from [cm^2] to [m^2]
        />
      </div>
    );
  }
}

export default TSLoudspeaker;
