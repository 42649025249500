import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import PassiveRadiatorGraph from "./PassiveRadiator.graph";
import PassiveRadiatorSVG from "./PassiveRadiator.svg";
import { getLength, getVolume } from "./PassiveRadiator.helper";
import { sliders } from "./PassiveRadiator.helper";
import { round } from "../../helpers/math.helper";

// -------------------------
// Loudspeaker system with a passive radiator
// - Loudspeaker with given TS parameters has a closed-box in which is a passive radiator with
//        fmult ... fb = fmult * fc
//        Rmult ... Rms_passive = Rmult * Rms
// -------------------------

class PassiveRadiator extends Component {
  // class state
  state = {
    Rmult: 1, // multiplication factor of volume
    fmult: 0.66, // multiplication factor of mechanical resistance
  };

  // handle for sliders
  handleChange = (name, value) => {
    this.setState({ [name]: Number(value) });
  };

  render() {
    let { Rmult, fmult } = this.state;

    return (
      <div>
        <div className="slider-block">
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            {sliders.map((slider, index) => {
              return (
                <SliderEdit
                  key={index}
                  onChange={this.handleChange}
                  params={slider}
                  val={this.state[slider.name]}
                />
              );
            })}
          </div>
        </div>
        {/* --------------------------------------------------*/}
        {/* ----------------  SVG DRAWING  -------------------*/}
        {/* --------------------------------------------------*/}
        <div style={{ margin: "auto", width: "50%" }}>
          <PassiveRadiatorSVG />
        </div>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        <div>
          <PassiveRadiatorGraph Rmult={Rmult} fmult={fmult} />
        </div>
      </div>
    );
  }
}
export default PassiveRadiator;
