import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import PistonDirectivityGraph from "./PistonDirectivity.graph";
//import { Fc, Q } from "./PistonDirectivity.helper";

// -------------------------
// PistonDirectivity
// - Directivity of a piston
//   radius (of the piston) [cm]
//   frequency [Hz]
//   dB ... dynamics of the graph
// -------------------------

class PistonDirectivity extends Component {
  // class state
  state = {
    radius: 5.0, // radius [cm]
    frequency: 5000.0, // frequency [Hz]
    dB: 40, // dynamics
  };

  // slider parameters
  sliders = [
    {
      name: "radius",
      unit: "cm",
      min: 0.1,
      max: 10.0,
      step: 0.1,
      edit: false,
      conversion: 0.3937007874,
      conversion_units: "inches",
    },
    {
      name: "frequency",
      unit: "Hz",
      min: 20,
      max: 10e3,
      step: 10,
      edit: false,
    },
    {
      name: "dB",
      title: "figure dynamics",
      unit: "dB",
      min: 10,
      max: 100,
      step: 10,
      edit: false,
    },
  ];

  // handle sliders
  handleChange = (name, value) => {
    // check if user has entered a numeric value
    if (!isNaN(value)) {
      this.setState({ [name]: parseFloat(value) });
    }
  };

  render() {
    let { radius, frequency, dB } = this.state;

    return (
      <div>
        <div className="slider-block">
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            {this.sliders.map((slider, index) => {
              return (
                <SliderEdit
                  key={index}
                  onChange={this.handleChange}
                  params={slider}
                  val={this.state[slider.name]}
                />
              );
            })}
          </div>
        </div>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        {/* */}
        {/* --------------------------------------------------*/}
        {/* radio buttons to choose between linear and logarithmic Yaxis of chart.js*/}
        {/* --------------------------------------------------*/}
        <div style={{ width: "100%", maxWidth: "500px", margin: "auto" }}>
          {/* --------------------------------------------------*/}
          {/* graph (chartjs)*/}
          {/* --------------------------------------------------*/}
          <PistonDirectivityGraph
            radius={radius * 1e-2} //   radius (of the piston), pass value from [cm] to [m]
            frequency={frequency} //   frequency [Hz]
            dB={dB} //   dB ... dynamics of the graph
          />
        </div>
      </div>
    );
  }
}
export default PistonDirectivity;
