import React, { Component } from "react";
import FIRGraph from "./FIR.graph";

// -------------------------
//   - comment
// -------------------------

class FIR extends Component {
  render() {
    return (
      <div>
        <p style={{ fontSize: "80%" }}>
          Move with samples of Impulse Response to see the effect on Frequency
          Response Function.
        </p>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        {/* */}
        {/* --------------------------------------------------*/}
        {/* graph (chartjs)*/}
        {/* --------------------------------------------------*/}
        <FIRGraph Npts={16} />
      </div>
    );
  }
}

export default FIR;
