import React, { Component } from "react";
import WaveGuideHelmholtzGraphImpedanceFreq from "./components/graphs/WaveGuideHelmholtz.graphImpedanceFreq.jsx";
import WaveGuideHelmholtzFrequency from "./components/frequency/WaveGuideHelmholtz.frequency.jsx";
import WaveGuideHelmholtzAnimation from "./components/animation/WaveGuideHelmholtz.animation.jsx";
import WaveGuideHelmholtzAnimationInformation from "./components/animation/WaveGuideHelmholtz.animation.information.jsx";
import { setDensityPoints, setAmplitude, surface } from "./helpers/WaveGuideHelmholtz.helper.js";
import { HarmonicSignal } from "./helpers/WaveGuideHelmholtz.signals.js";
import { COLORS } from "../../helpers/helpers";
import HideDiv from "../UI/HideDiv.jsx";
import "./WaveGuideHelmholtz.css";

const dt_default = 0.1;
const L = 0.2;

const props = {
  colormap: "RdBu",
  signal: {
    freq: { value: 500, can_be_modified: true, minmax: [0, 1000] },
    graph_limits: { pmax: 1, vmax: 0.006, auto: false },
    standing_waves: false,
  },
  physics: {
    dimensions: {
      L: L, // cavity length
      l: L / 4, // neck lenght
      H: L / 2, // cavity height
      h: L / 10, // neck height
      S: surface(L / 4), // cavity surface
      s: surface(L / 20), // length surface
    },
    radius: { values: [1.0 / 8, 1.0 / 8] },
    rho: 1.204,
    c0: 343,
    medium: { edit: false, title: "medium: air" },
  },
  pressure_colors: { can_be_modified: true, show: true },
  velocity_arrows: { can_be_modified: true, show: false, size_ratio: 6000, color: COLORS[1] },
};

class WaveGuideHelmholtz extends Component {
  constructor() {
    super();
    this.state = {
      // the only parameter that make component update is time
      // other parameters are state independent
      t: 0, // time
    };
    this.properties = {
      dt: dt_default, // time increment
      physics: props.physics,
      signals: props.signals,
      signal: new HarmonicSignal(props.signal, props.physics),
      animation: {
        animation_rate: 3000,
        num_of_particles: { groups: 250, particles_per_group: 20 }, // number of particles
        colormap: { name: props.colormap ?? "RdBu", nshades: 255 },
        velocity_arrows: props.velocity_arrows ?? {
          can_be_modified: true,
          show: false,
          size_ratio: 5000,
        },
        pressure_colors: props.pressure_colors ?? { can_be_modified: true, show: true },
        canvas_ratio: 0.3, // ratio of canvas height/width
        color_region: { xmin: 0, xmax: 1, box: false },
        arrow_region: { xmin: 0, xmax: 1, box: false },
      },
      graphs: props.graphs,
      flag_recalculate: true,
    };

    this.updateCoefficients(this.properties);
  }

  componentDidMount() {
    // update animation every dt [ms]
    this.interval = setInterval(() => {
      let { t } = this.state;
      this.setState({ t: t + this.properties.dt }); // increment time by dt [s]

      // if properties need to be recalculated
      if (this.properties.flag_recalculate) {
        this.updateCoefficients(this.properties);
        this.properties.flag_recalculate = false;
      }

      // animation restart for non-harmonic signals
      let time_repeat = this.properties.signal.time_repeat;
      if (time_repeat > 0) {
        // restart automatically animation
        if (t > (time_repeat * this.properties.animation.animation_rate) / 1000) {
          this.setState({ t: 0.0 });
        }
      }
    }, Math.round(1000 * dt_default)); // update every dt [ms]
  }

  // clean after finishing
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateCoefficients(properties) {
    let { c0, rho, dimensions } = properties.physics;
    properties.signal.pmax(properties.physics);
    setAmplitude(properties.signal, dimensions);
    properties.animation.points_density = setDensityPoints(rho); // 5 to 20
    properties.signal.updateParams(c0);
  }

  // handles for Restart and Pause
  handleRestart = (event) => {
    this.properties.dt = dt_default;
    this.setState({ t: 0.0 });
  };
  handlePause = (event) => {
    this.properties.dt = this.properties.dt === 0.0 ? dt_default : 0.0;
  };

  render() {
    let { t } = this.state;
    let { signal } = this.properties;

    return (
      <div>
        {/* --------------------------------------------------*/
        /* --- FREQUENCY -------------------------------------*/
        /* --------------------------------------------------*/}
        <HideDiv text={{ hide: "hide", show: "show frequency" }}>
          <WaveGuideHelmholtzFrequency
            properties={this.properties}
            value={signal.freq.value}
            minmax={signal.freq.minmax}
            updateCoefficients={this.updateCoefficients}
          />
        </HideDiv>

        {/* --------------------------------------------------*/
        /* --- ANIMATION information -------------------------*/
        /* --------------------------------------------------*/}
        <HideDiv text={{ hide: "hide", show: "show info" }}>
          <WaveGuideHelmholtzAnimationInformation
            t={t}
            properties={this.properties}
            handleRestart={this.handleRestart}
            handlePause={this.handlePause}
          />
        </HideDiv>

        {/* --------------------------------------------------*/}
        {/* --- ANIMATION ------------------------------------*/}
        {/* --------------------------------------------------*/}
        <HideDiv text={{ hide: "hide", show: "show animation" }}>
          <WaveGuideHelmholtzAnimation t={t} properties={this.properties} />
        </HideDiv>

        {/* --------------------------------------------------*/}
        {/* --- IMPEDANCE FREQUENCY CHART --------------------*/}
        {/* --------------------------------------------------*/}
        <HideDiv text={{ hide: "hide", show: "show impedance graph" }}>
          <div className="one-graph graph">
            <WaveGuideHelmholtzGraphImpedanceFreq
              updateCoefficients={this.updateCoefficients}
              properties={this.properties}
            />
          </div>
        </HideDiv>
      </div>
    );
  }
}

export default WaveGuideHelmholtz;
