import { complex } from "mathjs";
import { GRAPH_RATIO } from "./SoundZones.helper";
import { scale, COLORS } from "../../helpers/helpers";

// ------ COLORMAP ------------------------------------------------
class Colormap {
  constructor(obj) {
    this.nshades = 255;

    let colormaps = require("colormap");
    this.colormap = colormaps({
      colormap: "jet", // "RdBu", "picnic"
      nshades: this.nshades,
      format: "rgbaString",
      alpha: 1,
    });

    this.color_gradient = NaN; // will be assigned using update_color_gradient method
  }

  // color gradient for ColorBar
  updateColorGradient = (ctx, graph) => {
    this.color_gradient = ctx.createLinearGradient(0, 20, 0, ctx.canvas.height / 2);
    for (let i = 0; i < this.nshades; i++) {
      this.color_gradient.addColorStop(
        ((i / this.nshades) * this.colormap.length) / (this.nshades - 1),
        this.colormap[this.nshades - i - 1]
      );
    }
  };

  getColor = (val, max_val) => {
    return this.colormap[Math.floor(scale(val, -max_val, max_val, 0, this.nshades))];
  };
}

export class ZonePoints {
  constructor(M, dm, position, color) {
    // Coordinates of the control points
    this.points = [];
    for (let i = -(M - 1) / 2; i <= (M - 1) / 2; i++) {
      for (let j = -(M - 1) / 2; j <= (M - 1) / 2; j++) {
        let x = i * dm + position.x;
        let y = j * dm + position.y;
        this.points.push({ x: x, y: y });
      }
    }
    this.color = color;
  }

  static drawZonePoint(ctx, x, y, color) {
    ctx.beginPath();
    ctx.arc(x, y, 4, 0, 2 * Math.PI);
    ctx.fillStyle = color.bcg;
    ctx.fill();
    ctx.strokeStyle = color.line;
    ctx.stroke();
  }

  draw = (ctx, graph) => {
    let x_rect_size = ctx.canvas.width / graph.x_len;
    let y_rect_size = ctx.canvas.height / graph.y_len;
    this.points.forEach((point) => {
      ZonePoints.drawZonePoint(
        ctx,
        Math.ceil(GRAPH_RATIO * graph.mapX(point.x, x_rect_size) + 1),
        Math.ceil(graph.mapY(point.y, y_rect_size) + 1),
        this.color
      );
    });
  };
}

export class Sources {
  constructor(rs, N, color) {
    this.points = [];

    let dtheta = (2 * Math.PI) / N; // Angular spacing

    let i = 0;
    for (let theta = 0; theta < 2 * Math.PI; theta = theta + dtheta) {
      let x = rs * Math.cos(theta); // Coordinate vector of the sources along the x direction
      let y = rs * Math.sin(theta); // Coordinate vector of the sources along the y direction
      this.points.push({ x: x, y: y, q: complex(0, 0) });
      i++;
    }

    this.color = color;
  }

  static drawSource(ctx, x, y) {
    ctx.beginPath();
    ctx.arc(x, y, 7, 0, 2 * Math.PI);
    ctx.fillStyle = COLORS[1];
    ctx.fill();
    ctx.strokeStyle = "black";
    ctx.stroke();
  }

  draw = (ctx, graph) => {
    let x_rect_size = ctx.canvas.width / graph.x_len;
    let y_rect_size = ctx.canvas.height / graph.y_len;
    this.points.forEach((point) => {
      Sources.drawSource(
        ctx,
        Math.ceil(GRAPH_RATIO * graph.mapX(point.x, x_rect_size) + 1),
        Math.ceil(graph.mapY(point.y, y_rect_size) + 1)
      );
    });
  };
}

// ------ COLORBAR ------------------------------------------------
export class ColorBar {
  constructor() {
    this.width = 20;
    this.colormap = new Colormap();
    this.ticks_padding = 5; // pixels
  }

  getYAxisParameters(ctx, graph) {
    let low = ctx.canvas.height / 2;
    let high = 20;
    return [low, high];
  }

  getYAxisPosition(value, params, graph) {
    return scale(value, graph.z_min_max[0], graph.z_min_max[1], params[0], params[1]);
  }

  draw = (ctx, graph) => {
    let x_position = Math.round(1.05 * GRAPH_RATIO * ctx.canvas.width);
    ctx.fillStyle = this.colormap.color_gradient;
    ctx.beginPath();
    ctx.rect(x_position, 20, this.width, ctx.canvas.height / 2 - 20);
    ctx.strokeStyle = "black";
    ctx.stroke();
    ctx.fill();

    // tick labels in vertical colormap axis
    ctx.font = "12px Helvetica";
    ctx.fillStyle = "rgb(0,0,0)";
    ctx.textAlign = "left";
    ctx.textBaseline = "middle";

    let params = this.getYAxisParameters(ctx, graph);
    for (let value = graph.z_min_max[0]; value <= graph.z_min_max[1]; value = value + 5) {
      ctx.fillText(
        value + (value === graph.z_min_max[1] ? " dB SPL" : ""),
        x_position + this.width + 10,
        this.getYAxisPosition(value, params, graph)
      );
    }
  };
}

// ------ LEGEND ------------------------------------------------
export class Legend {
  constructor() {
    this.width = 30;
  }

  draw = (ctx, graph) => {
    let x_position = Math.round(1.05 * GRAPH_RATIO * ctx.canvas.width);
    let y_position = ctx.canvas.height - 100;
    Sources.drawSource(ctx, x_position, y_position);
    ctx.fillStyle = "rgb(0,0,0)";
    ctx.fillText("speaker", x_position + 20, y_position);

    y_position = ctx.canvas.height - 70;
    ZonePoints.drawZonePoint(ctx, x_position, y_position, { line: "black", bcg: "white" });
    ctx.fillStyle = "rgb(0,0,0)";
    ctx.fillText("dark-zone microphone", x_position + 20, y_position);

    y_position = ctx.canvas.height - 40;
    ZonePoints.drawZonePoint(ctx, x_position, y_position, { line: "white", bcg: "black" });
    ctx.fillStyle = "rgb(0,0,0)";
    ctx.fillText("bright-zone microphone", x_position + 20, y_position);
  };
}

export class ColorPlot {
  constructor() {
    this.colormap = new Colormap();
  }

  // -----------------------------------------------------
  // draw all the content
  draw = (ctx, graph) => {
    let x_rect_size = ctx.canvas.width / graph.x_len;
    let y_rect_size = ctx.canvas.height / graph.y_len;
    // draw pixel by pixel
    for (let i = 0; i < graph.x_len; i++) {
      for (let j = 0; j < graph.y_len; j++) {
        // draw only inside the circle (outside circle is NaN)
        if (!isNaN(graph.arr[i][j])) {
          ctx.fillStyle =
            this.colormap.colormap[
              Math.round(
                scale(
                  graph.arr[i][j],
                  graph.z_min_max[0],
                  graph.z_min_max[1],
                  0,
                  this.colormap.nshades
                )
              )
            ];
          ctx.fillRect(
            Math.floor(i * x_rect_size),
            Math.floor(j * y_rect_size),
            Math.ceil(x_rect_size),
            Math.ceil(y_rect_size)
          );
        }
      }
    }
  };
}
