import { complex, abs, arg, add, multiply, divide, pi } from "mathjs";

// slider for each parameter
export const sliders = [
  {
    name: "Rl",
    unit: "Ohm",
    min: 10,
    max: 10000,
    step: 1,
    edit: false,
  },
  {
    name: "Re",
    unit: "Ohm",
    min: 10,
    max: 1000,
    step: 1,
    edit: false,
  },
  {
    name: "Le",
    unit: "mH",
    min: 0,
    max: 1,
    step: 0.01,
    edit: false,
  },
  {
    name: "Bl",
    unit: "Tm",
    min: 0.01,
    max: 50.0,
    step: 0.01,
    edit: false,
  },
  {
    name: "Mm",
    unit: "g",
    min: 0.1,
    max: 50,
    step: 0.1,
    edit: false,
  },
  {
    name: "Rm",
    unit: "Ns/m",
    min: 0.01,
    max: 5,
    step: 0.01,
    edit: false,
  },
  {
    name: "Cm",
    unit: "mm/N",
    min: 1,
    max: 100,
    step: 0.1,
    edit: false,
  },
];

// calculate resonance frequency Fc
export function Fc(Mm, Cm) {
  return 1 / (2 * Math.PI * Math.sqrt(Mm * Cm));
}

// calculate quality factor Q
export function Q(Rl, Re, Bl, Rm, Mm, Cm) {
  let omega = 1 / Math.sqrt(Mm * Cm);
  return (omega * Mm) / (Rm + Math.pow(Bl, 2) / (Re + Rl));
}

// calculate impedance of a RLC circuit
export function sensitivity(Rl, Re, Le, Bl, Rm, Mm, Cm, f_axis, LinLog) {
  // prepare arrays for absVal and phase

  let absVal = [];
  let phase = [];
  for (let i = 0; i < f_axis.length; i++) {
    absVal[i] = { x: f_axis[i], y: 0 };
    phase[i] = { x: f_axis[i], y: 0 };
  }

  for (let i = 0; i < f_axis.length; i++) {
    // frequency axis
    let f = f_axis[i];
    let omega = 2 * pi * f;

    let Ze = complex(Re + Rl, omega * Le);
    let Zm = complex(Rm, omega * Mm - 1 / (Cm * omega));
    let Sg = divide(
      complex(0, -omega * Mm * Bl * Rl),
      add(Math.pow(Bl, 2), multiply(Ze, Zm))
    );

    // attribute the abs value to the chart.js y-axis
    if (LinLog === "linear") {
      absVal[i].y = abs(Sg);
    } else {
      absVal[i].y = 20 * Math.log10(abs(Sg));
    }
    // attribute the phase to the chart.js y-axis
    phase[i].y = (180 / pi) * arg(Sg);
  }

  return { absVal, phase };
}
