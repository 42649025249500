import { round } from "../../../helpers/math.helper";

const PI = Math.PI;
const TWO_PI = 2 * PI;

export class HarmonicSignal {
  constructor(signalParams, physicsParams) {
    Object.assign(this, signalParams);

    this.normalize = 1; // normalize the output
    this.displacement_zoom = 10;
    this.graph_limits = signalParams.graph_limits ?? { pmax: 1, vmax: 1, auto: true };

    this.updateParams(physicsParams.c0);
  }

  updateParams(c0) {
    this.omega = TWO_PI * this.freq.value;
    this.k = this.omega / c0;
  }

  pmax(physics) {
    let { L, l, S, s } = physics.dimensions;
    let { A, k } = this;
    let pmax = Math.max(
      Math.abs(A) * Math.abs(Math.cos(k * L)),
      Math.abs(A) *
        Math.abs(Math.cos(k * L) * Math.cos(k * l) - (S / s) * Math.sin(k * L) * Math.sin(k * l))
    );
    this.graph_limits.pmax = round(pmax, 2);
  }
}
