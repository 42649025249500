import React, { Component } from "react";
import BlockedImpedanceGraph from "./BlockedImpedance.graph";
import SliderEdit from "../UI/SliderEdit";
import ProgressBar from "../UI/ProgressBar";
import ImageViewer from "../UI/ImageViewer";
import { COLORS, pad } from "../../helpers/helpers";
import "./BlockedImpedance.css";

let title_text =
  "Select a blocked position of the coil and start the measurement of the blocked impedance (resistance and inductance). You can add several measurements to the same graph.";
const TextButton = ["Start Measurement", "", "Do another measurement"];

class BlockedImpedance extends Component {
  constructor(props) {
    super(props);
    this.images = [new Array(11)];
    for (let i = 0; i < 11; i++) {
      this.images[0][i] = "/img_loudspeaker/overhung_" + pad(24 - (i + 1) * 2, 2) + ".jpg";
    }

    // class state
    this.state = {
      page: 0, // step
      z: 0, // mass [g]
      progress: 0,
      z_values : [NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN],
      idx : 0
    };
  }

  // slider parameters
  sliders = [
    {
      title: "position",
      name: "z",
      unit: "mm",
      min: -5,
      max: 5,
      step: 1,
    },
  ];

  // handle for sliders
  handleChange = (name, value) => {
    if (!isNaN(value)) {
      this.setState({ [name]: value });
    }
  };

  // handle for button cliks
  buttonClickMeasurement = (event) => {
    if (Number(this.state.page) === 2 && this.state.idx >= this.state.z_values.length) {
        alert('Number of measurements exceeds the limit. Please remove some measurement data before.')
    } else {

        // move to another page
        let new_page = (Number(this.state.page) + 1) % 3;
        this.setState({ page: new_page });

        // if new measurement (going from page 0 to page 1)
        if (new_page === 1) {
            
            // add the measurement value to array
            let new_z_values = [ ... this.state.z_values]
            new_z_values[this.state.idx] = Number(this.state.z);
            this.setState({ progress: 0, idx: this.state.idx+1, z_values: new_z_values });

            // show the progress bar
            this.interval = setInterval(
            function () {
                if (this.state.progress >= 100) {
                // hide the progress bar
                this.setState({ page: 2 });
                clearInterval(this.interval);
                }
                this.setState({ progress: this.state.progress + 4 });
            }.bind(this),
            100
            );
        }
    }
  };

  buttonClickRemove = (event) => {
      // remove the last measurement
    let new_z_values = [ ... this.state.z_values]
    new_z_values[this.state.idx-1] = NaN;  
      this.setState({idx: this.state.idx-1, z_values: new_z_values})
  }

  render() {
    let { z, page, progress, z_values, idx } = this.state;

    // if deleted all the measured data show the first page (measurement)
    if (idx === 0) page = 0;

    let activeImageIndex = Number(z) + 5;

    let display_page_0 = page === 0 ? "block" : "none";
    let display_page_1 = page === 1 ? "block" : "none";
    let display_page_2 = page === 2 ? "block" : "none";


    return (
      <div>
        <div style={{ border: "solid 1px" }}>
          <div className="title-info-blocked-impedance" style={{ background: COLORS[2] }}>
            <p style={{ width: "50%", margin: "auto" }}>{title_text}</p>
          </div>
          <div style={{ display: display_page_0 }}>
            {/* --------------------------------------------------*/}
            {/* sliders */}
            {/* --------------------------------------------------*/}
            <div className="slider-block-blocked-impedance">
              <SliderEdit
                onChange={this.handleChange}
                params={this.sliders[0]}
                val={this.state[this.sliders[0].name]}
              />
            </div>
            <div className="image-viewer-blocked-impedance">
              <ImageViewer
                images={this.images}
                activeImageGroup={0}
                activeImageIndex={activeImageIndex}
                width="100%"
                alt="loudspeaker"
              />
            </div>
          </div>
          <div style={{ margin: "15px", display: display_page_2 }}>
            <BlockedImpedanceGraph z_values={z_values} page={page} />
          </div>
          <div style={{ margin: "15px", display: page === 1 ? "none" : "block" }}>
            <button onClick={this.buttonClickMeasurement}>{TextButton[page]}</button>
          </div>
          <div style={{ margin: "15px", display: page === 2 ? "block" : "none" }}>
            <button onClick={this.buttonClickRemove}>Remove last measurement data</button>
          </div>
          <div style={{ width: "50%", margin: "auto", display: display_page_1 }}>
            <ProgressBar bgcolor={COLORS[0]} completed={progress} />
          </div>
        </div>
      </div>
    );
  }
}
export default BlockedImpedance;
