import { AirParameters } from "../../helpers/helpers";

// calculate directivity in [dB]
export function directivity(theta0, d, frequency, n_of_transducers, dBLimit) {
  // data will contain array of {angle, dB}
  const N = 360;
  let data = Array(N + 1);
  // set last point to NaN so that the radar graph is not closed (first and last point not connected)
  data[N] = NaN;

  // k .. wave length
  let k = (2 * Math.PI * frequency) / AirParameters.c0;

  for (let i = 0; i < N; i++) {
    // theta is the angle (from -PI/2 to PI/2 in N steps)
    let theta = Math.PI * (i / N) - Math.PI / 2;

    // k*a*sin(theta)
    let k_d_sin = ((k * d) / 2) * (Math.sin(theta) - Math.sin(theta0));

    let h = Math.sin(n_of_transducers * k_d_sin) / (n_of_transducers * Math.sin(k_d_sin));
    let dB = 20 * Math.log10(Math.abs(h));

    // limiter to avoid values outside graph
    dB = dB < dBLimit ? dBLimit : dB;

    data[i] = {
      angle: theta,
      dB: k_d_sin === 0 ? 0 : dB,
    };
  }

  return data;
}

export function microphonePoints(N, d, dBmax) {
  // data will contain array of {angle, dB}
  let data = Array(N);

  for (let i = 0; i < N; i++) {
    data[i] = {
      angle: Math.PI / 2,
      dB: (-i + (N - 1) / 2) * ((d / 0.6) * dBmax) - dBmax,
    };
  }

  return data;
}

export function sourcePoints(N, d, dBmax) {
  // data will contain array of {angle, dB}
  let data = Array(N);

  for (let i = 0; i < N; i++) {
    data[i] = {
      angle: Math.PI / 2,
      dB: (-i + (N - 1) / 2) * ((d / 0.6) * dBmax) - dBmax,
    };
  }

  return data;
}

export function calculateDelays(sources_angle, N, d, theta) {
  let data = Array(N);
  for (let i = 0; i < N; i++) {
    let microphone_x = (i - (N - 1) / 2) * d;
    //let r = Math.sqrt(Math.pow(source.x - microphone_x, 2) + Math.pow(source.y, 2));
    //let dt = (r + microphone_x * Math.sin(theta)) / AirParameters.c0;
    let dt = (microphone_x * Math.sin(theta - sources_angle) + d * N) / AirParameters.c0;
    data[i] = dt;
  }
  return data;
}
