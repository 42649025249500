import React, { Component } from "react";
import AddedMassGraph from "./AddedMass.graph";
import SliderEdit from "../UI/SliderEdit";
import { COLORS } from "../../helpers/helpers";
import ProgressBar from "../UI/ProgressBar";
import "./AddedMass.css";

let title_text =
  "Select a type of measurement and click on 'Start Measurement'. You need to do at least two measurements (one without added mass, another one with an added mass) to be able to get the Thiele-Small parameters.";
const TextButton = ["Start Measurement", "", "Do another measurement"];

class AddedMass extends Component {
  // class state
  state = {
    page: 0, // step
    added_Mms: 10, // mass [g]
    add_mass: "no",
    progress: 0,
  };

  // slider parameters
  sliders = [
    {
      title: "added mass",
      name: "added_Mms",
      unit: "g",
      min: 5,
      max: 20.0,
      step: 1,
    },
  ];

  // handle for radio
  handleChangeRadio = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value });
  };

  // handle for sliders
  handleChange = (name, value) => {
    if (!isNaN(value)) {
      this.setState({ [name]: value });
    }
  };

  // handle for button cliks
  buttonClick = (event) => {
    let new_page = (Number(this.state.page) + 1) % 3;
    this.setState({ page: new_page });

    if (new_page === 1) {
      // show the progress bar
      this.setState({ progress: 0 });

      this.interval = setInterval(
        function () {
          if (this.state.progress >= 100) {
            // hide the progress bar
            this.setState({ page: 2 });
            clearInterval(this.interval);
          }
          this.setState({ progress: this.state.progress + 4 });
        }.bind(this),
        100
      );
    }
  };

  render() {
    let { added_Mms, page, add_mass, progress } = this.state;

    let display_slider_mass = page === 0 && add_mass === "yes" ? "block" : "none";
    let display_page_0 = page === 0 ? "block" : "none";
    let display_page_1 = page === 1 ? "block" : "none";
    let display_page_2 = page === 2 ? "block" : "none";

    return (
      <div>
        <div style={{ border: "solid 1px" }}>
          <div className="title-info-added-mass" style={{ background: COLORS[2] }}>
            <p style={{ width: "50%", margin: "auto" }}>{title_text}</p>
          </div>
          <div>
            <div style={{ margin: "15px", display: display_page_0 }}>
              {/* --------------------------------------------------*/}
              {/* radio buttons to choose between serial and parallel */}
              {/* --------------------------------------------------*/}
              <input
                type="radio"
                name="add_mass"
                value="no"
                checked={add_mass === "no"}
                onChange={this.handleChangeRadio}
              />
              no added mass
              <input
                type="radio"
                name="add_mass"
                value="yes"
                checked={add_mass === "yes"}
                onChange={this.handleChangeRadio}
              />
              added mass
            </div>
            {/* --------------------------------------------------*/}
            {/* sliders */}
            {/* --------------------------------------------------*/}
            <div className="slider-block" style={{ display: display_slider_mass }}>
              <SliderEdit
                onChange={this.handleChange}
                params={this.sliders[0]}
                val={this.state[this.sliders[0].name]}
              />
            </div>
          </div>
          <div style={{ margin: "15px", display: display_page_2 }}>
            <AddedMassGraph added_Mms={added_Mms} add_mass={add_mass} />
          </div>
          <div style={{ margin: "15px", display: page === 1 ? "none" : "block" }}>
            <button onClick={this.buttonClick}>{TextButton[page]}</button>
          </div>
          <div style={{ width: "50%", margin: "auto", display: display_page_1 }}>
            <ProgressBar bgcolor={COLORS[0]} completed={progress} />
          </div>
        </div>
      </div>
    );
  }
}
export default AddedMass;
