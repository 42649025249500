import React, { Component } from "react";
import LineGraph from "../UI/ChartJS/LineGraph";
import { prepareData } from "./SimpleSinus.helper";

class SimpleSinusGraph extends Component {
  state = {};
  render() {
    // List of inputs ("props") :
    //   A   ... signal amplitude
    //   f   ... signal frequency
    //   phi ... signal phase
    let { A, f, phi } = this.props;

    //let { input_signal, output_signal } = signal(A, NLfnc);
    let inputSignal = prepareData(A, f, phi);

    let signalData = {
      title: "Sine-wave signal",
      xAxes: {
        xLabel: "Time [ms]",
        type: "linear",
      },
      yAxes: [
        {
          data: [inputSignal],
          min: -1,
          max: 1,
          type: "linear",
        },
      ],
      animation: 0,
    };

    return <LineGraph data={signalData} graph_size_ratio={0.6} />;
  }
}

export default SimpleSinusGraph;
