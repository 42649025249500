import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import MicrophoneCardioGraph from "./MicrophoneCardio.graph";
import { sliders } from "./MicrophoneCardio.helper";

class MicrophoneCardio extends Component {
  // class state
  state = {
    Vab: 23, // back volume [cm^3]
  };

  // handle for sliders
  handleChange = (name, value) => {
    this.setState({ [name]: Number(value) });
  };

  render() {
    let { Vab } = this.state;

    return (
      <div>
        <div className="slider-block">
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            {sliders.map((slider, index) => {
              return (
                <SliderEdit
                  key={index}
                  onChange={this.handleChange}
                  params={slider}
                  val={this.state[slider.name]}
                />
              );
            })}
          </div>
        </div>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        {/* */}
        {/* --------------------------------------------------*/}
        {/* radio buttons to choose between linear and logarithmic Yaxis of chart.js*/}
        {/* --------------------------------------------------*/}
        <div className="one-graph">
          {/* --------------------------------------------------*/}
          {/* graph (chartjs)*/}
          {/* --------------------------------------------------*/}
          <MicrophoneCardioGraph
            Vab={Vab * 1e-6} // back volume, pass value from [cm^3] to [m^3]
          />
        </div>
      </div>
    );
  }
}
export default MicrophoneCardio;
