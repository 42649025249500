import { LogSpace } from "../../helpers/helpers";

export const ranges = {
  TELEPHONE: 0,
  HIGH_FI: 1,
  SUB: 2,
  WOOFER: 3,
  MIDRANGE: 4,
  TWEETER: 5,
};

// space graph options
export const range_options = [
  { title: "Old telephone", value: ranges.TELEPHONE, f1: 300, f2: 3400 },
  { title: "High-fi", value: ranges.HIGH_FI, f1: 20, f2: 20000 },
  { title: "Sub Woofer", value: ranges.SUB, f1: 10, f2: 60 },
  { title: "Woofer", value: ranges.WOOFER, f1: 40, f2: 500 },
  { title: "Midrange", value: ranges.MIDRANGE, f1: 500, f2: 2000 },
  { title: "Tweeter", value: ranges.TWEETER, f1: 2000, f2: 20000 },
];

// calculate resonance frequency Fc
export function FRF(f1, f2) {
  let Q1 = 0.707;
  let Q2 = 0.707;

  // frequency axis
  let f_axis = LogSpace(1, Math.log10(30e3), 100);

  // prepare arrays for Z_in and phase
  let FRF_abs = [];
  //let FRF_phase = [];
  for (let i = 0; i < f_axis.length; i++) {
    FRF_abs[i] = { x: f_axis[i], y: 0 };
    //FRF_phase[i] = { x: f_axis[i], y: 0 };
  }

  // omega1, omega2
  let omega1 = 2 * Math.PI * f1;
  let omega2 = 2 * Math.PI * f2;

  let A = omega1 / Q1 + omega2 / Q2;
  let B =
    Math.pow(omega1, 2) + Math.pow(omega2, 2) + ((omega1 / Q1) * omega2) / Q2;
  let C =
    (omega1 / Q1) * Math.pow(omega2, 2) + (omega2 / Q2) * Math.pow(omega1, 2);
  let D = Math.pow(omega1, 2) * Math.pow(omega2, 2);

  // HP - LP FRF sections
  for (let i = 0; i < f_axis.length; i++) {
    // frequency axis
    let w = 2 * Math.PI * f_axis[i];
    let w2 = Math.pow(w, 2);
    let w3 = Math.pow(w, 3);
    let w4 = Math.pow(w, 4);

    let DEN = Math.pow(w4 - w2 * B + D, 2) + Math.pow(w3 * A - w * C, 2);
    let NUM_RE = -Math.pow(omega2, 2) * w2 * (w4 - w2 * B + D);
    let NUM_IM = Math.pow(omega2, 2) * w2 * (w3 * A - w * C);

    let HPLP1 = Math.pow(NUM_RE / DEN, 2) + Math.pow(NUM_IM / DEN, 2);

    FRF_abs[i].y = 10 * Math.log10(Math.abs(HPLP1));
    //FRF_phase[i].y = -(180 / Math.PI) * Math.atan2(NUM_IM, NUM_RE);
  }

  //return { FRF_abs, FRF_phase };
  return { FRF_abs };
}
