import React, { Component } from "react";
import { calculateDelays } from "./MicrophoneArray.helper";

// Component used to play audio
const Nmax = 30;

class MicrophoneArrayAudio extends Component {
  state = {
    is_playing: false, // BOOL (audio is/(is not) playing)
  };

  audioContext = null;
  fileBuffer = null;

  loadAudioSource() {
    // source -> script node (gainNode) -> output (audioContext.destination)
    // audio context
    let AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioContext = new AudioContext();

    // source node
    this.source_node = this.audioContext.createBufferSource();

    window
      .fetch("/channels.wav")
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) =>
        this.audioContext.decodeAudioData(
          arrayBuffer,
          (audioBuffer) => {
            this.source_node.buffer = audioBuffer;
            this.source_node.loop = true;
            this.source_node.start();
          },
          (error) => console.error(error)
        )
      );

    // channel splitter
    let splitter = this.audioContext.createChannelSplitter(2);
    this.source_node.connect(splitter);

    this.finalGain = this.audioContext.createGain();
    this.finalGain.gain.value = 1;

    this.blocksA = [];
    this.blocksB = [];

    for (let i = 0; i < Nmax; i++) {
      this.blocksA[i] = {
        gainNode: this.audioContext.createGain(),
        delayNode: this.audioContext.createDelay(),
      };
      this.blocksB[i] = {
        gainNode: this.audioContext.createGain(),
        delayNode: this.audioContext.createDelay(),
      };

      splitter.connect(this.blocksA[i].gainNode, 0);
      this.blocksA[i].gainNode.gain.value = 1 / Nmax;
      this.blocksA[i].delayNode.delayTime.value = 0.1;
      this.blocksA[i].gainNode.connect(this.blocksA[i].delayNode);
      this.blocksA[i].delayNode.connect(this.finalGain);

      splitter.connect(this.blocksB[i].gainNode, 1);
      this.blocksB[i].gainNode.gain.value = 1 / Nmax;
      this.blocksB[i].delayNode.delayTime.value = 0;
      this.blocksB[i].gainNode.connect(this.blocksB[i].delayNode);
      this.blocksB[i].delayNode.connect(this.finalGain);
    }
  }

  updateDelays() {
    let { sources_angle, n_of_transducers, distance, theta0 } = this.props;
    let dataA = calculateDelays(sources_angle, n_of_transducers, distance, theta0);
    let dataB = calculateDelays(-sources_angle, n_of_transducers, distance, theta0);
    for (let i = 0; i < n_of_transducers; i++) {
      this.blocksA[i].gainNode.gain.value = 1 / n_of_transducers;
      this.blocksA[i].delayNode.delayTime.value = dataA[i];
      this.blocksB[i].gainNode.gain.value = 1 / n_of_transducers;
      this.blocksB[i].delayNode.delayTime.value = dataB[i];
    }
    for (let i = n_of_transducers; i < Nmax; i++) {
      this.blocksA[i].gainNode.gain.value = 0;
      this.blocksA[i].delayNode.delayTime.value = 0;
      this.blocksB[i].gainNode.gain.value = 0;
      this.blocksB[i].delayNode.delayTime.value = 0;
    }
  }

  componentDidUpdate() {
    // extract properties

    // on change any of the properties check if audio is on (checkbox)
    if (this.audioContext !== null) {
      if (this.state.is_playing === true) {
        // change parameters
        this.updateDelays();
        // connect node to destination
        this.finalGain.connect(this.audioContext.destination);
      } else {
        // disconnect the node from the destination (it will stop playing)
        this.finalGain.disconnect();
      }
    }
  }

  // when unmounting close the audio context
  componentWillUnmount() {
    if (this.audioContext !== null) {
      this.audioContext.close();
    }
  }

  // handle for checkbox audio playing
  handleChangePlay = () => {
    // check if audio context exists, if no, create the audio chain
    if (this.audioContext === null) {
      this.loadAudioSource();
    }
    // change the flag "is_playing"
    this.setState((prevState) => ({
      is_playing: !prevState.is_playing,
    }));
  };

  render() {
    return (
      <div>
        play sound
        <input
          type="checkbox"
          defaultChecked={this.state.is_playing}
          onChange={this.handleChangePlay}
        />
      </div>
    );
  }
}

export default MicrophoneArrayAudio;
