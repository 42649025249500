import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import BandPassGraph from "./BandPass.graph";
import BandPassAudio from "./BandPass.audio";
import { range_options } from "./BandPass.helper";

class BandPass extends Component {
  // class state
  state = {
    f1: range_options[0].f1, // frequency 1
    f2: range_options[0].f2, // frequency 2
  };

  // slider parameters
  sliders = [
    {
      name: "f1",
      unit: "Hz",
      min: 10,
      max: 20001,
      log: true,
      step: 0.01,
      edit: false,
    },
    {
      name: "f2",
      unit: "Hz",
      min: 10,
      max: 20001,
      log: true,
      step: 0.01,
      edit: false,
    },
  ];

  // handle for selection of ranges
  handleChangeRange = (event) => {
    this.setState({
      f1: range_options.find((obj) => obj.value === Number(event.target.value)).f1,
      f2: range_options.find((obj) => obj.value === Number(event.target.value)).f2,
    });
  };

  // handle for radio and sliders
  handleChange = (name, value) => {
    value = Math.round(Number(value));

    if (name === "f1") {
      this.setState({ f1: value });
      if (this.state.f2 < value) {
        this.setState({ f2: value });
      }
    }

    if (name === "f2") {
      this.setState({ f2: value });
      if (this.state.f1 > value) {
        this.setState({ f1: value });
      }
    }
  };

  render() {
    let { f1, f2 } = this.state;

    return (
      <div>
        <div className="slider-block">
          {/* --------------------------------------------------*/}
          {/* predefined values */}
          {/* --------------------------------------------------*/}
          <select onChange={this.handleChangeRange}>
            {range_options.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.title}
                </option>
              );
            })}
          </select>
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            {this.sliders.map((slider, index) => {
              return (
                <SliderEdit
                  key={index}
                  onChange={this.handleChange}
                  params={slider}
                  val={this.state[slider.name]}
                />
              );
            })}
            <BandPassAudio f1={f1} f2={f2} />
          </div>
        </div>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        <div className="one-graph">
          <BandPassGraph f1={f1} f2={f2} />
        </div>
      </div>
    );
  }
}

export default BandPass;
