import React, { Component } from "react";

// Component used to play audio
// props:   f ... frequency [Hz]
//          A ... amplitude of the audio [0..1]

class SimpleSineAudio extends Component {
  state = {
    is_playing: false, // BOOL (audio is/(is not) playing)
  };

  audioContext = null;
  componentDidUpdate() {
    // on component mount create audioContext (Web Audio API)
    // oscillator (osc) -> gain (gainNode) -> output (audioContext.destination)
    if (this.audioContext === null && this.state.is_playing === true) {
      // audio context
      let AudioContext = window.AudioContext || window.webkitAudioContext;
      this.audioContext = new AudioContext();

      // gain node
      this.gainNode = this.audioContext.createGain();
      this.gainNode.gain.value = 0;

      // oscillator
      this.osc = this.audioContext.createOscillator();
      this.osc.type = "sine";

      // connect gain to destination
      this.gainNode.connect(this.audioContext.destination);
      // but leave the oscillator not connected (no sound)
      this.osc.start();
    }

    if (this.audioContext !== null) {
      // on change any of the properties check if audio is on (checkbox)
      if (this.state.is_playing === true) {
        // set the frequency of the oscillator
        this.osc.frequency.value = this.props.f;

        // set the gain (using a linear ramp (fade-in)) to avoid abrupt changes
        this.gainNode.gain.linearRampToValueAtTime(
          this.props.A,
          this.audioContext.currentTime + 0.1
        );

        // connect the oscilator to the gain (it will start playing)
        this.osc.connect(this.gainNode);
      } else {
        // disconnect the oscilator from the gain (it will stop playing)
        this.osc.disconnect();
      }
    }
  }

  // when unmounting close the audio context
  componentWillUnmount() {
    if (this.audioContext !== null) {
      this.audioContext.close();
    }
  }

  // handle for checkbox audio playing
  handleChangePlay = () => {
    this.setState((prevState) => ({
      is_playing: !prevState.is_playing,
    }));
  };

  render() {
    return (
      <div>
        play sound
        <input
          type="checkbox"
          defaultChecked={this.state.is_playing}
          onChange={this.handleChangePlay}
        />
      </div>
    );
  }
}

export default SimpleSineAudio;
