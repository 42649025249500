import { round } from "../../../helpers/math.helper";

export function pressureFnc(signal, x, L, t, physics, phi) {
  let alpha = signal.alpha[0];
  let { arg_f, arg_g } = argumentsx(x, physics.c0[0], L, t);

  return (
    signal.f(arg_f, phi, 0) * Math.exp(-alpha * x) + signal.g(arg_g, phi, 0) * Math.exp(alpha * x)
  );
}

export function velocityFnc(signal, x, L, t, physics, phi) {
  let alpha = signal.alpha[0];
  let { arg_f, arg_g } = argumentsx(x, physics.c0[0], L, t);

  return (
    (1 / (physics.rho[0] * physics.c0[0])) *
    (signal.f(arg_f, phi, 0) * Math.exp(-alpha * x) - signal.g(arg_g, phi, 0) * Math.exp(alpha * x))
  );
}

export function displacementFnc(signal, x, L, t, physics, phi) {
  let alpha = signal.alpha[0];
  let { arg_f, arg_g } = argumentsx(x, physics.c0[0], L, t);
  return (
    (1 / (physics.rho[0] * Math.pow(physics.c0[0], 2))) *
    (-signal.F(arg_f, phi, 0) * Math.exp(-alpha * x) -
      signal.G(arg_g, phi, 0) * Math.exp(alpha * x))
  );
}

export function intensityFnc(signal, x, L, t, physics, phi) {
  let p = pressureFnc(signal, x, L, t, physics, phi);
  let v = velocityFnc(signal, x, L, t, physics, phi);
  return p * v;
}

export function intensityMeanFnc(signal, x) {
  let A = signal.A.abs;
  let B = signal.B.abs;
  let alpha = signal.alpha[0];
  let I_mean = ((A * A) / 2) * Math.exp(-2 * alpha * x) - ((B * B) / 2) * Math.exp(2 * alpha * x);
  return I_mean;
}

export function intensityMinMaxFnc() {
  let A = this.A.abs;
  let B = this.B.abs;
  let alpha = this.alpha[0];

  //let Imax = round((A * A) / 2 - (B * B) / 2, 3, Math.ceil);
  let Imin = round(
    ((A * A) / 2) * Math.exp(-2 * alpha) - ((B * B) / 2) * Math.exp(2 * alpha),
    3,
    Math.floor
  );
  return { value_max: 0, value_min: Imin };
}

function argumentsx(x, c0, L, t) {
  let arg_f = x - c0 * t;
  let arg_g = x + c0 * t;
  return { arg_f, arg_g };
}
