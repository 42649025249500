import React, { Component } from "react";
import LineGraph from "../UI/ChartJS/LineGraph";
import { LogSpace } from "../../helpers/helpers";
import { sensitivity } from "./ClosedBoxSystem.helper";

class ClosedBoxSystemGraph extends Component {
  render() {
    let { Vmult, Rmult } = this.props;

    // frequency axis
    let f_ax = LogSpace(1, Math.log10(1e3), 100);

    let { Z, P, Z_baf, P_baf } = sensitivity(Vmult, Rmult, f_ax);

    // graph data
    let dataImpedance = {
      title: "Loudspeaker Impedance",
      xAxes: {
        xLabel: "Frequency [Hz]",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [Z, Z_baf],
          label: ["closed-box", "baffled"],
          yLabel: "Input impedance (abs)",
          type: "linear",
        },
      ],
      animation: 0,
    };

    let dataSensitivity = {
      title: "SPL @2.83V @1m",
      xAxes: {
        xLabel: "Frequency [Hz]",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [P, P_baf],
          yLabel: "Sound Pressure Level [dB SPL]",
          label: ["closed-box", "baffled"],
          type: "linear",
          min: 60,
          max: 100,
        },
      ],
    };

    return (
      <div>
        <div className="two-graphs">
          <LineGraph data={dataImpedance} graph_size_ratio={0.7} />
        </div>
        <div className="two-graphs">
          <LineGraph data={dataSensitivity} graph_size_ratio={0.7} />
        </div>
        <div className="clr" />
      </div>
    );
  }
}
export default ClosedBoxSystemGraph;
