import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import ClosedBoxSystemGraph from "./ClosedBoxSystem.graph";
import ClosedBoxSystemSVG from "./ClosedBoxSystem.svg";

import { sliders } from "./ClosedBoxSystem.helper";

// -------------------------
// Closed-box loudspeaker system simulation
// - Loudspeaker with given TS parameters has a closed-box which can change
//        Vmult ... Volume = Vmult * Vas
//        Rmult ... Rms_tot = Rmult * Rms
// -------------------------

class ClosedBoxSystem extends Component {
  // class state
  state = {
    Vmult: 1, // multiplication factor of volume
    Rmult: 0, // multiplication factor of mechanical resistance
  };

  // handle for sliders
  handleChange = (name, value) => {
    this.setState({ [name]: Number(value) });
  };

  render() {
    let { Vmult, Rmult } = this.state;
    return (
      <div>
        <div className="slider-block">
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            {sliders.map((slider, index) => {
              return (
                <SliderEdit
                  key={index}
                  onChange={this.handleChange}
                  params={slider}
                  val={this.state[slider.name]}
                />
              );
            })}
          </div>
        </div>

        {/* --------------------------------------------------*/}
        {/* ----------------  SVG DRAWING  -------------------*/}
        {/* --------------------------------------------------*/}
        <div style={{ margin: "auto", width: "50%" }}>
          <ClosedBoxSystemSVG Vmult={Vmult} Rmult={Rmult} />
        </div>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        <div>
          <ClosedBoxSystemGraph Vmult={Vmult} Rmult={Rmult} />
        </div>
      </div>
    );
  }
}
export default ClosedBoxSystem;
