import React, { Component } from "react";
import WaveGuideHelmholtzCanvas from "./WaveGuideHelmholtz.canvas.jsx";

class WaveGideHelmholtzAnimation extends Component {
  handleCheckVelocityArrows = () => {
    this.props.properties.animation.velocity_arrows.show =
      !this.props.properties.animation.velocity_arrows.show;
  };

  handleCheckPressureColors = () => {
    this.props.properties.animation.pressure_colors.show =
      !this.props.properties.animation.pressure_colors.show;
  };

  render() {
    let { animation } = this.props.properties;
    let t = this.props.t;

    const show_colors_text = animation.pressure_colors.text || "show pressure colors";
    const show_arrows_text = animation.velocity_arrows.text || "show velocity arrows";

    return (
      <div>
        {/* velocity arrows and pressure colors checkbox */}
        <div className="graph-properties">
          {/* velocity arrows checkbox */}
          {animation.velocity_arrows.can_be_modified && (
            <div className="graph-property">
              {show_arrows_text}
              <input
                type="checkbox"
                defaultChecked={animation.velocity_arrows.show}
                onChange={this.handleCheckVelocityArrows}
              />
            </div>
          )}

          {/* pressure colors checkbox */}
          {animation.pressure_colors.can_be_modified && (
            <div className="graph-property">
              {show_colors_text}
              <input
                type="checkbox"
                defaultChecked={animation.pressure_colors.show}
                onChange={this.handleCheckPressureColors}
              />
            </div>
          )}
        </div>

        {/* informative text fileds and equations above the canvas animation */}
        <div style={{ width: "70%", margin: "auto" }}>
          {/* CANVAS */}
          <WaveGuideHelmholtzCanvas t={t} properties={this.props.properties} />
        </div>
      </div>
    );
  }
}

export default WaveGideHelmholtzAnimation;
