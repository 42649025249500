import React, { useState, useEffect } from "react";
import SliderEdit from "../../../UI/SliderEdit";
import { SIGNAL_CHOICE } from "../../helpers/WaveGuide.signals.js";

import "../../WaveGuide.css";

export const REFLECTION_TYPES = {
  NEUMANN: { value: 0, title: "R = 1" },
  DIRICHLET: { value: 1, title: "R = -1" },
  NO: { value: 2, title: "R = 0" },
  COMPLEX: { value: 3, title: "R = |R|*exp(phi)" },
  RADIATION: { value: 4, title: "radiation impedance" },
};

const SLIDERS = [
  {
    title: "|R|",
    name: "R",
    unit: "",
    min: 0,
    max: 1,
    step: 0.01,
    edit: false,
  },
  {
    title: "phi",
    name: "phi",
    unit: "°",
    min: 0,
    max: 360,
    step: 1,
    edit: false,
  },
];

export default function WaveGuideReflection({ properties, signal, physics, update_flag }) {
  const [reflection_type, setType] = useState(physics.reflection.type);
  const [R, setR] = useState(1);
  const [phi, setPhi] = useState(0);

  const handleChangeSlider = (name, value) => {
    if (name === "R") setR(value);
    if (name === "phi") setPhi(value);
  };

  const updateFnc = (value) => {
    properties.signal.setReflection(value, properties.physics);
    properties.physics.reflection.type = value.type;
    properties.flag_recalculate = true;
  };

  useEffect(() => {
    updateFnc({
      abs: Number(R),
      arg: (Number(phi) * Math.PI) / 180,
      type: reflection_type,
    });
  }, [R, phi]);

  useEffect(() => {
    // if R was set to complex valued |R|*exp(phi)
    // and signal is changed to non-harmonic, complex valued R has no sense.
    // Change it to R = 1.
    if (signal.type !== SIGNAL_CHOICE.HARMONIC && reflection_type === REFLECTION_TYPES.COMPLEX) {
      setType(0);
    }
  }, [signal]);

  useEffect(() => {
    if (reflection_type === REFLECTION_TYPES.NEUMANN.value) {
      setR(1);
      setPhi(0);
    }
    if (reflection_type === REFLECTION_TYPES.DIRICHLET.value) {
      setR(1);
      setPhi(180);
    }
    if (reflection_type === REFLECTION_TYPES.NO.value) {
      setR(0);
      setPhi(0);
    }
    if (reflection_type === REFLECTION_TYPES.RADIATION.value) {
      let k = signal.k[0];
      let H = physics.radius.values[0];
      setR(Math.exp(-Math.pow(k * H, 2) / 2));
      setPhi(-1.4 * k * H * (180 / Math.PI));
    }
  }, [reflection_type, update_flag]);

  SLIDERS[0].value = R;
  SLIDERS[1].value = phi;

  return (
    <>
      <select
        style={{ marginTop: "15px", marginBottom: "15px" }}
        value={reflection_type}
        onChange={(e) => setType(Number(e.currentTarget.value))}
      >
        {physics.reflection.types.map((type, index) => {
          let return_value = (
            <option key={index} value={type.value}>
              {type.title}
            </option>
          );
          const DONT_SHOW_COMPLEX =
            type.value === REFLECTION_TYPES.COMPLEX.value && signal.type !== SIGNAL_CHOICE.HARMONIC;

          let return_flag = DONT_SHOW_COMPLEX ? false : true;

          if (return_flag) {
            return return_value;
          }
        })}
      </select>
      {reflection_type === 3 ? (
        <div className="sliders">
          {SLIDERS.map((slider) => {
            return (
              <SliderEdit
                key={slider.name}
                onChange={handleChangeSlider}
                params={slider}
                val={slider.value}
              />
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
