import React, { useState, useEffect } from "react";
import SliderEdit from "../../../UI/SliderEdit";
import { round } from "../../../../helpers/math.helper";

import "../../WaveGuide.css";

/*  
  There are three dependent values:
    - frequency (freq)
    - speed of sound (c0)
    - wavelength (lam)

  User can select only two of them, the third one is calcualted.
*/

const SLIDERS = [
  {
    title: "frequency",
    name: 0,
    unit: "Hz",
    min: 100,
    max: 2000,
    step: 1,
    edit: false,
  },
  {
    title: "speed of sound",
    name: 1,
    unit: "m/s",
    min: 100,
    max: 1000,
    step: 1,
    edit: false,
  },
  {
    title: "wavelength",
    name: 2,
    unit: "m",
    min: 0.1,
    max: 4,
    step: 0.1,
    edit: false,
  },
];

function updateFCWValues(prev_state, fck_selected = 0, idx = 0) {
  let f = prev_state[0]; // frequency
  let c = prev_state[1]; // speed of sound
  let lam = prev_state[2]; // wavelength
  let recalculate = [false, false]; // booleans for [f, c]

  switch (fck_selected) {
    // calculate wavelength (frequency and speed of sound are selected)
    case 0: //
      lam = c / f;

      // limit minimum and maximum of wave-length
      if (lam < 0.1) {
        lam = 0.1;
        recalculate[idx] = true;
      }
      if (lam > 4) {
        lam = 4;
        recalculate[idx] = true;
      }
      break;

    // calculate speed of sound (frequency and wave-length are selected)
    case 1: //
      c = lam * f;
      break;

    // calculate frequency (speed of sound and wave-length are selected)
    case 2:
      f = c / lam;
      break;

    default:
      break;
  }

  f = recalculate[0] ? c / lam : f;
  c = recalculate[1] ? lam * f : c;

  return [f, c, lam];
}

export default function WaveGuideFCW(props) {
  const { c0, freq, properties } = props;

  const [fcw_selected, setFCW] = useState(0);
  const [fcw_values, setFCWValues] = useState([freq, c0, 0]);
  const [sliders, setSliders] = useState([]);

  useEffect(() => {
    setFCWValues((prev_state) => {
      return updateFCWValues(prev_state);
    });
  }, []);

  // always select
  useEffect(() => {
    setSliders(SLIDERS.filter((slider, idx) => fcw_selected !== 2 - idx));
  }, [fcw_selected]);

  const handleChangeSlider = (idx, value) => {
    // make copy of previous state and update new value
    let prev_state = [...fcw_values];
    prev_state[idx] = Number(value);

    // compute other values (frequency, speed of light, wavelength)
    let new_values = updateFCWValues(prev_state, fcw_selected, idx);

    // set new State
    setFCWValues(new_values);

    // change parameters (for other components)
    properties.signal.freq.value = new_values[0];
    properties.signal.setAlpha(properties.physics.radius.values);
    properties.physics.c0 = [new_values[1], new_values[1]];
    properties.signal.lambda = [new_values[2], new_values[2]];
    properties.signal.setWavenumer(properties.physics.c0);
  };

  const idx_not_selected = 2 - fcw_selected;
  return (
    <>
      {/* selector for slider combinations (show only 2 of 3 dependent values) */}
      <select
        style={{ marginTop: "15px", marginBottom: "15px" }}
        value={fcw_selected}
        onChange={(e) => setFCW(Number(e.currentTarget.value))}
      >
        <option value="0">Frequency / Speed of sound</option>
        <option value="1">Frequency / Wavelength</option>
        <option value="2">Wavelength / Speed of sound</option>
      </select>

      {/* 2 sliders to show the two selected values */}
      <div className="sliders">
        {sliders.map((slider) => {
          return (
            <SliderEdit
              key={slider.name}
              onChange={handleChangeSlider}
              params={slider}
              val={fcw_values[slider.name]}
            />
          );
        })}
      </div>

      {/* The third (not-selected) value is shown as a text */}
      <div style={{ fontSize: "90%", marginBottom: "15px" }}>
        {SLIDERS[idx_not_selected].title} = {round(fcw_values[idx_not_selected])}{" "}
        {SLIDERS[idx_not_selected].unit}
      </div>
    </>
  );
}
