import React, { useState, useEffect } from "react";

import "../../WaveGuide.css";

const RESONATOR_TYPES = [
  { idx: 0, name: "quarter wave resonator", phi: 0, closed: true },
  { idx: 1, name: "half wave resonator", phi: Math.PI, closed: false },
];

export default function WaveGuideOpenClosed({ properties }) {
  const [resonator_type, setResonatorType] = useState(RESONATOR_TYPES[0]);

  useEffect(() => {
    // update the properties (for other components)
    properties.signal.setReflection(
      { abs: 1, arg: Number(resonator_type.phi) },
      properties.physics
    );
    properties.flag_recalculate = true;
    properties.physics.closed = resonator_type.closed;
  }, [resonator_type]);

  return (
    <select
      style={{ marginTop: "15px", marginBottom: "15px" }}
      value={resonator_type.idx}
      onChange={(e) => setResonatorType(RESONATOR_TYPES[e.currentTarget.value])}
    >
      {RESONATOR_TYPES.map((type) => (
        <option value={type.idx}>{type.name}</option>
      ))}
    </select>
  );
}
