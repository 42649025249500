import React, { useState } from "react";
import "./HideDiv.css";

export default function HideDiv(props) {
  const [show, setShow] = useState(true);

  return (
    <div className={show ? "hidediv-div" : "hidediv-div hidediv-div-hidden"}>
      <div className="hidediv-button" onClick={() => setShow((v) => !v)}>
        {show ? props.text.hide : props.text.show}
      </div>

      <div style={{ display: show ? "block" : "none" }}>{props.children}</div>
    </div>
  );
}
