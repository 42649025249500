import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import NonlinearLoudspeakerGraph from "./NonlinearLoudspeaker.graph";
import { speakers_options } from "./NonlinearLoudspeaker.helper";

// -------------------------

// -------------------------

class NonlinearLoudspeaker extends Component {
  state = {
    x: 0, // displacement
    selected_speaker: speakers_options[0],
  };

  // sliders
  sliders = [
    {
      name: "x",
      unit: "mm",
      min: -5,
      max: 5,
      step: 0.5,
      edit: false,
    },
  ];

  // handle for slider (Amplitude)
  handleChange = (name, value) => {
    // check if user has entered a numeric value
    if (!isNaN(value)) {
      this.setState({ [name]: Number(value) });
    }
  };

  // handle for selection of ranges
  handleChangeSpeaker = (event) => {
    this.setState({
      selected_speaker: speakers_options.find((obj) => obj.value === Number(event.target.value)),
    });
  };

  render() {
    let { x, selected_speaker } = this.state;

    return (
      <div>
        <div className="slider-block">
          {/* --------------------------------------------------*/}
          {/* predefined values */}
          {/* --------------------------------------------------*/}
          <select onChange={this.handleChangeSpeaker}>
            {speakers_options.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.title}
                </option>
              );
            })}
          </select>
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            {this.sliders.map((slider, index) => {
              return (
                <SliderEdit
                  key={index}
                  onChange={this.handleChange}
                  params={slider}
                  val={this.state[slider.name]}
                />
              );
            })}
          </div>
        </div>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        {/* */}
        {/* --------------------------------------------------*/}
        {/* graph (chartjs)*/}
        {/* --------------------------------------------------*/}
        <div style={{ width: "75%", margin: "auto" }}>
          <NonlinearLoudspeakerGraph type="Bl" x={x} selected_speaker={selected_speaker} />
        </div>
      </div>
    );
  }
}

export default NonlinearLoudspeaker;
