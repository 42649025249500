/*
 * Defines the scale for the smith chart.
 * When built, Chart will be passed via the UMD header
 */
import Chart from "chart.js";
import { formatNumber } from "./graph.helpers";

const helpers = Chart.helpers;

const defaults = {
  position: "chartArea",
  display: true,
  ticks: {
    padding: 5,
    rCallback: (tick) => {
      return tick === 0 ? "" : tick.toString() + " dB";
    },
    xCallback: (tick) => tick.toString() + "°",
  },
};

class Scale extends Chart.Scale {
  setDimensions() {
    this.padding = 20;
  }

  dBtoRadius(dB) {
    let { max, min } = this.options.ticks;
    return (1 / (max - min)) * dB - min / (max - min);
  }

  buildTicks() {
    let { min, max } = this.options.ticks;

    // dB tikcs (max 10 ticks per full scale)
    let dBTickStep = this.scientificRound(Math.floor((max - min) / 10));
    let dBTickNum = Math.floor((max - min) / dBTickStep);
    this.dBTicks = [...Array(dBTickNum + 1).keys()].map((x) => formatNumber(max - x * dBTickStep));

    // angle ticks
    let angleTickStep = 10;
    let angleTickNum = Math.floor(180 / angleTickStep) + 1;
    this.angleTicks = [...Array(angleTickNum).keys()].map((x) =>
      formatNumber(x * angleTickStep - 90)
    );
    return [];
  }

  scientificRound(val) {
    // mantisa & exponent
    let exponent = Math.floor(Math.log10(val));
    let mantisa = val / Math.pow(10, exponent);

    let mantisa_ceil = [1, 2, 5, 10].reduce((acc, curr) => {
      return (curr - mantisa >= 0) & (acc > curr) ? curr : acc;
    }, Infinity);

    return mantisa_ceil * Math.pow(10, exponent);
  }

  convertTicksToLabels() {
    this.dBLabels = this.dBTicks.map(function (tick, index, ticks) {
      return this.options.ticks.rCallback.apply(this, [tick, index, ticks]);
    }, this);

    this.angleLabels = this.angleTicks.map(function (tick, index, ticks) {
      return this.options.ticks.xCallback.apply(this, [tick, index, ticks]);
    }, this);

    return [];
  }

  // fit function similar to the radial linear scale
  fit() {
    const me = this;
    me.xCenter = (me.left + me.right) / 2;
    me.yCenter = me.maxHeight - me.padding;
    const fontSize = helpers.getValueOrDefault(
      me.options.ticks.fontSize,
      Chart.defaults.global.defaultFontSize
    );

    if (me.options.ticks.display) {
      const fontStyle = helpers.getValueOrDefault(
        me.options.ticks.fontStyle,
        Chart.defaults.global.defaultFontStyle
      );
      const fontFamily = helpers.getValueOrDefault(
        me.options.ticks.fontFamily,
        Chart.defaults.global.defaultFontFamily
      );
      const labelFont = helpers.fontString(fontSize, fontStyle, fontFamily);
      me.ctx.font = labelFont;
    }

    let maxRadiusWidth = (me.right - me.left) / 2 - 2 * me.padding;
    let maxRadiusHeight = me.maxHeight - me.padding - 60;
    me.maxRadius = Math.min(maxRadiusWidth, maxRadiusHeight);

    // Store data about the arcs that we will draw
    me.arcs = [];
    me.angleLines = [];
    me.dBLabelPoints = [];
    me.angleLabelPoints = [];

    // Draw each of the circles
    helpers.each(me.dBTicks, (r) => {
      const radius = this.dBtoRadius(r) * me.maxRadius; // scale for the min dimension
      const x = me.xCenter;

      me.arcs.push({
        x,
        y: me.yCenter,
        r: radius,
        s: Math.PI,
        e: 2 * Math.PI,
        cc: false,
      });

      me.dBLabelPoints.push({
        x: me.xCenter,
        y: me.yCenter - radius,
      });
    });

    // Draw each of the angle lines
    helpers.each(me.angleTicks, (x) => {
      const phi0 = (x * Math.PI) / 180;

      me.angleLines.push({
        x: me.xCenter + Math.sin(phi0) * me.maxRadius,
        y: me.yCenter - Math.cos(phi0) * me.maxRadius,
      });

      me.angleLabelPoints.push({
        x: me.xCenter + Math.sin(phi0) * me.maxRadius,
        y: me.yCenter - Math.cos(phi0) * me.maxRadius,
      });
    });
  }

  // Need a custom draw function here
  draw() {
    const me = this;

    if (me.options.display) {
      me.ctx.strokeStyle = me.options.gridLines.color;
      me.ctx.lineWidth = me.options.gridLines.lineWidth;

      // Draw each of the circles
      helpers.each(me.arcs, (arc) => {
        me.ctx.beginPath();
        me.ctx.arc(arc.x, arc.y, arc.r, arc.s, arc.e, arc.cc);
        me.ctx.stroke();
      });
      // Draw each of the angle lines
      helpers.each(me.angleLines, (angleLine) => {
        me.ctx.beginPath();
        me.ctx.moveTo(me.xCenter, me.yCenter);
        me.ctx.lineTo(angleLine.x, angleLine.y);
        me.ctx.stroke();
      });

      if (me.options.ticks.display) {
        const fontSize = helpers.getValueOrDefault(
          me.options.ticks.fontSize,
          Chart.defaults.global.defaultFontSize
        );
        const fontStyle = helpers.getValueOrDefault(
          me.options.ticks.fontStyle,
          Chart.defaults.global.defaultFontStyle
        );
        const fontFamily = helpers.getValueOrDefault(
          me.options.ticks.fontFamily,
          Chart.defaults.global.defaultFontFamily
        );

        const labelFont = helpers.fontString(fontSize, fontStyle, fontFamily);
        me.ctx.font = labelFont;

        me.ctx.fillStyle = helpers.getValueOrDefault(
          me.options.ticks.fontColor,
          Chart.defaults.global.defaultFontColor
        );

        helpers.each(me.dBLabels, (dBLabel, index) => {
          const pt = me.dBLabelPoints[index];

          me.ctx.save();
          me.ctx.translate(pt.x, pt.y);
          me.ctx.textBaseline = "middle";
          me.ctx.textAlign = "center";
          me.ctx.fillText(dBLabel, 0, 0);
          me.ctx.restore();
        });

        helpers.each(me.angleLabels, (angleLabel, index) => {
          const pt = me.angleLabelPoints[index];

          if (pt) {
            let align = "left";
            let ang = Math.atan2(pt.y - me.yCenter, pt.x - me.xCenter);
            let textPadding = me.options.ticks.padding;

            if (pt.x < me.xCenter) {
              ang += Math.PI;
              align = "right";
              textPadding *= -1;
            }

            me.ctx.save();
            me.ctx.translate(pt.x, pt.y);
            me.ctx.rotate(ang);
            me.ctx.textBaseline = "middle";
            me.ctx.textAlign = align;
            me.ctx.fillText(angleLabel, textPadding, 0);
            me.ctx.restore();
          }
        });
      }
    }
  }

  getPointPosition(phi, dB) {
    // look for the intersection of the r circle and the x circle that is not the one along the right side of the canvas
    const val = this.dBtoRadius(dB) * this.maxRadius; // scale for the minDimension size

    return {
      x: this.xCenter + Math.sin(phi) * val,
      y: this.yCenter - Math.cos(phi) * val,
    };
  }
}

export { defaults };
export default Scale;
