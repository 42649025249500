import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import SimpleSinusGraph from "./SimpleSinus.graph";
import SimpleSinusAudio from "./SimpleSinus.audio";

// -------------------------
// Simple Sinus function:
// Show sine signal of
//   - amplitude A (slider variable),
//   - frequency f (slider variable),
//   - phase Phi (slider variable).
// -------------------------

class SimpleSinus extends Component {
  state = {
    A: 0.5, // amplitude
    f: 1000, // frequency
    phi: 0, // phase
  };

  // sliders
  sliders = [
    {
      name: "A",
      unit: "",
      min: 0,
      max: 1.0,
      step: 0.01,
    },
    {
      name: "f",
      unit: "Hz",
      min: 100,
      max: 10000,
      step: 1,
    },
    {
      name: "phi",
      unit: "rad",
      min: 0,
      max: 6.28,
      step: 0.01,
    },
  ];

  // handle for slider (Amplitude)
  handleChange = (name, value) => {
    // check if user has entered a numeric value
    if (!isNaN(value)) {
      this.setState({ [name]: Number(value) });
    }
  };

  render() {
    let { A, f, phi } = this.state;

    return (
      <div>
        <div className="slider-block">
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            {this.sliders.map((slider, index) => {
              return (
                <SliderEdit
                  key={index}
                  onChange={this.handleChange}
                  params={slider}
                  val={this.state[slider.name]}
                />
              );
            })}
            <SimpleSinusAudio f={f} A={A} />
          </div>
        </div>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        {/* */}
        {/* --------------------------------------------------*/}
        {/* graph (chartjs)*/}
        {/* --------------------------------------------------*/}
        <div className="one-graph">
          <SimpleSinusGraph A={A} f={f} phi={phi} />
        </div>
      </div>
    );
  }
}

export default SimpleSinus;
