import { getWaveguideIndex } from "./WaveGuide.helper.js";

export function pressureFnc(signal, x, L, t, physics, phi) {
  let A_incident = physics?.showWaves?.incident ?? 1;
  let A_reflected = physics?.showWaves?.reflected ?? 1;

  let x_interface = physics.reflection?.x ?? 0.5;
  let idx = getWaveguideIndex(x, x_interface);

  let alpha = signal.alpha[idx];

  let { arg_f, arg_g } = argumentsx(x, physics.c0[idx], L, t);
  //   let arg_f = -x + c0 * t;
  //   let arg_g = x - L + c0 * t;
  if (idx === 0) {
    return (
      A_incident * signal.f(arg_f, phi, idx) * Math.exp(-alpha * x) +
      A_reflected *
        signal.Rf.abs() *
        signal.f(arg_g - (2 * x_interface - L), phi + signal.Rf.arg(), idx) *
        Math.exp(alpha * (x - 2 * x_interface)) +
      signal.Tg.abs() *
        signal.g(arg_g + signal.wave_delay_g, phi + signal.Tg.arg(), idx) *
        Math.exp(alpha * (x - L))
    );
  } else {
    return (
      A_incident * signal.g(arg_g, phi, idx) * Math.exp(alpha * (x - L)) +
      A_reflected *
        signal.Rg.abs() *
        signal.g(arg_f + (2 * x_interface - L), phi + signal.Rg.arg(), idx) *
        Math.exp(-alpha * (x - 2 * x_interface + L)) +
      signal.Tf.abs() *
        signal.f(arg_f + signal.wave_delay_f, phi + signal.Tf.arg(), idx) *
        Math.exp(-alpha * x)
    );
  }
}

export function velocityFnc(signal, x, L, t, physics, phi) {
  let A_incident = physics?.showWaves?.incident ?? 1;
  let A_reflected = physics?.showWaves?.reflected ?? 1;

  let x_interface = physics.reflection?.x ?? 0.5;
  let idx = getWaveguideIndex(x, x_interface);

  let alpha = signal.alpha[idx];
  let { arg_f, arg_g } = argumentsx(x, physics.c0[idx], L, t);
  if (idx === 0) {
    return (
      (1 / (physics.rho[idx] * physics.c0[idx])) *
      (A_incident * signal.f(arg_f, phi, idx) * Math.exp(-alpha * x) -
        A_reflected *
          signal.Rf.abs() *
          signal.f(arg_g - (2 * x_interface - L), phi + signal.Rf.arg(), idx) *
          Math.exp(alpha * (x - 2 * x_interface)) -
        signal.Tg.abs() *
          signal.g(arg_g + signal.wave_delay_g, phi + signal.Tg.arg(), idx) *
          Math.exp(alpha * (x - L)))
    );
  } else {
    return (
      (1 / (physics.rho[idx] * physics.c0[idx])) *
      (-A_incident * signal.g(arg_g, phi, idx) * Math.exp(alpha * (x - L)) +
        A_reflected *
          signal.Rg.abs() *
          signal.g(arg_f + (2 * x_interface - L), phi + signal.Rg.arg(), idx) *
          Math.exp(-alpha * (x - 2 * x_interface + L)) +
        signal.Tf.abs() *
          signal.f(arg_f + signal.wave_delay_f, phi + signal.Tf.arg(), idx) *
          Math.exp(-alpha * x))
    );
  }
}

export function displacementFnc(signal, x, L, t, physics, phi) {
  let A_incident = physics?.showWaves?.incident ?? 1;
  let A_reflected = physics?.showWaves?.reflected ?? 1;

  let x_interface = physics.reflection?.x ?? 0.5;
  let idx = getWaveguideIndex(x, x_interface);

  let alpha = signal.alpha[idx];
  let { arg_f, arg_g } = argumentsx(x, physics.c0[idx], L, t);
  if (idx === 0) {
    return (
      (1 / (physics.rho[idx] * Math.pow(physics.c0[idx], 2))) *
      (A_incident * signal.F(arg_f, phi, idx) * Math.exp(-alpha * x) -
        A_reflected *
          signal.Rf.abs() *
          signal.F(arg_g - (2 * x_interface - L), phi + signal.Rf.arg(), idx) *
          Math.exp(alpha * (x - 2 * x_interface)) -
        signal.Tg.abs() *
          signal.G(arg_g + signal.wave_delay_g, phi + signal.Tg.arg(), idx) *
          Math.exp(alpha * (x - L)))
    );
  } else {
    return (
      (1 / (physics.rho[idx] * Math.pow(physics.c0[idx], 2))) *
      (-A_incident * signal.G(arg_g, phi, idx) * Math.exp(alpha * (x - L)) +
        A_reflected *
          signal.Rg.abs() *
          signal.G(arg_f + (2 * x_interface - L), phi + signal.Rg.arg(), idx) *
          Math.exp(-alpha * (x - 2 * x_interface + L)) +
        signal.Tf.abs() *
          signal.F(arg_f + signal.wave_delay_f, phi + signal.Tf.arg(), idx) *
          Math.exp(-alpha * x))
    );
  }
}

function argumentsx(x, c0, L, t) {
  let arg_f = -x + c0 * t;
  let arg_g = x - L + c0 * t;
  return { arg_f, arg_g };
}

export function pressureMinMaxFnc() {
  let pmax = this.graph_limits.pmax;
  return { value_max: pmax, value_min: -pmax };
}
