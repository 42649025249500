import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import { ColorPlot } from "./ColorPlotExample.canvas.js";
import {
  sliderA,
  length_from_min_step_max,
  calculate_colorplot,
} from "./ColorPlotExample.helper.js";
import "./ColorPlotExample.css";

class ColorPlotExample extends Component {
  constructor() {
    super();
    this.graph = {
      x_min_step_max: [-2, 0.02, 2],
      y_min_step_max: [-2, 0.05, 2],
      z_min_max: [-0.5, 0.5],
    };
    this.graph.x_len = length_from_min_step_max(this.graph.x_min_step_max);
    this.graph.y_len = length_from_min_step_max(this.graph.y_min_step_max);
    // create a new empty array
    this.graph.arr = Array.from(Array(this.graph.x_len), () => new Array(this.graph.y_len));

    this.state = {
      A: 1.0,
    };
    this.myColorPlot = new ColorPlot();
  }

  componentDidMount() {
    this.ctx = this.refs.canvas.getContext("2d");
    this.updateCanvas();
  }

  componentDidUpdate() {
    this.updateCanvas();
  }

  updateCanvas() {
    // update graph
    calculate_colorplot(this.graph, this.state);

    // update canvas width (reactive)
    this.refs.canvas.style.width = "50%";
    this.refs.canvas.width = this.refs.canvas.offsetWidth;
    this.refs.canvas.height = 0.5 * this.refs.canvas.offsetWidth;

    // clear the space for canvas
    this.ctx.clearRect(0, 0, this.refs.canvas.width, this.refs.canvas.height);
    // draw on it
    this.myColorPlot.draw(this.ctx, this.graph);
  }

  // handle for sliders
  handleChange = (name, value) => {
    // check if user has entered a numeric value
    if (!isNaN(value)) {
      this.setState({ [name]: Number(value) });
    }
  };

  render() {
    return (
      <div>
        <div className="slider-block">
          {/* --------------------------------------------------*/}
          {/* sliders for A and B coefficients */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 10 }}>
            <SliderEdit onChange={this.handleChange} params={sliderA} val={this.state.A} />
          </div>
        </div>
        {/* --------------------------------------------------*/}
        {/* -----------  ANIMATION / SPACE GRAPH  ------------*/}
        {/* --------------------------------------------------*/}
        <canvas ref="canvas" className="canvas" />
      </div>
    );
  }
}

export default ColorPlotExample;
