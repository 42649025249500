import { complex, abs, add, subtract, multiply, divide, pi } from "mathjs";
import { AirParameters } from "../../helpers/helpers";

// slider for each parameter
export const sliders = [
  {
    name: "Vmult",
    title: "Volume Vab",
    unit: "* Vas",
    min: 0.8,
    max: 1.2,
    step: 0.01,
    edit: false,
  },
  {
    name: "fmult",
    title: "fb",
    unit: "* fs",
    min: 0.8,
    max: 1.2,
    step: 0.01,
    edit: false,
  },
];

const Re = 6;
const Bl = 5.61;
const Mms = 6.7e-3;
const Rms = 0.35;
const Kms = 660;
const Sd = 138e-4;
const U0 = 2.83;

const rhoC2 = AirParameters.rho0 * Math.pow(346.1, 2);

const Vas = (rhoC2 * Math.pow(Sd, 2)) / Kms;

const fs = (1 / (2 * Math.PI)) * Math.sqrt(Kms / Mms);
const oms = 2 * Math.PI * fs;
const Qms = (2 * Math.PI * fs * Mms) / Rms;
const Qes = (2 * Math.PI * fs * Mms * Re) / Math.pow(Bl, 2);
const pg = (U0 * Bl) / Sd / Re;

const Rap = 0.000001; // to avoid singularity

const port_radius = 0.03;

export function getVolume(Vmult) {
  return Vmult * Vas;
}

export function getLength(Vmult, fmult) {
  let Sp = Math.PI * Math.pow(port_radius, 2);
  let lb = 0.85 * port_radius;
  let lf = 0.61 * port_radius;

  let fb = fmult * fs;
  let omb = 2 * Math.PI * fb;
  let Vab = Vmult * Vas;

  let L = (Math.pow(AirParameters.c0 / omb, 2) * Sp) / Vab;
  return L - lb - lf;
}

// calculate microphone sensitivity
export function sensitivity(Vmult, fmult, f_axis) {
  // prepare arrays for absVal and phase
  let Z_ebr = [];
  let Z_et = [];

  let Pd = [];
  let Pp = [];
  let Pb = [];

  for (let i = 0; i < f_axis.length; i++) {
    Z_ebr[i] = { x: f_axis[i] / fs, y: 0 };
    Z_et[i] = { x: f_axis[i] / fs, y: 0 };
    Pd[i] = { x: f_axis[i] / fs, y: 0 };
    Pp[i] = { x: f_axis[i] / fs, y: 0 };
    Pb[i] = { x: f_axis[i] / fs, y: 0 };
  }

  let b2 = fmult + (1 + 1 / Vmult) / fmult;
  let b3 = 1 / Qms / Math.sqrt(fmult);
  let Vab = Vmult * Vas;
  let fb = fmult * fs;
  let omb = 2 * Math.PI * fb;
  let om0 = Math.sqrt(oms * omb);
  let Cab = Vab / AirParameters.rho0 / Math.pow(AirParameters.c0, 2);
  let Rae = Math.pow(Bl, 2) / Re / Math.pow(Sd, 2);

  let Map = 1 / Cab / Math.pow(omb, 2);

  for (let i = 0; i < f_axis.length; i++) {
    // frequency axis
    let f = f_axis[i];
    let omega = 2 * pi * f;

    let omega_rel = omega / oms;
    let ombrel = omega / omb;
    let om0rel = omega / om0;

    let b1 = Math.sqrt(fmult) / Qms;

    //let Ze = complex(Re);

    let Zet = add(
      Re,
      divide(
        complex(0, (omega_rel * Re) / Qes),
        complex(1 - Math.pow(omega_rel, 2), omega_rel / Qms)
      )
    );

    let Zbr = add(
      Re,
      divide(
        complex(0, ((omega_rel * Re) / Qes) * (1 - Math.pow(ombrel, 2))),
        complex(
          Math.pow(om0rel, 4) - b2 * Math.pow(om0rel, 2) + 1,
          -b3 * Math.pow(om0rel, 3) + b1 * om0rel
        )
      )
    );
    Z_ebr[i].y = abs(Zbr);
    Z_et[i].y = abs(Zet);

    let Zms = complex(Rms, omega * Mms - Kms / omega);
    let Zas = divide(Zms, Math.pow(Sd, 2));
    let Zat = add(Rae, Zas);
    let Zab = complex(0, -1 / omega / Cab);
    let Zap = complex(Rap, omega * Map);

    let A1 = divide(Zab, add(Zap, Zab));
    let qd = divide(pg, add(Zat, multiply(Zap, A1)));
    let qp = multiply(qd, A1);
    let qb = subtract(qd, qp);

    let B0 = complex(0, (omega * AirParameters.rho0) / 2 / Math.PI);
    let pd = multiply(B0, qd);
    let pp = multiply(B0, qp);
    let pb = multiply(B0, qb);

    Pd[i].y = 20 * Math.log10(abs(divide(pd, 2e-5)));
    Pp[i].y = 20 * Math.log10(abs(divide(pp, 2e-5)));
    Pb[i].y = 20 * Math.log10(abs(divide(pb, 2e-5)));
  }

  return { Z_ebr, Z_et, Pd, Pp, Pb };
}
