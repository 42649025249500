import React, { Component } from "react";
import LineGraph from "../UI/ChartJS/LineGraph";
import PolarGraph from "../UI/ChartJS/PolarGraph";
import { LogSpace } from "../../helpers/helpers";
import { sensitivity, polarResponse } from "./MicrophoneCardio.helper";
import { COLORS } from "../../helpers/helpers";

class MicrophoneCardioGraph extends Component {
  render() {
    // List of inputs ("props") :
    // Vab ... back volume [m^3]
    let { Vab } = this.props;

    // frequency axis
    let f_ax = LogSpace(1, Math.log10(20000), 500);
    let db_line = [
      { x: 10, y: -3 },
      { x: 20e3, y: -3 },
    ];

    // abs and phase
    let { absVal, phase } = sensitivity(Vab, f_ax);
    let data = {
      title: "Microphone Sensitivity",
      xAxes: {
        xLabel: "Frequency [Hz]",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [absVal, db_line],
          yLabel: "Relative sensitivity [dB]",
          type: "linear",
          min: -25,
          max: 10,
          borderColor: [COLORS[0], COLORS[0]],
          lineWidth: [3, 1],
          borderDash: [undefined, [10, 5]],
        },
        {
          data: [phase],
          yLabel: "Phase [deg]",
          type: "linear",
          min: -180,
          max: 180,
          borderColor: [COLORS[1]],
        },
      ],
      animation: 0,
    };

    // polar graph
    let { graphData } = polarResponse(Vab);
    let dataPolar = {
      title: "Microphone directivity",
      data: [graphData],
      axis: { min: 0, max: 1.2 },
    };

    return (
      <div>
        <LineGraph data={data} graph_size_ratio={0.6} />
        <PolarGraph data={dataPolar} />
      </div>
    );
  }
}
export default MicrophoneCardioGraph;
