import React from "react";
import WaveGuide from "./WaveGuide.jsx";
import { SIGNAL_CHOICE } from "./helpers/WaveGuide.signals.js";
import { COLORS } from "../../helpers/helpers";
import {
  pressureFnc,
  velocityFnc,
  displacementFnc,
  pressureMinMaxFnc,
} from "./helpers/WaveGuide.mainEquations.js";

const props = {
  colormap: { name: "RdBu", unit: "Pa" },
  microphone: { show: true, x: 0.5 },
  signals: [
    {
      title: "Harmonic",
      type: SIGNAL_CHOICE.HARMONIC,
      freq: { value: 1200, can_be_modified_with_c0: true },
      A: { abs: 1.0, arg: 0.0, edit: true, arg_edit: true, inv_edit: false },
      B: { abs: 0.6, arg: 0.0, edit: true, arg_edit: true, inv_edit: false },
      graph_limits: { pmax: 2, vmax: 0.006, auto: false },
      standing_waves: true,
      drawMinMax: pressureMinMaxFnc,
    },
  ],
  physics: {
    pressureFnc: pressureFnc,
    velocityFnc: velocityFnc,
    displacementFnc: displacementFnc,
    drawFnc: pressureFnc,
    vectorFnc: velocityFnc,
    open_left: true,
    open_right: true,
    radius: { values: [1.0 / 8, 1.0 / 8], can_be_modified: false },
    rho: [1.2, 1.2],
    c0: [340, 340],
    medium: { edit: false, title: "medium: air" },
  },
  graphs: { space: true },
  pressure_colors: { can_be_modified: true, show: true },
  velocity_arrows: { can_be_modified: true, show: false, size_ratio: 6000, color: COLORS[1] },
};

export function WaveGuideLesson04() {
  return <WaveGuide {...props} />;
}
