import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import GeophoneGraph from "./Geophone.graph";
import { sliders, Fc, Q } from "./Geophone.helper";

//const MU = String.fromCharCode(956); // symbol for micro (mu)

// -------------------------
// Mechanical Mass Spring System:
// - Shows mechanical impedance or admittannce (amplitude and phase) of a mechanical system.
//   User can select between admittance and impedance,
//   and values of Rm ... mechanical resistance
//                 Mm ... moving mass
//                 Cm ... mechanical compliance
// -------------------------

class Geophone extends Component {
  // class state
  state = {
    Rl: 1200, // load resistance [ohm]
    Bl: 28, // force factor [Tm]
    Re: 395, // resistance [ohm]
    Le: 0.1, // inductance [mH]
    Rm: 0.4, // mechanical resistance [Ns/m]
    Mm: 11.0, // moving mass [g]
    Cm: 23, // mechanical compliance [mm/N]
    LinLog: "logarithmic", // "linear" or "logarithmic"
  };

  // handle for sliders
  handleChange = (name, value) => {
    this.setState({ [name]: Number(value) });
  };

  // handle for radio
  handleChangeRadio = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    let { Rl, Re, Le, Bl, Rm, Mm, Cm, LinLog } = this.state;

    return (
      <div>
        <div className="slider-block">
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            {sliders.map((slider, index) => {
              return (
                <SliderEdit
                  key={index}
                  onChange={this.handleChange}
                  params={slider}
                  val={this.state[slider.name]}
                />
              );
            })}
          </div>
        </div>
        {/* --------------------------------------------------*/}
        {/* text values (resonant frequency (fc) and quality factor (Q) ) */}
        {/* --------------------------------------------------*/}
        <p>
          fc ={" "}
          {Fc(Mm * 1e-3, Cm * 1e-3) // pass values from [g] to [kg]
            .toFixed(2) //                     and from [mm/N] to [m/N]
            .toString()}
          {" Hz, Q = "}
          {Q(Rl, Re, Bl, Rm, Mm * 1e-3, Cm * 1e-3)
            .toFixed(2)
            .toString()}
        </p>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        {/* */}
        {/* --------------------------------------------------*/}
        {/* radio buttons to choose between linear and logarithmic Yaxis of chart.js*/}
        {/* --------------------------------------------------*/}
        <div className="one-graph">
          <input
            type="radio"
            value="linear"
            name="LinLog"
            checked={LinLog === "linear"}
            onChange={this.handleChangeRadio}
          />
          linear
          <input
            type="radio"
            value="logarithmic"
            name="LinLog"
            checked={LinLog === "logarithmic"}
            onChange={this.handleChangeRadio}
          />
          logarithmic
          {/* --------------------------------------------------*/}
          {/* graph (chartjs)*/}
          {/* --------------------------------------------------*/}
          <GeophoneGraph
            LinLog={LinLog} // selector between "linear" and "logarithmic"
            Rl={Rl} // load resistance
            Re={Re} // el. resistance
            Le={Le * 1e-3} // el. inductance
            Bl={Bl} // intrinsic sensitivity
            Rm={Rm} // mech. resistance
            Mm={Mm * 1e-3} // mass, pass value from [g] to [kg]
            Cm={Cm * 1e-3} // compliance, pass value from [mm/N] to [m/N]
          />
        </div>
      </div>
    );
  }
}
export default Geophone;
