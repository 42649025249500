import React from "react";
import WaveGuide from "./WaveGuide.jsx";
import { SIGNAL_CHOICE } from "./helpers/WaveGuide.signals.js";
import { getWaveguideIndex } from "./helpers/WaveGuide.helper.js";
import { COLORS } from "../../helpers/helpers.js";
import dpdx from "./latex_svg/dpdx.svg";
import dpdx_units from "./latex_svg/dpdx_units.svg";
import drhodt from "./latex_svg/drhodt.svg";
import drhodt_units from "./latex_svg/drhodt_units.svg";
import {
  pressureFnc,
  velocityFnc,
  displacementFnc,
  pressureMinMaxFnc,
} from "./helpers/WaveGuide.mainEquations.js";

const props = {
  colormap: { name: "winter", unit: "Pa" },
  animation_rate: 5000,
  microphone: { show: false, x: 0.5 },
  signals: [
    {
      title: "Pulse",
      type: SIGNAL_CHOICE.PULSE,
      freq: { value: 800 },
      A: { abs: 1.0, arg: 0.0, edit: true, arg_edit: false, inv_edit: true },
      B: { abs: 0.0, arg: 0.0, edit: false, arg_edit: false, inv_edit: false },
      graph_limits: { pmax: 1.5, vmax: 0.003, auto: false },
      time_repeat: 3.5, // current time [ms]
      drawMinMax: pressureMinMaxFnc,
    },
    {
      title: "Burst",
      type: SIGNAL_CHOICE.BURST,
      freq: { value: 800 },
      A: { abs: 1.0, arg: 0.0, edit: true, arg_edit: false, inv_edit: true },
      B: { abs: 0.0, arg: 0.0, edit: false, arg_edit: false, inv_edit: false },
      graph_limits: { pmax: 1.5, vmax: 0.003, auto: false },
      time_repeat: 3.5, // current time [ms]
      drawMinMax: pressureMinMaxFnc,
    },
    {
      title: "Harmonic",
      type: SIGNAL_CHOICE.HARMONIC,
      freq: { value: 1000 },
      A: { abs: 1.0, arg: 0.0, edit: true, arg_edit: false, inv_edit: true },
      B: { abs: 0.0, arg: 0.0, edit: false, arg_edit: false, inv_edit: false },
      graph_limits: { pmax: 1.5, vmax: 0.003, auto: false },
      drawMinMax: pressureMinMaxFnc,
    },
  ],
  physics: {
    pressureFnc: pressureFnc,
    velocityFnc: velocityFnc,
    displacementFnc: displacementFnc,
    drawFnc: pressureFnc,
    vectorFnc: velocityFnc,
    open_left: true,
    open_right: true,
    radius: { values: [1.0 / 8, 1.0 / 8], can_be_modified: false },
    rho: [1.2, 1.2],
    c0: [340, 340],
    medium: { edit: false, title: "medium: air" },
  },
  graphs: { space: true },
  color_region: {
    xmin: 0.25,
    xmax: 0.3,
    box: false,
  },
  arrow_region: {
    xmin: 0.65,
    xmax: 0.7,
    box: true,
  },
  pressure_colors: { can_be_modified: false, show: true },
  velocity_arrows: { can_be_modified: false, show: true, size_ratio: 6000, color: COLORS[1] },
  text: [
    {
      left: "15%",
      variable: { img: dpdx },
      units: { img: dpdx_units },
      fnc: fnc_dp_dx,
    },
    {
      left: "52%",
      variable: { img: drhodt },
      units: { img: drhodt_units },
      fnc: fnc_drho_dt,
    },
  ],
};

function fnc_dp_dx(properties, t) {
  let { physics, signal, animation } = properties;
  let { xmin, xmax } = animation.color_region;
  let p1max = pressureFnc(signal, xmax, 1.0, t, physics, 0);
  let p1min = pressureFnc(signal, xmin, 1.0, t, physics, 0);
  return (p1max - p1min) / (xmax - xmin);
}

function fnc_drho_dt(properties, t) {
  let { physics, signal, animation } = properties;
  let { xmin, xmax } = animation.arrow_region;
  let v1max = velocityFnc(signal, xmax, 1.0, t, physics, 0);
  let v1min = velocityFnc(signal, xmin, 1.0, t, physics, 0);
  let x_interface = physics.reflection?.x ?? 0.5;
  return (
    (-1000 * physics.rho[getWaveguideIndex((xmin + xmax) / 2, x_interface)] * (v1max - v1min)) /
    (xmax - xmin)
  );
}

export function WaveGuideLesson02() {
  return <WaveGuide {...props} />;
}
