import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import Figure from "./figure.svg";
import ElectrostaticTransductionAudio from "./ElectrostaticTransduction.audio";
import ElectrostaticTransductionAudio2 from "./ElectrostaticTransduction.audio2";

// -------------------------
// ElectrostaticTransduction
// -------------------------

class ElectrostaticTransduction extends Component {
  state = {
    U0: 100, // amplitude of the input signal
  };

  // slider to change the amplitude of the input signal
  sliderU = {
    name: "U0",
    unit: "V",
    min: 0.01,
    max: 100,
    step: 0.1,
    edit: false,
    log: true,
  };

  // handle for slider
  handleSliderChange = (name, value) => {
    // check if user has entered a numeric value
    if (!isNaN(value)) {
      this.setState({ [name]: value });
    }
  };

  render() {
    let { U0 } = this.state;
    return (
      <div>
        <div className="slider-block">
          {/* --------------------------------------------------*/}
          {/* Slider to chnage volateg U0 */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            <SliderEdit onChange={this.handleSliderChange} params={this.sliderU} val={U0} />
          </div>
          <ElectrostaticTransductionAudio U0={U0} text="play music" />
          <ElectrostaticTransductionAudio2 U0={U0} text="play sine" />
        </div>

        {/* --------------------------------------------------*/}
        {/* ----------------  SVG FIGURE  --------------------*/}
        {/* --------------------------------------------------*/}
        <div style={{ margin: "auto", width: "50%" }}>
          <img src={Figure} alt="Figure" />
        </div>
      </div>
    );
  }
}

export default ElectrostaticTransduction;
