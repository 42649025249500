import React, { Component } from "react";
import { COLORS } from "../../helpers/helpers.js";

class MicrophoneElstatSVG extends Component {
  render() {
    // List of inputs ("props") :
    //        D  ... Diameter of the diaphragm [m]
    //        d0 ... Distance between electrodes at rest [m]
    //        U0 ... Bias voltage [V] (std 200 V)
    //        Ra ... Acoustic resistance of holes in the back electrode [kg.s-1.m-2]
    let { D, d0, U0, Ra } = this.props;
    let svgU0 = { x: 270 + D * 5, y: 120, r: 15 };
    let svgMembrane = { x: 200, y: 10, d: D * 5 };
    let svgGrid = { x: 200, y: 10 + d0 / 2, d: D * 5 };
    let height = 170;
    let grid_dash = String(5 + Math.log10(Ra)) + ", " + String(5 - Math.log10(Ra));
    let wires_shift = 5;
    let wires_corner = 30;
    let bottom_line = 170;
    let svgResistor = { x: 270 + D * 5, y: 60, w: 7, h: 30 };
    let svgCapacitor = { x: 320 + D * 5, y: 170, w: 5, h: 15 };
    let topWire = { y: 40 };

    let groud_wire_corner =
      String(svgGrid.x + svgGrid.d - wires_shift) + "," + String(bottom_line) + " ";
    let ground_wire =
      "M " +
      String(svgGrid.x + svgGrid.d - wires_shift) +
      "," +
      String(svgGrid.y) +
      " V " +
      String(150) +
      " C " +
      groud_wire_corner +
      groud_wire_corner +
      String(svgGrid.x + svgGrid.d - wires_shift + wires_corner) +
      "," +
      String(bottom_line) +
      " H" +
      String(svgCapacitor.x);

    let up_wire_corner = String(svgU0.x) + "," + String(svgMembrane.y) + " ";
    let up_wire =
      "M " +
      String(svgMembrane.x + svgMembrane.d - wires_shift) +
      "," +
      String(svgMembrane.y) +
      " H " +
      String(svgU0.x - wires_corner) +
      " C " +
      up_wire_corner +
      up_wire_corner +
      String(svgU0.x) +
      "," +
      String(svgMembrane.y + wires_corner) +
      " " +
      " V " +
      String(svgU0.y - svgU0.r);

    let arrow_path_U =
      "M " +
      String(svgU0.x + 100) +
      "," +
      String(topWire.y + 20) +
      " V " +
      String(bottom_line - 20);

    let arrow_path_U0 =
      "M " + String(svgU0.x + 25) + "," + String(svgU0.y - 20) + " V " + String(bottom_line - 20);

    return (
      <svg viewBox="0 0 500 200">
        {/*-- arrow head --*/}
        <defs>
          <marker id="head" orient="auto" markerWidth="4" markerHeight="8" refX="4" refY="4">
            <path d="M 0,0 V8 L4,4 Z" fill={COLORS[3]} />
          </marker>
        </defs>

        {/*-- source U0 --*/}
        <circle
          cx={svgU0.x}
          cy={svgU0.y}
          r={svgU0.r}
          stroke={COLORS[3]}
          strokeWidth="2"
          fill="white"
        />
        <text
          x={svgU0.x}
          y={svgU0.y + 1}
          alignmentBaseline="middle"
          textAnchor="middle"
          fontSize="0.8em"
          fill={COLORS[3]}
        >
          U0
        </text>
        <text
          x={svgU0.x + svgU0.r + 15}
          y={svgU0.y + 1}
          alignmentBaseline="middle"
          fontSize="0.8em"
        >
          {U0} V
        </text>
        {/*-- membrane + electrode --*/}
        <rect
          x={svgMembrane.x - svgMembrane.d}
          y={svgMembrane.y}
          width={2 * svgMembrane.d}
          height={height}
          style={{ stroke: COLORS[0], fill: "white", strokeWidth: 2 }}
        />
        {/*-- grid --*/}
        <line
          x1={svgGrid.x + svgGrid.d}
          y1={svgGrid.y}
          x2={svgGrid.x - svgGrid.d}
          y2={svgGrid.y}
          strokeWidth="2"
          stroke={COLORS[0]}
          strokeDasharray={grid_dash}
        />
        {/*-- ground --*/}
        <path d={ground_wire} strokeWidth="2" fill="none" stroke={COLORS[3]} />
        <line
          x1={svgCapacitor.x + svgCapacitor.w}
          y1={svgCapacitor.y}
          x2={svgU0.x + 100}
          y2={svgCapacitor.y}
          strokeWidth="2"
          stroke={COLORS[3]}
        />
        <circle cx={svgGrid.x + svgGrid.d - wires_shift} cy={svgGrid.y} r="2" fill={COLORS[3]} />
        <line
          x1={svgU0.x}
          y1={bottom_line}
          x2={svgU0.x}
          y2={svgU0.y + svgU0.r}
          strokeWidth="2"
          stroke={COLORS[3]}
        />

        <circle cx={svgU0.x} cy={bottom_line} r="2" fill={COLORS[3]} />
        {/*-- up wire --*/}
        <path d={up_wire} strokeWidth="2" fill="none" stroke={COLORS[3]} />
        <circle
          cx={svgMembrane.x + svgMembrane.d - wires_shift}
          cy={svgMembrane.y}
          r="2"
          fill={COLORS[3]}
        />
        {/*-- resistor --*/}
        <rect
          x={svgResistor.x - svgResistor.w}
          y={svgResistor.y}
          width={2 * svgResistor.w}
          height={svgResistor.h}
          style={{ stroke: COLORS[3], fill: "white", strokeWidth: 2 }}
        />

        {/*-- top wire --*/}
        <circle cx={svgU0.x} cy={svgCapacitor.y} r="2" fill={COLORS[3]} />
        <line
          x1={svgU0.x}
          y1={topWire.y}
          x2={svgU0.x + 100}
          y2={topWire.y}
          strokeWidth="2"
          stroke={COLORS[3]}
        />

        <line
          x1={svgCapacitor.x}
          y1={svgCapacitor.y - svgCapacitor.h / 2}
          x2={svgCapacitor.x}
          y2={svgCapacitor.y + svgCapacitor.h / 2}
          strokeWidth="2"
          stroke={COLORS[3]}
        />
        <line
          x1={svgCapacitor.x + svgCapacitor.w}
          y1={svgCapacitor.y - svgCapacitor.h / 2}
          x2={svgCapacitor.x + svgCapacitor.w}
          y2={svgCapacitor.y + svgCapacitor.h / 2}
          strokeWidth="2"
          stroke={COLORS[3]}
        />

        {/*-- out voltage --*/}
        <path
          id="arrow-line"
          markerEnd="url(#head)"
          strokeWidth="2"
          fill="none"
          stroke={COLORS[3]}
          d={arrow_path_U}
        />
        <text
          x={svgU0.x + 110}
          y={(topWire.y + bottom_line) / 2}
          alignmentBaseline="middle"
          textAnchor="middle"
          fill={COLORS[3]}
        >
          U
        </text>

        {/*-- U0 voltage --*/}
        <path
          id="arrow-line"
          markerEnd="url(#head)"
          strokeWidth="2"
          fill="none"
          stroke={COLORS[3]}
          d={arrow_path_U0}
        />
      </svg>
    );
  }
}
export default MicrophoneElstatSVG;
