import React, { Component } from "react";
import { COLORS } from "../../../helpers/helpers";

import Chart from "chart.js";
import Controller from "./HalfPolarGraph.controller";
import Scale, { defaults } from "./HalfPolarGraph.scale";

// Register the Controller and Scale
Chart.controllers.halfPolar = Controller;
Chart.defaults.halfPolar = {
  aspectRatio: 1.5,
  scale: {
    type: "halfPolar",
  },
};
Chart.scaleService.registerScaleType("halfPolar", Scale, defaults);

class HalfPolarGraph extends Component {
  myChart = {};
  chartRef = React.createRef();

  componentDidMount() {
    this.myChart = new Chart(
      this.chartRef.current.getContext("2d"), // context
      this.createChartContext() // what to render
    );
  }

  componentDidUpdate() {
    // update chart data for each line in the graph
    this.myChart.data.datasets = this.props.data.data.map((d, idx) => {
      return {
        data: d,
        pointRadius: this.props.data.pointRadius?.[idx] ?? 0,
        borderColor: this.props.data.color?.[idx] ?? COLORS[idx],
        pointBorderColor: this.props.data.color?.[idx] ?? COLORS[idx],
        pointBackgroundColor: this.props.data.color?.[idx] ?? COLORS[idx],
      };
    });

    this.myChart.options.scale.ticks.min = this.props.data.axis.min;
    this.myChart.options.scale.ticks.max = this.props.data.axis.max;
    this.myChart.options.scale.ticks.step = this.props.data.axis.step;
    this.myChart.update();
  }

  render() {
    return (
      <div>
        <canvas id="myChart" ref={this.chartRef} />
      </div>
    );
  }

  createChartContext = () => {
    let { axis, data } = this.props.data;

    return {
      type: "halfPolar",
      data: {
        datasets: data.map((d, idx) => {
          return {
            data: d,
            pointRadius: this.props.data.pointRadius?.[idx] ?? 0,
            label: this.props.data.legend !== undefined ? this.props.data.legend[idx] : "",
            borderColor: this.props.data.color?.[idx] ?? COLORS[idx],
            pointBorderColor: this.props.data.color?.[idx] ?? COLORS[idx],
            pointBackgroundColor: this.props.data.color?.[idx] ?? COLORS[idx],
            borderWidth: this.props.data.borderWidth?.[idx] ?? 3,
          };
        }),
      },
      options: {
        tooltips: { enabled: false },
        hover: { mode: null },
        title: {
          display: true,
          text: this.props.data.title,
        },
        legend: {
          display: false,
        },
        scale: {
          ticks: {
            min: axis.min,
            max: axis.max,
          },
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
      },
    };
  };
}

export default HalfPolarGraph;
