import React, { Component } from "react";
import LineGraph from "../UI/ChartJS/LineGraph";
import LineGraphDrag from "../UI/ChartJS/LineGraphDrag";
import FIRAudio from "./FIR.audio";
import { prepareData, fft_mag_phase } from "./FIR.helper";
import "chartjs-plugin-dragdata";

class FIRGraph extends Component {
  state = {
    showPhase: false,
    semilogx: false,
    impulsResponse: prepareData(this.props.Npts),
  };

  value_change = (e, datasetIndex, index, value) => {
    // create a clone of the state
    let new_impulsResponse = this.state.impulsResponse.slice();
    // modify the index
    new_impulsResponse[index] = value;
    // save new state
    this.setState({ impulsResponse: new_impulsResponse });
  };

  // handles for Restart and Pause
  handleReset = (event) => {
    this.setState({ impulsResponse: prepareData(this.props.Npts) });
  };

  handleShowPhase = (event) => {
    this.setState((prevState) => ({
      showPhase: !prevState.showPhase,
    }));
  };

  handleSemilogx = (event) => {
    this.setState((prevState) => ({
      semilogx: !prevState.semilogx,
    }));
  };

  render() {
    // List of inputs ("props") :
    let { Ymag, Yphase } = fft_mag_phase(this.state.impulsResponse, this.state.showPhase);

    let signalData = {
      title: "Impulse Response",
      xAxes: {
        xLabel: "Samples [-]",
        type: "linear",
      },
      yAxes: [
        {
          data: [this.state.impulsResponse],
          min: -1.5,
          max: 1.5,
          type: "linear",
          pointRadius: [5],
        },
      ],
    };

    let fftData = {
      title: "Frequency Response Function",
      xAxes: {
        xLabel: this.state.semilogx ? "bla" : "Normalized Frequency [-]",
        type: this.state.semilogx ? "logarithmic" : "linear",
      },
      yAxes: [
        {
          data: [Ymag],
          yLabel: "Absolute value",
          type: "linear",
          min: -50,
          max: 10,
        },
        {
          data: [Yphase],
          yLabel: "Phase [deg]",
          type: "linear",
          min: -180,
          max: 180,
        },
      ],
    };

    return (
      <div>
        <div className="slider-block">
          <button onClick={this.handleReset}>reset</button>
          <p>
            show phase
            <input
              type="checkbox"
              defaultChecked={this.state.showPhase}
              onChange={this.handleShowPhase}
            />
          </p>
        </div>
        <FIRAudio IR={this.state.impulsResponse} />
        <div className="two-graphs">
          <LineGraphDrag data={signalData} func={this.value_change} graph_size_ratio={0.6} />
        </div>
        <div className="two-graphs">
          <LineGraph data={fftData} func={this.value_change} graph_size_ratio={0.6} />
        </div>
        <div className="clr" />
        <p style={{ fontSize: "80%" }}>FFT is calculated using 4096 samples (zero padding).</p>
      </div>
    );
  }
}

export default FIRGraph;
