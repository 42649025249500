import React, { Component } from "react";
import { fnc } from "./ElectrostaticTransduction.helper.js";

// Component used to play audio
// props:   freq ... frequency [Hz]
//          osc  ... array of oscillators, each oscillator has: { A, init_phase, last_phase}

const BUFFER_SIZE = 4096;

class ElectrostaticTransductionAudio extends Component {
  state = {
    is_playing: false, // BOOL (audio is/(is not) playing)
  };

  audioContext = null;
  file_buffer = null;

  loadAudioSource() {
    // source -> script node (gainNode) -> output (audioContext.destination)
    // audio context
    let AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioContext = new AudioContext();

    // source node
    this.source_node = this.audioContext.createBufferSource();

    window
      .fetch("/comis.wav")
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) =>
        this.audioContext.decodeAudioData(
          arrayBuffer,
          (audioBuffer) => {
            this.source_node.buffer = audioBuffer;
            this.source_node.loop = true;
            this.source_node.start();
          },
          (error) => console.error(error)
        )
      );

    // script node
    this.scriptNode = this.audioContext.createScriptProcessor(BUFFER_SIZE, 1, 1);

    this.source_node.connect(this.scriptNode);
  }

  componentDidUpdate() {
    // extract props from parrent
    let { U0 } = this.props;

    if (this.audioContext !== null) {
      // on change any of the properties check if audio is on (checkbox)
      if (this.state.is_playing === true) {
        this.scriptNode.onaudioprocess = function (audioProcessingEvent) {
          const inputBuffer = audioProcessingEvent.inputBuffer;
          const outputBuffer = audioProcessingEvent.outputBuffer;
          const N_channels = outputBuffer.numberOfChannels;

          // go through each output channel
          for (let channel = 0; channel < N_channels; channel++) {
            let inputData = inputBuffer.getChannelData(channel);
            let outputData = outputBuffer.getChannelData(channel);

            // sample-by-sample processing
            for (let sample = 0; sample < outputBuffer.length; sample++) {
              outputData[sample] = fnc(inputData[sample], U0);
            }
          }
        };

        // connect the scriptNode to the destination (it will start playing)
        this.scriptNode.connect(this.audioContext.destination);
      } else {
        // disconnect the oscilator from the gain (it will stop playing)
        this.scriptNode.disconnect();
      }
    }
  }

  // when unmounting close the audio context
  componentWillUnmount() {
    if (this.audioContext !== null) {
      this.audioContext.close();
    }
  }

  // handle for checkbox audio playing
  handleChangePlay = () => {
    // check if audio context exists, if no, create the audio chain
    if (this.audioContext === null) {
      this.loadAudioSource();
    }
    // change the flag "is_playing"
    this.setState((prevState) => ({
      is_playing: !prevState.is_playing,
    }));
  };

  render() {
    return (
      <div>
        {this.props.text}
        <input
          type="checkbox"
          defaultChecked={this.state.is_playing}
          onChange={this.handleChangePlay}
        />
      </div>
    );
  }
}

export default ElectrostaticTransductionAudio;
