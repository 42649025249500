import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

import Navbar from "./components/UI/Navbar.jsx";

import SimpleSinus from "./components/SimpleSinus/SimpleSinus.jsx";
import Fourier from "./components/Fourier/Fourier.jsx";
import Geophone from "./components/Geophone/Geophone.jsx";
import MicrophoneEldyn from "./components/MicrophoneEldyn/MicrophoneEldyn.jsx";
import MicrophoneCardio from "./components/MicrophoneCardio/MicrophoneCardio.jsx";
import MicrophoneElstat from "./components/MicrophoneElstat/MicrophoneElstat.jsx";
import ElectrostaticTransduction from "./components/ElectrostaticTransduction/ElectrostaticTransduction.jsx";
import MechanicalSystem from "./components/MechanicalSystem/MechanicalSystem.jsx";
import AnalogFilters from "./components/AnalogFilters/AnalogFilters.jsx";
import Distortion from "./components/Distortion/Distortion.jsx";
import TSLoudspeaker from "./components/TSLoudspeaker/TSLoudspeaker.jsx";
import ClosedBoxSystem from "./components/ClosedBoxSystem/ClosedBoxSystem.jsx";
import VentedBoxSystem from "./components/VentedBoxSystem/VentedBoxSystem.jsx";
import PassiveRadiator from "./components/PassiveRadiator/PassiveRadiator.jsx";
import PistonDirectivity from "./components/PistonDirectivity/PistonDirectivity.jsx";
import MicrophoneArray from "./components/MicrophoneArray/MicrophoneArray.jsx";
import AdvancedLoudspeaker from "./components/AdvancedLoudspeaker/AdvancedLoudspeaker.jsx";
import { WaveGuideLesson02 } from "./components/WaveGuide/WaveGuideLesson02.jsx";
import { WaveGuideLesson03 } from "./components/WaveGuide/WaveGuideLesson03.jsx";
import { WaveGuideLesson04 } from "./components/WaveGuide/WaveGuideLesson04.jsx";
import { WaveGuideLesson05 } from "./components/WaveGuide/WaveGuideLesson05.jsx";
import { WaveGuideLesson06 } from "./components/WaveGuide/WaveGuideLesson06.jsx";
import { WaveGuideLesson07 } from "./components/WaveGuide/WaveGuideLesson07.jsx";
import { WaveGuideLesson08 } from "./components/WaveGuide/WaveGuideLesson08.jsx";
import { WaveGuideLesson09 } from "./components/WaveGuide/WaveGuideLesson09.jsx";
import WaveGuideHelmholtz from "./components/WaveGuideHelmholtz/WaveGuideHelmholtz.jsx";
import BandPass from "./components/BandPass/BandPass.jsx";
import EddyCurrents from "./components/EddyCurrents/EddyCurrents.jsx";
import MicrophoneDirectivity from "./components/MicrophoneDirectivity/MicrophoneDirectivity.jsx";
import NonlinearLoudspeaker from "./components/NonlinearLoudspeaker/NonlinearLoudspeaker.jsx";
import FIR from "./components/FIR/FIR.jsx";
import ColorPlotExample from "./components/ColorPlotExample/ColorPlotExample.jsx";
import SoundZones from "./components/SoundZones/SoundZones.jsx";
import AddedMass from "./components/AddedMass/AddedMass.jsx";
import BlockedImpedance from "./components/BlockedImpedance/BlockedImpedance.jsx";
import PressureBasics from "./components/PressureBasics/PressureBasics.jsx";

class App extends Component {
  render() {
    return (
      <Router>
        <div className="app">
          <Navbar />

          <div className="my-div">
            <Switch>
              <Route exact path="/">
                <h3>Sine-wave simulation</h3>
                <SimpleSinus />
              </Route>
              <Route exact path="/Sinus">
                <h3>Sine-wave simulation</h3>
                <SimpleSinus />
              </Route>
              <Route exact path="/Fourier">
                <h3>Fourier</h3>
                <Fourier />
              </Route>
              <Route exact path="/MechanicalSystem">
                <h3>Mechanical System</h3>
                <MechanicalSystem />
              </Route>
              <Route path="/Distortion">
                <h3>Distortion</h3>
                <Distortion />
              </Route>
              <Route path="/TSLoudspeaker">
                <h3>Electrodynamic moving-coil loudspeaker</h3>
                <TSLoudspeaker />
              </Route>
              <Route path="/ClosedBoxSystem">
                <h3>Closed-box System </h3>
                <ClosedBoxSystem />
              </Route>
              <Route path="/AnalogFilters">
                <h3>Analog Filters </h3>
                <AnalogFilters />
              </Route>
              <Route path="/VentedBoxSystem">
                <h3>Vented-box System </h3>
                <VentedBoxSystem />
              </Route>
              <Route path="/PassiveRadiator">
                <h3>Passive Radiator </h3>
                <PassiveRadiator />
              </Route>
              <Route path="/MicrophoneArray">
                <h3>Microphone line array </h3>
                <MicrophoneArray />
              </Route>
              <Route path="/PistonDirectivity">
                <h3>Piston mounted in an infinite baffle </h3>
                <PistonDirectivity />
              </Route>
              <Route path="/AdvancedLoudspeaker">
                <h3>Advanced Loudspeaker Parameters Estimation </h3>
                <AdvancedLoudspeaker />
              </Route>
              <Route path="/WaveGuide02">
                <h3>Wave Guide (Lesson 1.2) </h3>
                <WaveGuideLesson02 />
              </Route>
              <Route path="/WaveGuide03">
                <h3>Wave Guide (Lesson 1.3) </h3>
                <WaveGuideLesson03 />
              </Route>
              <Route path="/WaveGuide04">
                <h3>Wave Guide (Lesson 1.4) </h3>
                <WaveGuideLesson04 />
              </Route>
              <Route path="/WaveGuide05">
                <h3>Wave Guide (Lesson 1.5) </h3>
                <WaveGuideLesson05 />
              </Route>
              <Route path="/WaveGuide06">
                <h3>Wave Guide (Lesson 1.6) </h3>
                <WaveGuideLesson06 />
              </Route>
              <Route path="/WaveGuide07">
                <h3>Wave Guide (Lesson 1.7) </h3>
                <WaveGuideLesson07 />
              </Route>
              <Route path="/WaveGuide08">
                <h3>Wave Guide (Lesson 1.8) </h3>
                <WaveGuideLesson08 />
              </Route>
              <Route path="/WaveGuide09">
                <h3>Wave Guide (Lesson 1.9) </h3>
                <WaveGuideLesson09 />
              </Route>
              <Route path="/WaveGuideHelmholtz">
                <h3>Wave Guide Helmholtz </h3>
                <WaveGuideHelmholtz />
              </Route>
              <Route path="/BandPass">
                <h3>Band Pass Filter</h3>
                <BandPass />
              </Route>
              <Route path="/Geophone">
                <h3>Geophone</h3>
                <Geophone />
              </Route>
              <Route path="/MicrophoneEldyn">
                <h3>Electrodynamic microphone</h3>
                <MicrophoneEldyn />
              </Route>
              <Route path="/MicrophoneCardio">
                <h3>Cardioid microphone</h3>
                <MicrophoneCardio />
              </Route>
              <Route path="/ElectrostaticTransduction">
                <h3>Electrostatic transduction</h3>
                <ElectrostaticTransduction />
              </Route>
              <Route path="/MicrophoneElstat">
                <h3>Electrostatic microphone</h3>
                <MicrophoneElstat />
              </Route>
              <Route path="/EddyCurrents">
                <h3>Eddy Currents</h3>
                <EddyCurrents />
              </Route>
              <Route path="/NonlinearLoudspeakerBl">
                <h3>Loudspeaker Bl(x)</h3>
                <NonlinearLoudspeaker />
              </Route>
              <Route exact path="/FIR">
                <h3>FIR</h3>
                <FIR />
              </Route>
              <Route path="/MicrophoneDirectivity">
                <h3>Microphone Directivity</h3>
                <MicrophoneDirectivity />
              </Route>
              <Route exact path="/ColorPlotExample">
                <h3>Color Plot Example</h3>
                <ColorPlotExample />
              </Route>
              <Route exact path="/SoundZones">
                <h3>Sound Zones</h3>
                <SoundZones />
              </Route>
              <Route exact path="/AddedMass">
                <h3>Measurement: Added Mass</h3>
                <AddedMass />
              </Route>
              <Route exact path="/BlockedImpedance">
                <h3>Measurement: Blocked Impedance</h3>
                <BlockedImpedance />
              </Route>
              <Route exact path="/PressureBasics">
                <h3>Basics of Acoustic Pressure</h3>
                <PressureBasics />
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
