import React, { Component } from "react";
import LineGraph from "../UI/ChartJS/LineGraph";
import { calculateFilters } from "./AnalogFilters.helper";
import { MatlabData, COLORS } from "../../helpers/helpers";

class AnalogFiltersGraph extends Component {
  render() {
    // List of inputs ("props") :
    //   Rm [Ns/m] ... mechanical resistance
    //   Mm [kg] ... moving mass
    //   C [m/N] ... compliance
    //   LinLog ... "linear" or "logarithmic"
    //   AdmImp ... "admittance" or "impedance"
    let { fc, Rl, polarity } = this.props;

    let dataMatlab = MatlabData(require("./loudspeaker_data.json"));
    let { wooferFiltered, tweeterFiltered, sumOfSpeakers } = calculateFilters(
      fc,
      Rl,
      polarity,
      dataMatlab
    );

    // get Sound presseure levels with filters
    //let { Zall } = calculateFilters();

    let data = {
      xAxes: {
        xLabel: "Frequency [Hz]",
        type: "logarithmic",
        min: 50,
        max: 20000,
      },
      yAxes: [
        {
          data: [wooferFiltered, tweeterFiltered, sumOfSpeakers],
          borderColor: [COLORS[0], COLORS[1], COLORS[3]],
          backgroundColor: [COLORS[0], COLORS[1], COLORS[3]],
          label: ["woofer", "tweeter", "sum"],
          yLabel: "Sound pressure level [dB SPL]",
          min: 20,
          max: 100,
        },
      ],
      animation: 0,
    };

    return <LineGraph data={data} graph_size_ratio={0.6} />;
  }
}
export default AnalogFiltersGraph;
