import React, { Component } from "react";
import { ColorPlot, ZonePoints, Sources, ColorBar, Legend } from "./SoundZones.canvas.js";
import { calculate_colorplot, createGraphProperties, GRAPH_RATIO } from "./SoundZones.helper.js";
import { COLORS } from "../../helpers/helpers";
import "./SoundZones.css";

class SoundZones extends Component {
  constructor() {
    super();
    this.graph = createGraphProperties({
      x_min_step_max: [-2.1, 0.02, 2.1],
      y_min_step_max: [-2.1, 0.02, 2.1],
      z_min_max: [75, 115],
    });

    this.state = {
      frequency: 300.0,
      N_sources: 10,
    };

    this.frequency_options = [
      { title: "300 Hz", value: 300.0 },
      { title: "600 Hz", value: 600.0 },
      { title: "1 kHz", value: 1000.0 },
    ];

    this.n_sources_options = [
      { title: "10", value: 10 },
      { title: "15", value: 15 },
      { title: "20", value: 20 },
      { title: "25", value: 25 },
    ];

    // Source geometry
    this.updateSources();
    this.darkzone = new ZonePoints(3, 0.1, { x: -0.4, y: 0.6 }, { line: "black", bcg: "white" }); // (Number of pts 1D, spacing, position, circle colors)
    this.brightzone = new ZonePoints(3, 0.1, { x: 0.6, y: -0.5 }, { line: "white", bcg: "black" }); // (Number of pts 1D, spacing, position, circle colors)
    this.myColorPlot = new ColorPlot(this.sources);
    this.colorBar = new ColorBar();
    this.legend = new Legend();
  }

  componentDidMount() {
    this.ctx = this.refs.canvas.getContext("2d");
    this.updateCanvas();
  }

  componentDidUpdate() {
    this.updateCanvas();
  }

  updateSources() {
    this.sources = new Sources(2, this.state.N_sources, { line: "black", bcg: COLORS[1] }); // (Radius [m], Number of sources, circle colors)
  }

  updateCanvas() {
    // update graph
    this.updateSources();

    calculate_colorplot(this.graph, this.state, this.sources);

    // update canvas width (reactive)
    this.refs.canvas.style.width = "100%";
    this.refs.canvas.width = this.refs.canvas.offsetWidth;
    this.refs.canvas.height = Math.round(GRAPH_RATIO * this.refs.canvas.offsetWidth);
    this.colorBar.colormap.updateColorGradient(this.ctx, this.graph);

    // clear the space for canvas
    this.ctx.clearRect(0, 0, this.refs.canvas.width, this.refs.canvas.height);
    // draw on it
    this.myColorPlot.draw(this.ctx, this.graph);
    this.sources.draw(this.ctx, this.graph);
    this.darkzone.draw(this.ctx, this.graph);
    this.brightzone.draw(this.ctx, this.graph);
    this.brightzone.draw(this.ctx, this.graph);
    this.colorBar.draw(this.ctx, this.graph);
    this.legend.draw(this.ctx, this.graph);
  }

  // handle for sliders
  handleChangeNSources = (e) => {
    // check if user has entered a numeric value
    this.setState({ N_sources: Number(e.target.value) });
  };

  // handle for sliders
  handleChangeFrequency = (e) => {
    // check if user has entered a numeric value
    this.setState({ frequency: Number(e.target.value) });
  };

  render() {
    return (
      <>
        <div style={{ margin: "10px" }}>
          {/* --------------------------------------------------*/}
          {/* select number of sources */}
          {/* --------------------------------------------------*/}
          number of sources{" "}
          <select onChange={this.handleChangeNSources}>
            {this.n_sources_options.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.title}
                </option>
              );
            })}
          </select>
        </div>
        <div style={{ margin: "10px" }}>
          {/* --------------------------------------------------*/}
          {/* slect frequencies */}
          {/* --------------------------------------------------*/}
          frequency{" "}
          <select onChange={this.handleChangeFrequency}>
            {this.frequency_options.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.title}
                </option>
              );
            })}
          </select>
        </div>
        {/* --------------------------------------------------*/}
        {/* -----------  ANIMATION / SPACE GRAPH  ------------*/}
        {/* --------------------------------------------------*/}
        <div className="canvas-div">
          <canvas ref="canvas" className="canvas" />
        </div>
      </>
    );
  }
}

export default SoundZones;
