import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import MicrophoneElstatGraph from "./MicrophoneElstat.graph";
import MicrophoneElstatSVG from "./MicrophoneElstat.svg";

import { sliders } from "./MicrophoneElstat.helper";

// -------------------------
// Response of an electrostatic microphone
// - Simplified version, the back of the membrane is modeled by an acoustic impedance Za
//        D  ... Diameter of the diaphragm [m]
//        d0 ... Distance between electrodes at rest [m]
//        nu ... stretching force per unit length [N.m-1]
//        U0 ... Bias voltage [V] (std 200 V)
//        Ra ... Acoustic resistance of holes in the back electrode [kg.s-1.m-2]
// -------------------------

class MicrophoneElstat extends Component {
  // class state
  state = {
    D: 6.35, // Diameter of the diaphragm [mm]
    d0: 13, // Distance between electrodes at rest [um]
    nu: 3162, // stretching force per unit length [N.m-1]
    U0: 200, // Bias voltage [V]
    Ra: 1, // Acoustic resistance of holes in the back electrode [kg.s-1.m-2]
  };

  // handle for sliders
  handleChange = (name, value) => {
    this.setState({ [name]: Number(value) });
  };

  render() {
    let { D, d0, nu, U0, Ra } = this.state;

    return (
      <div>
        <div className="slider-block">
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            {sliders.map((slider, index) => {
              return (
                <SliderEdit
                  key={index}
                  onChange={this.handleChange}
                  params={slider}
                  val={this.state[slider.name]}
                />
              );
            })}
          </div>
        </div>
        {/* --------------------------------------------------*/}
        {/* ----------------  SVG DRAWING  -------------------*/}
        {/* --------------------------------------------------*/}
        <div style={{ margin: "auto", width: "50%" }}>
          <MicrophoneElstatSVG
            D={D} // Diameter of the diaphragm
            d0={d0} // Distance between electrodes at rest
            nu={nu} // stretching force per unit length
            U0={U0} // Bias voltage
            Ra={Ra} // Acoustic resistance of holes
          />
        </div>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        <div className="one-graph">
          {/* --------------------------------------------------*/}
          {/* graph (chartjs)*/}
          {/* --------------------------------------------------*/}
          <MicrophoneElstatGraph
            D={D * 1e-3} // Diameter of the diaphragm, pass value from [mm] to [m]
            d0={d0 * 1e-6} // Distance between electrodes at rest, pass value from [um] to [m]
            nu={nu} // stretching force per unit length [N.m-1]
            U0={U0} // Bias voltage [V]
            Ra={Ra * 1e6} // Acoustic resistance of holes in the back electrode [kg.s-1.m-2]
          />
        </div>
      </div>
    );
  }
}
export default MicrophoneElstat;
