import { complex, abs, add, subtract, multiply, divide, pi } from "mathjs";
import { AirParameters } from "../../helpers/helpers";

// slider for each parameter
export const sliders = [
  {
    name: "fmult",
    title: "fp",
    unit: "* fs",
    min: 0.5,
    max: 1.5,
    step: 0.01,
    edit: false,
  },
  {
    name: "Rmult",
    title: "Rmp",
    unit: "* Rms",
    min: 0.01,
    max: 2,
    step: 0.01,
    edit: false,
  },
];

const Re = 6;
const Le = 0.01;
const Bl = 4.58;
const Mms = 6.69e-3;
const Rms = 0.35;
const Kms = 660;
const Sd = 138e-4;
const U0 = 2.83;

const Vmult = 0.77;

const Cms = 1 / Kms;
const Cmp = Cms;

const rhoC2 = AirParameters.rho0 * Math.pow(346.1, 2);

const Vas = (rhoC2 * Math.pow(Sd, 2)) / Kms;

const fs = (1 / (2 * Math.PI)) * Math.sqrt(Kms / Mms);
const pg = (U0 * Bl) / Sd / Re;

// calculate microphone sensitivity
export function sensitivity(Rmult, fmult, f_axis) {
  // prepare arrays for absVal and phase
  let Z_pr = [];
  let Z_et = [];

  let Pd = [];
  let Pp = [];
  let Pb = [];

  for (let i = 0; i < f_axis.length; i++) {
    Z_pr[i] = { x: f_axis[i] / fs, y: 0 };
    Z_et[i] = { x: f_axis[i] / fs, y: 0 };
    Pd[i] = { x: f_axis[i] / fs, y: 0 };
    Pp[i] = { x: f_axis[i] / fs, y: 0 };
    Pb[i] = { x: f_axis[i] / fs, y: 0 };
  }

  let Rmp = Rmult * Rms;
  let Vab = Vmult * Vas;
  let fp = fmult * fs;
  let omp = 2 * Math.PI * fp;
  let Cap = Cms * Sd * Sd;
  let Rae = Math.pow(Bl, 2) / Re / Math.pow(Sd, 2);
  let Cab = Vab / AirParameters.rho0 / Math.pow(AirParameters.c0, 2);

  let Mmp = 1 / (4 * Math.pow(Math.PI * fp, 2) * Cmp);
  let Map = 1 / Cap / Math.pow(omp, 2);
  let Rap = Rmp / (Sd * Sd);

  for (let i = 0; i < f_axis.length; i++) {
    // frequency axis
    let f = f_axis[i];
    let omega = 2 * pi * f;

    let Zms = complex(Rms, omega * Mms - Kms / omega);
    let Zmp = complex(Rmp, omega * Mmp - 1 / (Cmp * omega));
    let Zas = divide(Zms, Math.pow(Sd, 2));
    let Zat = add(Rae, Zas);
    let Zab = complex(0, -1 / omega / Cab);
    let Zap = complex(Rap, omega * Map - 1 / (omega * Cap));

    let Zmb = multiply(Zab, Math.pow(Sd, 2));

    let Ze = complex(Re, omega * Le);

    let Zet = add(Ze, divide(Bl * Bl, Zms));

    let P1 = divide(multiply(Zmp, Zmb), add(Zmp, Zmb));
    let Zem = divide(Bl * Bl, add(Zms, P1));
    let Zpr = add(Ze, Zem);

    Z_pr[i].y = abs(Zpr);
    Z_et[i].y = abs(Zet);

    let A1 = divide(Zab, add(Zap, Zab));
    let qd = divide(pg, add(Zat, multiply(Zap, A1)));
    let qp = multiply(qd, A1);
    let qb = subtract(qd, qp);

    let B0 = complex(0, (omega * AirParameters.rho0) / 2 / Math.PI);
    let pd = multiply(B0, qd);
    let pp = multiply(-1, B0, qp);
    let pb = multiply(B0, qb);

    Pd[i].y = 20 * Math.log10(abs(divide(pd, 2e-5)));
    Pp[i].y = 20 * Math.log10(abs(divide(pp, 2e-5)));
    Pb[i].y = 20 * Math.log10(abs(divide(pb, 2e-5)));
  }

  return { Z_pr, Z_et, Pd, Pp, Pb };
}
