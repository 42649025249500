import React, { Component } from "react";

// Component used to play audio
// props:   freq ... frequency [Hz]
//          osc  ... array of oscillators, each oscillator has: { A, init_phase, last_phase}

const BUFFER_SIZE = 4096;

class DistortionAudio extends Component {
  state = {
    is_playing: false, // BOOL (audio is/(is not) playing)
  };

  audioContext = null;
  componentDidUpdate() {
    // extract props from parrent
    let { A, NLfnc } = this.props;

    // on component mount create audioContext (Web Audio API)
    // oscillator (osc) -> gain (gainNode) -> scriptNode -> output (audioContext.destination)
    if (this.audioContext === null && this.state.is_playing === true) {
      // audio context
      let AudioContext = window.AudioContext || window.webkitAudioContext;
      this.audioContext = new AudioContext();

      // gain node
      this.gainNode = this.audioContext.createGain();
      this.gainNode.gain.value = A;

      // oscillator
      this.osc = this.audioContext.createOscillator();
      this.osc.type = "sine";
      this.osc.frequency.value = 500;

      // script node
      this.scriptNode = this.audioContext.createScriptProcessor(
        BUFFER_SIZE,
        1,
        1
      );

      // connect chain
      this.osc.start();
      this.osc.connect(this.gainNode);
      this.gainNode.connect(this.scriptNode);
      // but leave the scriptNode not connected to destination (no sound for the moment)
    }

    if (this.audioContext !== null) {
      // on change any of the properties check if audio is on (checkbox)
      if (this.state.is_playing === true) {
        // set the gain (using a linear ramp (fade-in)) to avoid abrupt changes
        this.gainNode.gain.linearRampToValueAtTime(
          this.props.A,
          this.audioContext.currentTime + 0.05
        );

        this.scriptNode.onaudioprocess = function (audioProcessingEvent) {
          const inputBuffer = audioProcessingEvent.inputBuffer;
          const outputBuffer = audioProcessingEvent.outputBuffer;
          const N_channels = outputBuffer.numberOfChannels;

          // go through each output channel
          for (let channel = 0; channel < N_channels; channel++) {
            let inputData = inputBuffer.getChannelData(channel);
            let outputData = outputBuffer.getChannelData(channel);

            // sample-by-sample processing
            for (let sample = 0; sample < outputBuffer.length; sample++) {
              outputData[sample] = NLfnc(inputData[sample]);
            }
          }
        };

        // connect the scriptNode to the destination (it will start playing)
        this.scriptNode.connect(this.audioContext.destination);
      } else {
        // disconnect the oscilator from the gain (it will stop playing)
        this.scriptNode.disconnect();
      }
    }
  }

  // when unmounting close the audio context
  componentWillUnmount() {
    if (this.audioContext !== null) {
      this.audioContext.close();
    }
  }

  // handle for checkbox audio playing
  handleChangePlay = () => {
    this.setState((prevState) => ({
      is_playing: !prevState.is_playing,
    }));
  };

  render() {
    return (
      <div>
        play sound
        <input
          type="checkbox"
          defaultChecked={this.state.is_playing}
          onChange={this.handleChangePlay}
        />
      </div>
    );
  }
}

export default DistortionAudio;
