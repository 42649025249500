import React, { useState, useEffect } from "react";

export default function WaveGuideLeftRight(props) {
  const { properties } = props;

  const [lr, setLR] = useState("L");

  useEffect(() => {
    if (lr === "L") {
      properties.signal.left_right = "left";
      properties.signal.A.abs = 1;
      properties.signal.A.arg = 0;
      properties.signal.B.abs = 0;
      properties.signal.B.arg = 0;
    } else if (lr === "R") {
      properties.signal.left_right = "right";
      properties.signal.A.abs = 0;
      properties.signal.A.arg = 0;
      properties.signal.B.abs = 1;
      properties.signal.B.arg = 0;
    }
  }, [lr]);

  return (
    <div style={{ fontSize: "80%", marginTop: "10px" }}>
      <label>Left source</label>
      <input type="radio" name="lr" checked={lr === "L"} value="L" onChange={() => setLR("L")} />
      <input type="radio" name="lr" checked={lr === "R"} value="R" onChange={() => setLR("R")} />
      <label>Right source</label>
    </div>
  );
}
