import React, { Component } from "react";
import PolarGraph from "../UI/ChartJS/PolarGraph";
import { polarResponse, getPatternName } from "./MicrophoneDirectivity.helper";

class MicrophoneDirectivityGraph extends Component {
  render() {
    let { beta, order, sourceAngle, dragFnc } = this.props;
    // abs and phase
    let N = 72;
    let { graphDataPlus, graphDataMinus } = polarResponse(beta, N, order);
    let data = {
      title: "Microphone directivity pattern" + getPatternName(beta, order),
      data: [graphDataPlus, graphDataMinus],
      axis: { min: 0, max: 1.2 },
      dragPoint: { angle: sourceAngle, value: 1.2, radius: 10, fnc: dragFnc },
      //legend: ["positive", "negative"],
    };
    return <PolarGraph data={data} />;
  }
}
export default MicrophoneDirectivityGraph;
