import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import AnalogFiltersGraph from "./AnalogFilters.graph";

// -------------------------
// Analog Filters for Loudspeakers:
//                 fc ... cut-off frequency
//                 Rl ... load resistor (tweeter)
// -------------------------

class AnalogFilters extends Component {
  // class state
  state = {
    fc: 2000.0, // cut-off frequency [Hz]
    Rl: 20.0, // load resistor [Ohm]
    invert_tweeter_polarity: true,
  };

  // slider parameters
  sliders = [
    {
      name: "fc",
      unit: "Hz",
      min: 500,
      max: 3000,
      step: 1,
    },
    {
      name: "Rl",
      unit: "Ohm",
      min: 0,
      max: 50,
      step: 0.1,
    },
  ];

  // handle for sliders
  handleChange = (name, value) => {
    if (!isNaN(value)) {
      this.setState({ [name]: value });
    }
  };

  // handle for checkbox
  invertTweeterPolarity = (event) => {
    this.setState((prevState) => ({
      invert_tweeter_polarity: !prevState.invert_tweeter_polarity,
    }));
  };

  render() {
    let { Rl, fc, invert_tweeter_polarity } = this.state;

    return (
      <div>
        <div className="slide_block">
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            {this.sliders.map((slider, index) => {
              return (
                <SliderEdit
                  key={index}
                  onChange={this.handleChange}
                  params={slider}
                  val={this.state[slider.name]}
                />
              );
            })}
          </div>
          <p>
            invert tweeter polarity
            <input
              type="checkbox"
              defaultChecked={invert_tweeter_polarity}
              onChange={this.invertTweeterPolarity}
            />
          </p>
        </div>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        {/* */}
        {/* --------------------------------------------------*/}
        {/* radio buttons to choose between linear and logarithmic Yaxis of chart.js*/}
        {/* --------------------------------------------------*/}
        <div className="one-graph">
          {/* --------------------------------------------------*/}
          {/* graph (chartjs)*/}
          {/* --------------------------------------------------*/}
          <AnalogFiltersGraph fc={fc} Rl={Rl} polarity={invert_tweeter_polarity} />
        </div>
      </div>
    );
  }
}
export default AnalogFilters;
