import React, { Component } from "react";
import SliderEdit from "../UI/SliderEdit";
import MicrophoneEldynGraph from "./MicrophoneEldyn.graph";
import { sliders } from "./MicrophoneEldyn.helper";

class MicrophoneEldyn extends Component {
  // class state
  state = {
    Rt: 10, // mechanical resistance [Ns/m]
    Mt: 0.3, // moving mass [g]
    Ct: 0.5, // mechanical compliance [mm/N]
  };

  // handle for sliders
  handleChange = (name, value) => {
    this.setState({ [name]: Number(value) });
  };

  render() {
    let { Rt, Mt, Ct } = this.state;

    return (
      <div>
        <div className="slider-block">
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            {sliders.map((slider, index) => {
              return (
                <SliderEdit
                  key={index}
                  onChange={this.handleChange}
                  params={slider}
                  val={this.state[slider.name]}
                />
              );
            })}
          </div>
        </div>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        {/* */}
        {/* --------------------------------------------------*/}
        {/* radio buttons to choose between linear and logarithmic Yaxis of chart.js*/}
        {/* --------------------------------------------------*/}
        <div className="one-graph">
          {/* --------------------------------------------------*/}
          {/* graph (chartjs)*/}
          {/* --------------------------------------------------*/}
          <MicrophoneEldynGraph
            Rt={Rt} // mech. resistance
            Mt={Mt * 1e-3} // mass, pass value from [g] to [kg]
            Ct={Ct * 1e-3} // compliance, pass value from [mm/N] to [m/N]
          />
        </div>
      </div>
    );
  }
}
export default MicrophoneEldyn;
