import React from "react";
import SliderEdit from "../../../UI/SliderEdit";

export default function WaveGuideHelmholtzFrequency({
  properties,
  value,
  minmax,
  updateCoefficients,
}) {
  const slider = {
    name: "frequency",
    unit: "Hz",
    min: minmax[0],
    max: minmax[1],
    step: 0.01,
    log: false,
    edit: false,
  };

  const updateFrequency = (name, value) => {
    properties.signal.freq.value = Number(value);
    properties.signal.updateParams(properties.physics.c0);
    updateCoefficients(properties);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <SliderEdit onChange={updateFrequency} params={slider} val={value} />
    </div>
  );
}
