import { complex } from "mathjs";

export const Qpm_all = [
   {
    N:  10,
    f:  300,
    Qpm: [
      complex(0.0013729006, 0.00090570268),
      complex(-0.00084853668, 5.905496e-05),
      complex(0.00048834945, -0.0013359019),
      complex(0.00082668477, 0.00038729369),
      complex(-0.00016529192, 0.00048453132),
      complex(0.0016422832, 0.00038591042),
      complex(0.00056253002, -0.0011924081),
      complex(-0.0010895611, 0.0011814871),
      complex(0.00078258386, -1.879335e-05),
      complex(0.00027101607, -0.0011579074),
    ],
   },
   {
    N:  10,
    f:  600,
    Qpm: [
      complex(0.00022376065, 0.00036654537),
      complex(0.00014694545, 0.001477617),
      complex(0.0011682625, -0.00045469163),
      complex(0.00027425328, -0.00043243137),
      complex(-0.00037524804, 0.00052290135),
      complex(-0.0014377089, 0.00037621238),
      complex(4.2425451e-05, -0.001521498),
      complex(0.00066553674, 0.00020536981),
      complex(7.1107411e-05, 0.000311764),
      complex(0.001072856, -0.0007510803),
    ],
   },
   {
    N:  10,
    f:  1000,
    Qpm: [
      complex(0.00025206564, 0.00020255896),
      complex(-0.00046577266, 5.0493677e-05),
      complex(-2.4517495e-05, -0.00031789794),
      complex(-0.00017849185, 5.3026854e-05),
      complex(0.00036168766, -0.00016857499),
      complex(-0.00045131562, 5.2832088e-05),
      complex(-0.0002489516, 2.9416156e-05),
      complex(0.00028961297, 0.00073762863),
      complex(-5.4227246e-05, 6.4868618e-08),
      complex(-0.00024985398, 0.00060241571),
    ],
   },
   {
    N:  15,
    f:  300,
    Qpm: [
      complex(0.00080209336, 0.00053410024),
      complex(-6.769352e-05, 0.0007519111),
      complex(-0.00065074106, -0.00073810749),
      complex(0.00056906648, -0.00088414639),
      complex(0.00078770573, -0.00012941594),
      complex(-0.00014131891, 0.0004566616),
      complex(-7.4023662e-05, 0.00038694342),
      complex(0.00084036777, 0.00038920953),
      complex(0.0010350765, -0.00013198336),
      complex(0.00033552562, -0.00090077306),
      complex(-0.00066859848, 0.00014844421),
      complex(-4.349902e-05, 0.0010402521),
      complex(0.00060315325, 1.5890238e-05),
      complex(0.00030804077, -0.00079140051),
      complex(0.00041973792, -0.00049908203),
    ],
   },
   {
    N:  15,
    f:  600,
    Qpm: [
      complex(0.00029963846, 0.00019614141),
      complex(0.00081106763, -0.0013197768),
      complex(6.0397156e-05, -0.00053512826),
      complex(0.00026104298, 0.0010385161),
      complex(0.00013465477, 0.0012335489),
      complex(-0.00043625513, -7.5177249e-05),
      complex(-0.00078431213, -0.00031651323),
      complex(-0.0014989598, 0.00020558301),
      complex(-0.00066681508, -0.00032408566),
      complex(2.7231926e-05, 0.00011130694),
      complex(0.00014264285, 0.0012403558),
      complex(0.00011534812, -0.0001583692),
      complex(-9.4972109e-06, 0.0010034218),
      complex(-0.00015111802, -0.00038359492),
      complex(0.00099047952, 0.00036738945),
    ],
   },
   {
    N:  15,
    f:  1000,
    Qpm: [
      complex(0.00037448142, -0.0013845506),
      complex(-0.00035350348, 0.002180887),
      complex(0.0026798135, -0.00264036),
      complex(-0.001034156, 0.0009503741),
      complex(0.0013433708, -0.0015578082),
      complex(0.0029799293, -0.00035184473),
      complex(-0.0019337416, -0.0031876946),
      complex(0.00055057535, -0.0032663091),
      complex(0.00055216627, -0.000626968),
      complex(0.0024257369, 0.00013650619),
      complex(-0.00023806729, -0.00076421984),
      complex(-0.00129252, -0.002529059),
      complex(-0.00063929328, 0.0015518347),
      complex(-0.00021353492, 0.00095319743),
      complex(-0.00085494773, -0.00041577443),
    ],
   },
   {
    N:  20,
    f:  300,
    Qpm: [
      complex(0.00058989672, 0.00039834805),
      complex(0.00020948053, 0.00067095668),
      complex(-0.00044564328, 1.466429e-05),
      complex(-0.00034285204, -0.00073883643),
      complex(0.00043002102, -0.00067400825),
      complex(0.00072061847, -0.0002440504),
      complex(0.00022889192, 0.00016593965),
      complex(-0.00021776465, 0.00038077316),
      complex(-6.4785706e-05, 0.00029283816),
      complex(0.00049835248, 0.00028482769),
      complex(0.00081048538, 0.00021923367),
      complex(0.00071981113, -0.00031693612),
      complex(0.00025954142, -0.00068464045),
      complex(-0.00037247866, -0.00017860279),
      complex(-0.00040056564, 0.00063632467),
      complex(0.00016249768, 0.00067200301),
      complex(0.00044146072, 1.3096654e-05),
      complex(0.0003019218, -0.00053165543),
      complex(0.00021215824, -0.0005932958),
      complex(0.00040433302, -0.00021093092),
    ],
   },
   {
    N:  20,
    f:  600,
    Qpm: [
      complex(0.00034499958, -1.969923e-06),
      complex(0.00070704622, -0.0011038948),
      complex(0.00030849417, 0.0004548024),
      complex(-0.00054535325, 0.00019086506),
      complex(-5.9631788e-05, 0.00032320315),
      complex(7.3188847e-06, 0.0003531387),
      complex(-0.000430513, 0.00056426384),
      complex(0.00024269745, -0.00066716144),
      complex(-0.00036627034, -0.00052417352),
      complex(-0.0011247102, 5.7317756e-05),
      complex(-0.00026631258, 0.0001868386),
      complex(-0.00022439652, -0.00048950394),
      complex(5.7368924e-05, -0.00018921361),
      complex(-0.00050832141, 0.00033662259),
      complex(0.0010787252, 0.00076179873),
      complex(-0.00048889741, 0.00028414812),
      complex(7.8526762e-05, -0.00020491562),
      complex(0.00046002458, -0.00010553133),
      complex(0.00093828888, -0.00028887998),
      complex(0.00015420263, -0.00030160463),
    ],
   },
   {
    N:  20,
    f:  1000,
    Qpm: [
      complex(-0.001884749, 0.00022210375),
      complex(-0.0007576575, -0.0033955636),
      complex(-0.0013815375, -0.0020399413),
      complex(-0.0012233313, -0.0021476213),
      complex(-0.002057126, -2.1185345e-05),
      complex(-0.00014980604, 0.00061980726),
      complex(-0.00030223908, -3.2856148e-06),
      complex(0.0014000203, 0.00021177928),
      complex(0.00050015958, -0.0019664921),
      complex(0.0014536157, -0.0014160345),
      complex(0.0010038561, 0.00085409513),
      complex(-0.00060121244, -0.0014523406),
      complex(0.00062341357, -0.0018071823),
      complex(0.00031334887, 0.0003002946),
      complex(0.0039766655, 0.0024758675),
      complex(0.0022978414, -0.0014264447),
      complex(0.0018240808, 0.00044114403),
      complex(0.0013350279, 0.0028459021),
      complex(-0.0035570081, -0.00034085133),
      complex(-0.00077529187, 0.0026843439),
    ],
   },
   {
    N:  25,
    f:  300,
    Qpm: [
      complex(0.000464594, 0.0003168974),
      complex(0.00027424956, 0.00053926142),
      complex(-0.00016680289, 0.00028654218),
      complex(-0.00042124371, -0.00029237163),
      complex(-0.00017373588, -0.000640386),
      complex(0.00034528826, -0.00054596804),
      complex(0.00060447511, -0.00026931673),
      complex(0.00038811842, 6.8993527e-06),
      complex(-6.9159746e-06, 0.00023674991),
      complex(-0.0002058463, 0.00030720667),
      complex(-5.7117793e-05, 0.00023590137),
      complex(0.00031857426, 0.00022145984),
      complex(0.00060632806, 0.0002430184),
      complex(0.00066026021, 3.9232243e-05),
      complex(0.0005317431, -0.00035920999),
      complex(0.00021253621, -0.00055249848),
      complex(-0.00020462631, -0.00026904844),
      complex(-0.00038305766, 0.00028814108),
      complex(-0.00015327485, 0.00061570731),
      complex(0.00020179431, 0.00045140802),
      complex(0.00034652782, 1.0615929e-05),
      complex(0.00027739026, -0.00037056079),
      complex(0.00018325036, -0.00051646684),
      complex(0.00021148041, -0.00039163994),
      complex(0.00036253818, -7.5535546e-05),
    ],
   },
   {
    N:  25,
    f:  600,
    Qpm: [
      complex(0.00062462472, 0.00034167761),
      complex(0.0002018543, -0.0013743579),
      complex(0.0008635021, 0.0003052414),
      complex(-0.00022328311, 7.0396758e-05),
      complex(-0.0002945631, 0.0004580331),
      complex(-0.00038646875, 0.00010626328),
      complex(0.00030774368, 0.00032067305),
      complex(-0.00048558145, 0.00066406362),
      complex(-0.0003441244, -3.7931965e-05),
      complex(0.00039021465, -0.0006072049),
      complex(-0.00025442501, -0.00039126018),
      complex(-0.00097914667, -0.00025752798),
      complex(-0.00060407589, 0.00025530569),
      complex(-4.3456441e-05, -0.00013214749),
      complex(-0.0002930872, -0.00065692707),
      complex(-4.5472869e-05, 1.8322766e-05),
      complex(-0.00036840839, -3.2588423e-05),
      complex(0.00023607589, 0.0011047742),
      complex(0.00091372092, -0.00021773721),
      complex(-0.00031099135, 0.00074589744),
      complex(-5.1935828e-05, -0.00039103496),
      complex(-8.298292e-05, -0.00012127962),
      complex(0.0014190842, -0.00061199832),
      complex(2.0170796e-05, 0.00011007461),
      complex(2.1764492e-05, -0.00032084904),
    ],
   },
   {
    N:  25,
    f:  1000,
    Qpm: [
      complex(-0.00080881046, 0.00063003846),
      complex(-0.0017407995, -0.00084208909),
      complex(0.00067957915, 0.00066255605),
      complex(0.00070347071, 0.0007515339),
      complex(-0.00030426312, -0.00074591655),
      complex(-0.0004824998, -0.00018061193),
      complex(-0.00030642564, 0.00047099185),
      complex(-5.2342198e-05, 0.00068669575),
      complex(-0.0013233587, -1.7941141e-05),
      complex(0.001403126, 0.00052990044),
      complex(0.00086564826, -0.0016037279),
      complex(0.00024703935, -0.0013640815),
      complex(0.0013749717, -0.0007365221),
      complex(0.00018273223, -0.00056978584),
      complex(-0.0015442031, -0.00052428373),
      complex(0.0007013093, 0.00081765675),
      complex(0.0010387615, -0.00018461616),
      complex(-0.0023305726, 0.00049431594),
      complex(0.0011343199, -0.00021104862),
      complex(-0.00081563398, -0.0020927817),
      complex(-0.0020897778, -0.0016871013),
      complex(0.0012698817, -0.00061976121),
      complex(-0.0024365547, 0.0028375221),
      complex(0.0017984791, -0.00094381659),
      complex(-0.00063691362, 0.00014277653),
    ],
   },
];
