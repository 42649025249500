import { complex, abs, add, multiply } from "mathjs";
import { AirParameters } from "../../helpers/helpers";
import { Qpm_all } from "./SoundZones.data.js";

export const GRAPH_RATIO = 0.6;

export function calculate_colorplot(graph, state, sources) {
  let k = (2 * Math.PI * state.frequency) / AirParameters.c0; // Wave vector
  let jkrhoc = complex(0, k * AirParameters.rho0 * AirParameters.c0);

  // select the corresponding set of filter coefficients
  let Qpm = Qpm_all.filter((obj) => {
    return obj.N === state.N_sources && obj.f === state.frequency;
  })[0].Qpm;

  for (let i = 0; i < state.N_sources; i++) {
    sources.points[i].q = multiply(jkrhoc, Qpm[i]);
  }
  // --------------

  let x = 0;
  let y = 0;
  for (let idx = 0; idx < graph.x_len; idx++) {
    x = graph.x_min_step_max[0] + idx * graph.x_min_step_max[1];
    for (let idy = 0; idy < graph.y_len; idy++) {
      y = graph.y_min_step_max[0] + idy * graph.y_min_step_max[1];
      let r = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
      if (r < 2.05) {
        let Ppm = 0;

        sources.points.forEach((source) => {
          let ro = Math.sqrt(Math.pow(x - source.x, 2) + Math.pow(y - source.y, 2));
          let expjkro = complex(
            Math.cos(k * ro) / (4 * Math.PI * ro),
            -Math.sin(k * ro) / (4 * Math.PI * ro)
          );
          Ppm = add(Ppm, multiply(expjkro, source.q));
        });

        graph.arr[idx][idy] = 20 * Math.log10(abs(Ppm) / 2e-5);
      } else {
        graph.arr[idx][idy] = NaN;
      }
    }
  }
}

export function createGraphProperties(properties) {
  let x_len = Math.round((1 / GRAPH_RATIO) * length_from_min_step_max(properties.x_min_step_max));
  let y_len = length_from_min_step_max(properties.y_min_step_max);
  let x_width = properties.x_min_step_max[2] - properties.x_min_step_max[0];
  let y_width = properties.y_min_step_max[2] - properties.y_min_step_max[0];
  return {
    x_min_step_max: properties.x_min_step_max,
    y_min_step_max: properties.y_min_step_max,
    z_min_max: properties.z_min_max,
    x_len: x_len, // number of points
    y_len: y_len, // number of points
    // create a new empty array
    arr: Array.from(Array(x_len), () => new Array(y_len)),
    mapX: (x, x_rect_size) => {
      return ((x - properties.x_min_step_max[0]) / x_width) * x_len * x_rect_size;
    },
    mapY: (y, y_rect_size) => {
      return ((y - properties.y_min_step_max[0]) / y_width) * y_len * y_rect_size;
    },
  };
}

export function length_from_min_step_max(min_step_max) {
  return Math.floor((min_step_max[2] - min_step_max[0]) / min_step_max[1]);
}
