import React, { Component } from "react";
import LineGraph from "../UI/ChartJS/LineGraph";
import { LogSpace } from "../../helpers/helpers";
import { loudspeaker } from "./TSLoudspeaker.helper";

class TSLoudspeakergraph extends Component {
  render() {
    // List of inputs ("props") :
    //   Bl [Tm] ... force factor
    //   Re [Ohm] ... resistance
    //   Le [H] ... inductance
    //   Mms [kg] ... mass
    //   Rms [Ns/m] ... mechanical resistance
    //   Kms [N/m] ... stiffness
    //   Sd [m^2] ... surface
    let { Bl, Re, Le, Mms, Rms, Kms, Sd } = this.props;

    // frequency axis
    let f_ax = LogSpace(1, Math.log10(20e3), 200);

    // impedance and phase
    let { Z_in, phase, P_sens } = loudspeaker(Bl, Re, Le, Mms, Rms, Kms, Sd, f_ax);
    let dataImpedance = {
      title: "Loudspeaker Impedance",
      xAxes: {
        xLabel: "Frequency [Hz]",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [Z_in],
          label: ["Abs"],
          yLabel: "Absolute value [ohm]",
          type: "linear",
        },
        {
          data: [phase],
          label: ["Phase"],
          yLabel: "Phase [deg]",
          type: "linear",
          min: -100,
          max: 100,
        },
      ],
      animation: 0,
    };

    let dataSensitivity = {
      title: "SPL @2.83V @1m",
      xAxes: {
        xLabel: "Frequency [Hz]",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [P_sens],
          yLabel: "Sound Pressure Level [dB SPL]",
          label: ["SPL"],
          type: "linear",
        },
      ],
    };

    return (
      <div>
        <div className="two-graphs">
          <LineGraph data={dataImpedance} graph_size_ratio={0.7} />
        </div>
        <div className="two-graphs">
          <LineGraph data={dataSensitivity} graph_size_ratio={0.7} />
        </div>
        <div className="clr" />
      </div>
    );
  }
}

export default TSLoudspeakergraph;
